import ComingSoon from "../../../components/ComingSoon/ComingSoon";
import { DIRECTORCUT } from "../../../NewData/dataDirector";
import React from "react";

const FSS = () => (
  <ComingSoon
    title={DIRECTORCUT.images[1].title}
    image={DIRECTORCUT.images[1]}
  />
);

export default FSS;
