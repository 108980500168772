import React from "react";
import I1 from "../images/NY/1.jpg";
import I2 from "../images/NY/2.jpg";
import I3 from "../images/NY/3.jpg";
import I4 from "../images/NY/4.jpg";
import I5 from "../images/NY/5.jpg";
import I6 from "../images/NY/6.jpg";
import I7 from "../images/NY/7.jpg";
import I8 from "../images/NY/8.jpg";
import I9 from "../images/NY/9.jpg";
import I10 from "../images/NY/10.jpg";
import I11 from "../images/NY/11.jpg";
import I12 from "../images/NY/12.jpg";
import I13 from "../images/NY/13.jpg";
import I14 from "../images/NY/14.jpg";
import I15 from "../images/NY/15.jpg";
import I16 from "../images/NY/16.jpg";
import I17 from "../images/NY/17.jpg";
import I18 from "../images/NY/18.jpg";
import I19 from "../images/NY/19.jpg";
import I20 from "../images/NY/20.jpg";
import I21 from "../images/NY/21.jpg";
import I22 from "../images/NY/22.jpg";
import I23 from "../images/NY/23.jpg";
import I24 from "../images/NY/24.jpg";
import I25 from "../images/NY/25.jpg";
import I26 from "../images/NY/26.jpg";
import I27 from "../images/NY/27.jpg";
import I28 from "../images/NY/28.jpg";
import I29 from "../images/NY/29.jpg";
import I30 from "../images/NY/30.jpg";
import I31 from "../images/NY/31.jpg";
import I32 from "../images/NY/32.jpg";
import I33 from "../images/NY/33.jpg";
import I34 from "../images/NY/34.jpg";
import I35 from "../images/NY/35.jpg";
import I36 from "../images/NY/36.jpg";
import I37 from "../images/NY/37.jpg";
import I38 from "../images/NY/38.jpg";
import I39 from "../images/NY/39.jpg";
import I40 from "../images/NY/40.jpg";
import I41 from "../images/NY/41.jpg";
import I42 from "../images/NY/42.jpg";
import I43 from "../images/NY/43.jpg";
import Valedictory from "../pages/destinations/NewYork/Texts/valedictory";

const image11Text = (
  <span>
    <span style={{ marginRight: 32 }}>AT&T</span>
    <span>Building</span>
  </span>
);

export const NEWYORK = {
  title: "New York",
  images: [
    {
      id: "NY1",
      image: I1,
      imageGridColumnStart: 5,
      imageGridColumnEnd: 17,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "New York 2010",
      marginTop: "",
      speed: "",
    },
    {
      id: "NY2",
      image: I2,
      imageGridColumnStart: 17,
      imageGridColumnEnd: 20,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "untitled",
      date: "New York 2010",
      marginTop: "4%",
      speed: "",
    },
    {
      id: "NY3",
      image: I3,
      imageGridColumnStart: 8,
      imageGridColumnEnd: 13,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "The Singer Cup",
      date: "New York 2010",
      marginTop: "-4%",
      speed: 5,
    },
    {
      id: "NY4",
      image: I4,
      imageGridColumnStart: 15,
      imageGridColumnEnd: 20,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "untitled",
      date: "New York 2010",
      marginTop: "-2%",
      speed: -20,
    },
    {
      id: "NY5",
      image: I5,
      imageGridColumnStart: 2,
      imageGridColumnEnd: 13,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Look there, look there!",
      date: "New York 2010",
      marginTop: "-12%",
      speed: -10,
    },
    {
      id: "NY6",
      image: I6,
      imageGridColumnStart: 8,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Here I am, bird!",
      date: "New York 2010",
      marginTop: "4%",
      speed: 2.5,
    },
    {
      id: "NY7",
      image: I7,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 13,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "New York 2010",
      marginTop: "4%",
      speed: 5,
    },
    {
      id: "NY8",
      image: I8,
      imageGridColumnStart: 14,
      imageGridColumnEnd: 19,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "Citicorp at Dusk",
      date: "New York 2010",
      marginTop: "-4%",
      speed: "",
    },
    {
      id: "NY9",
      image: I9,
      imageGridColumnStart: 2,
      imageGridColumnEnd: 6,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "untitled",
      date: "New York 2010",
      marginTop: "-4%",
      speed: 0,
    },
    {
      id: "NY10",
      image: I10,
      imageGridColumnStart: 8,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "Rockefeller's Dream",
      date: "New York 2010",
      marginTop: "-2%",
      speed: 0,
    },
    {
      id: "NY11",
      image: I11,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 8,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: image11Text,
      date: "New York 2010",
      marginTop: "2%",
      speed: 0,
    },
    {
      id: "NY12",
      image: I12,
      imageGridColumnStart: 9,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "New York 2011",
      marginTop: "-2%",
      speed: 0,
    },
    {
      id: "NY13",
      image: I13,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 8,
      text: <Valedictory />,
      textGridColumnStart: 5,
      textGridColumnEnd: 15,
      layout: "horizontal",
      title: "Norman Thomas High School",
      date: "New York 2013",
      marginTop: "-10%",
      speed: 0,
      textMarginTop: "-8%",
    },
    {
      id: "NY14",
      image: I14,
      imageGridColumnStart: 8,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "New York 2011",
      marginTop: "2%",
      speed: 0,
    },
    {
      id: "NY15",
      image: I15,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 12,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "Double Deco",
      date: "New York 2011",
      marginTop: "",
      speed: 0,
    },
    {
      id: "NY16",
      image: I16,
      imageGridColumnStart: 10,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "New York 2010",
      marginTop: "4%",
      speed: 0,
    },
    {
      id: "NY17",
      image: I17,
      imageGridColumnStart: 2,
      imageGridColumnEnd: 8,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "untitled",
      date: "New York 2010",
      marginTop: "-4%",
      speed: 0,
    },
    {
      id: "NY18",
      image: I18,
      imageGridColumnStart: 15,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "Night Watch",
      date: "New York 2010",
      marginTop: "-8%",
      speed: 0,
    },
    {
      id: "NY19",
      image: I19,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 13,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "untitled",
      date: "New York 2011",
      marginTop: "",
      speed: 0,
    },
    {
      id: "NY20",
      image: I20,
      imageGridColumnStart: 14,
      imageGridColumnEnd: 20,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "Penny for Your Thoughts",
      date: "New York 2011",
      marginTop: "-4%",
      speed: 0,
    },
    {
      id: "NY21",
      image: I21,
      imageGridColumnStart: 7,
      imageGridColumnEnd: 13,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Ask Ms. Lisa",
      date: "New York 2010",
      marginTop: "-2%",
      speed: 0,
    },
    {
      id: "NY22",
      image: I22,
      imageGridColumnStart: 12,
      imageGridColumnEnd: 18,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "New York 2012",
      marginTop: "2%",
      speed: 0,
    },
    {
      id: "NY23",
      image: I23,
      imageGridColumnStart: 6,
      imageGridColumnEnd: 9,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "Abandon Hope All Ye Who Enter Here",
      date: "New York 2012",
      marginTop: "",
      speed: 0,
    },
    {
      id: "NY24",
      image: I24,
      imageGridColumnStart: 10,
      imageGridColumnEnd: 19,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "untitled",
      date: "New York 2011",
      marginTop: "4%",
      speed: 0,
    },
    {
      id: "NY25",
      image: I25,
      imageGridColumnStart: 2,
      imageGridColumnEnd: 7,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Pretzels and Hot Dogs",
      date: "New York 2012",
      marginTop: "-4%",
      speed: 0,
    },
    {
      id: "NY26",
      image: I26,
      imageGridColumnStart: 14,
      imageGridColumnEnd: 20,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "New York 2011",
      marginTop: "-2%",
      speed: 0,
    },
    {
      id: "NY27",
      image: I27,
      imageGridColumnStart: 5,
      imageGridColumnEnd: 15,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "New York 2011",
      marginTop: "2%",
      speed: 0,
    },
    {
      id: "NY28",
      image: I28,
      imageGridColumnStart: 2,
      imageGridColumnEnd: 7,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "untitled",
      date: "New York 2010",
      marginTop: "2%",
      speed: 0,
    },
    {
      id: "NY29",
      image: I29,
      imageGridColumnStart: 9,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "World Trade Center 2 Rising",
      date: "New York 2011",
      marginTop: "",
      speed: 0,
    },
    {
      id: "NY30",
      image: I30,
      imageGridColumnStart: 3,
      imageGridColumnEnd: 9,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "Homage to Ayn Rand",
      date: "New York 2010",
      marginTop: "2%",
      speed: 0,
    },
    {
      id: "NY31",
      image: I31,
      imageGridColumnStart: 13,
      imageGridColumnEnd: 19,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "An Eye for Fashion",
      date: "New York 2012",
      marginTop: "-2%",
      speed: 0,
    },
    {
      id: "NY32",
      image: I32,
      imageGridColumnStart: 3,
      imageGridColumnEnd: 9,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "New York 2011",
      marginTop: "4%",
      speed: 0,
    },
    {
      id: "NY33",
      image: I33,
      imageGridColumnStart: 8,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Don't Be A Statistic!",
      date: "New York 2011",
      marginTop: "2%",
      speed: 0,
    },
    {
      id: "NY34",
      image: I34,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 14,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Forever 21",
      date: "New York 2011",
      marginTop: "2%",
      speed: 0,
    },
    {
      id: "NY35",
      image: I35,
      imageGridColumnStart: 12,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "New York 2012",
      marginTop: "4%",
      speed: 0,
    },
    {
      id: "NY36",
      image: I36,
      imageGridColumnStart: 3,
      imageGridColumnEnd: 8,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Homage to Aaron Siskind",
      date: "New York 2011",
      marginTop: "1%",
      speed: 0,
    },
    {
      id: "NY37",
      image: I37,
      imageGridColumnStart: 15,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "In Colgate Time",
      date: "New York 2011",
      marginTop: "-4%",
      speed: 0,
    },
    {
      id: "NY38",
      image: I38,
      imageGridColumnStart: 3,
      imageGridColumnEnd: 10,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "New York 2010",
      marginTop: "",
      speed: 0,
    },
    {
      id: "NY39",
      image: I39,
      imageGridColumnStart: 10,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "New York 2010",
      marginTop: "",
      speed: 0,
    },
    {
      id: "NY40",
      image: I40,
      imageGridColumnStart: 3,
      imageGridColumnEnd: 9,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "@RealJacksonPollock",
      date: "New York 2010",
      marginTop: "",
      speed: 0,
    },
    {
      id: "NY41",
      image: I41,
      imageGridColumnStart: 10,
      imageGridColumnEnd: 19,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "New York 2010",
      marginTop: "1%",
      speed: 0,
    },
    {
      id: "NY42",
      image: I42,
      imageGridColumnStart: 8,
      imageGridColumnEnd: 14,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "A Republic, Ma'am, If You Can Keep It",
      date: "New York 2011",
      marginTop: "4%",
      speed: 0,
    },
    {
      id: "NY43",
      image: I43,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Madonna of the Willows",
      date: "New York 2010",
      marginTop: "4%",
      speed: 0,
    },
  ],
};
