import I1 from "../images/CAUC/1.jpg";
import I2 from "../images/CAUC/2.jpg";
import I3 from "../images/CAUC/3.jpg";
import I4 from "../images/CAUC/4.jpg";
import I5 from "../images/CAUC/5.jpg";
import I6 from "../images/CAUC/6.jpg";
import I7 from "../images/CAUC/7.jpg";
import I8 from "../images/CAUC/8.jpg";
import I9 from "../images/CAUC/9.jpg";

export const CUATROAMIGOS = {
  title: "quatro amigos: una cuba",
  images: [
    {
      image: I1,
      title: "untitled",
      text: [],
      gridRowStart: 1,
      gridColumnStart: 50,
      gridRowEnd: 4,
      gridColumnEnd: 58,
      layout: "vertical",
      date: "Havana 2019",
    },
    {
      image: I2,
      title: "Mondrian’s Malecon",
      text: [],
      gridRowStart: 4,
      gridColumnStart: 57,
      gridRowEnd: 6,
      gridColumnEnd: 66,
      layout: "horizontal",
      date: "Havana 2019",
    },
    {
      image: I3,
      title: "untitled",
      floatingTitle: true,
      text: [
        "This image was taken in November 2009 on my first visit to Cuba.  The year 2009 marked the 50th anniversary of Fidel Castro’s revolution. Throughout a year-long celebration, flags of all sizes were in prominent display around the city.",
      ],
      gridRowStart: 2,
      gridColumnStart: 68,
      gridRowEnd: 5,
      gridColumnEnd: 84,
      layout: "horizontal",
      date: "Havana 2019",
    },
    {
      image: I4,
      title: "untitled",
      text: [],
      gridRowStart: 1,
      gridColumnStart: 84,
      gridRowEnd: 3,
      gridColumnEnd: 92,
      layout: "horizontal",
      date: "Havana 2019",
    },
    {
      image: I5,
      title: "The Stare",
      text: [],
      gridRowStart: 3,
      gridColumnStart: 92,
      gridRowEnd: 6,
      gridColumnEnd: 99,
      layout: "vertical",
      date: "Havana 2019",
    },
    {
      image: I6,
      title: "",
      text: [],
      gridRowStart: 1,
      gridColumnStart: 18,
      gridRowEnd: 6,
      gridColumnEnd: 26,
      layout: "vertical",
      noInfo: true,
    },
    {
      image: I7,
      title: "",
      text: [],
      gridRowStart: 1,
      gridColumnStart: 1,
      gridRowEnd: 6,
      gridColumnEnd: 7,
      layout: "vertical",
      noInfo: true,
    },
    {
      image: I8,
      title: "",
      text: [],
      gridRowStart: 2,
      gridColumnStart: 8,
      gridRowEnd: 6,
      gridColumnEnd: 16,
      layout: "horizontal",
      noInfo: true,
    },
    {
      image: I9,
      title: "",
      text: [],
      gridRowStart: 1,
      gridColumnStart: 29,
      gridRowEnd: 6,
      gridColumnEnd: 38,
      layout: "horizontal",
      noInfo: true,
    },
  ],
};
