import styled from "styled-components";

// arrow.jsx
export const Arrow = styled.img`
  cursor: pointer;
  position: absolute;
  top: 50%;
  transition: transform ease-in 0.1s;
  width: 30px;
  &:hover {
    transform: scale(1.4);
  }
`;

//dots.jsx
export const Dot = styled.span`
  cursor: pointer;
  height: 12px;
  width: 12px;
  margin: 0px 4px;
  border-radius: 50%;
  border: 1px solid #b3c53f;
`;

//sliderContent.jsx

export const Text = styled.p`
  font: 34px Mrs Saint Delafield;
  color: #301b3c;
  margin: 16px auto;
  text-align: center;
  word-spacing: 4px;
`;

export const Slide = styled.div`
  height: ${(props) => (props.height ? props.height : "50vh")};
  width: 100%;
  position: relative;
  display: ${(props) => props.display};
`;
