// import I1 from "../images/DC/1.jpg";
import I2 from "../images/DC/2.png";
import I3 from "../images/DC/3.png";
import I4 from "../images/DC/4.png";
import I5 from "../images/DC/5.jpg";
import I6 from "../images/DC/6.png";
import I7 from "../images/DC/7.jpg";
import I8 from "../images/DC/8.jpg";
import I9 from "../images/DC/9.png";

export const DIRECTORCUT = {
  images: [
    // {
    //   id:1,
    //image: I1,
    //   title: "take two",
    //   to: "take-two",
    //   text: [],
    //   gridRowStart: 2,
    //   gridColumnStart: 6,
    //   gridRowEnd: 4,
    //   gridColumnEnd: 10,
    // comingSoon:false,
    // layout: "horizontal",
    // },
    {
      id: "DC2",
      image: I2,
      title: "Who Am I",
      to: "cultural-disconnect",
      text: [],
      gridRowStart: 20,
      gridColumnStart: 35,
      gridRowEnd: 31,
      gridColumnEnd: 51,
      comingSoon: true,
      layout: "horizontal",
    },
    {
      id: "DC3",
      image: I3,
      title: "Favorite Street Scenes",
      to: "favorite-street-scenes",
      text: [],
      gridRowStart: 24,
      gridColumnStart: 22,
      gridRowEnd: 30,
      gridColumnEnd: 32,
      comingSoon: true,
      layout: "horizontal",
    },
    {
      id: "DC4",
      image: I4,
      title: "Windows of the World",
      to: "window-of-the-world",
      text: [],
      gridRowStart: 15,
      gridColumnStart: 12,
      gridRowEnd: 24,
      gridColumnEnd: 25,
      comingSoon: true,
      layout: "horizontal",
    },
    {
      id: "DC5",
      image: I5,
      title: "From Darkness to Light",
      to: "from-darkness-to-light",
      text: [],
      gridRowStart: 11,
      gridColumnStart: 14,
      gridRowEnd: 15,
      gridColumnEnd: 19,
      comingSoon: false,
      layout: "horizontal",
    },
    {
      id: "DC6",
      image: I6,
      title: "Flashbacks from the Metaverse",
      to: "flashbacks-from-the-metaverse",
      text: [],
      gridRowStart: 1,
      gridColumnStart: 8,
      gridRowEnd: 11,
      gridColumnEnd: 32,
      comingSoon: false,
      layout: "horizontal",
    },
    {
      id: "DC7",
      image: I7,
      title: "Familiar Landmarks * Unfamiliar Angles",
      to: "familiar-landmarks-unfamiliar-angles",
      text: [],
      gridRowStart: 10,
      gridColumnStart: 1,
      gridRowEnd: 18,
      gridColumnEnd: 6,
      comingSoon: true,
      layout: "vertical",
    },
    {
      id: "DC8",
      image: I8,
      title: "From the Pavement",
      to: "from-the-pavement",
      text: [],
      gridRowStart: 23,
      gridColumnStart: 1,
      gridRowEnd: 31,
      gridColumnEnd: 6,
      comingSoon: true,
      layout: "vertical",
    },
    {
      id: "DC9",
      image: I9,
      title: "Animal Farm",
      to: "animal-farm",
      text: [],
      gridRowStart: 2,
      gridColumnStart: 44,
      gridRowEnd: 8,
      gridColumnEnd: 51,
      comingSoon: true,
      layout: "horizontal",
    },
  ],
};
