import LS0 from "../images/LS/0.jpg";
import LS1 from "../images/LS/1.jpg";
import LS2 from "../images/LS/2.jpg";
import LS3 from "../images/LS/3.jpg";
import LS4 from "../images/LS/4.jpg";
import LS5 from "../images/LS/5.jpg";
import LS6 from "../images/LS/6.jpg";
import LS7 from "../images/LS/7.jpg";
import LS8 from "../images/LS/8.jpg";
import LS9 from "../images/LS/9.jpg";
import LS10 from "../images/LS/10.jpg";
import LS11 from "../images/LS/11.jpg";
import LS12 from "../images/LS/12.jpg";
import LS13 from "../images/LS/13.jpg";
import LS14 from "../images/LS/14.jpg";
import LS15 from "../images/LS/15.jpg";
import LS16 from "../images/LS/16.jpg";

export const LIGHTSCAPES = {
  title: "lightscapes",
  images: [
    {
      id: "LS0",
      image: LS0,
      text: [],
      gridRowStart: 2,
      gridColumnStart: 16,
      gridRowEnd: 5,
      gridColumnEnd: 28,
      layout: "vertical",
      title: "Lightscapes Number 1",
      date: "Toronto 2021",
    },
    {
      id: "LS1",
      image: LS1,
      text: [],
      gridRowStart: 3,
      gridColumnStart: 8,
      gridRowEnd: 5,
      gridColumnEnd: 13,
      layout: "vertical",
      title: "Homage to Monet",
      date: "Toronto 2020",
    },
    {
      id: "LS2",
      image: LS2,
      text: [],
      gridRowStart: 2,
      gridColumnStart: 28,
      gridRowEnd: 5,
      gridColumnEnd: 42,
      layout: "horizontal",
      title: "Lightscapes Number 2",
      date: "Toronto 2020",
    },
    {
      id: "LS3",
      image: LS3,
      text: [],
      gridRowStart: 2,
      gridColumnStart: 42,
      gridRowEnd: 5,
      gridColumnEnd: 56,
      layout: "horizontal",
      title: "Lightscapes Number 3",
      date: "Toronto 2020",
    },
    {
      id: "LS4",
      image: LS4,
      text: [],
      gridRowStart: 2,
      gridColumnStart: 57,
      gridRowEnd: 5,
      gridColumnEnd: 66,
      layout: "vertical",
      title: "Lightscapes Number 4",
      date: "Toronto 2020",
    },
    {
      id: "LS5",
      image: LS5,
      text: [],
      gridRowStart: 2,
      gridColumnStart: 66,
      gridRowEnd: 5,
      gridColumnEnd: 80,
      layout: "horizontal",
      title: "Lightscapes Number 5",
      date: "Toronto 2020",
    },
    {
      id: "LS6",
      image: LS6,
      text: [],
      gridRowStart: 2,
      gridColumnStart: 80,
      gridRowEnd: 5,
      gridColumnEnd: 90,
      layout: "vertical",
      title: "Lightscapes Number 6",
      date: "Toronto 2020",
    },
    {
      id: "LS7",
      image: LS7,
      text: [],
      gridRowStart: 2,
      gridColumnStart: 90,
      gridRowEnd: 5,
      gridColumnEnd: 104,
      layout: "horizontal",
      title: "Lightscapes Number 7",
      date: "Toronto 2020",
    },
    {
      id: "LS8",
      image: LS8,
      text: [],
      gridRowStart: 2,
      gridColumnStart: 104,
      gridRowEnd: 5,
      gridColumnEnd: 114,
      layout: "vertical",
      title: "Lightscapes Number 8",
      date: "Toronto 2020",
    },
    {
      id: "LS9",
      image: LS9,
      text: [],
      gridRowStart: 2,
      gridColumnStart: 114,
      gridRowEnd: 5,
      gridColumnEnd: 128,
      layout: "horizontal",
      title: "Lightscapes Number 9",
      date: "Toronto 2020",
    },
    {
      id: "LS10",
      image: LS10,
      text: [],
      gridRowStart: 2,
      gridColumnStart: 128,
      gridRowEnd: 5,
      gridColumnEnd: 142,
      layout: "horizontal",
      title: "Homage to Marc Chagall",
      date: "Toronto 2020",
    },
    {
      id: "LS11",
      image: LS11,
      text: [],
      gridRowStart: 2,
      gridColumnStart: 142,
      gridRowEnd: 5,
      gridColumnEnd: 156,
      layout: "horizontal",
      title: "Unstill Life",
      date: "Toronto 2020",
    },
    {
      id: "LS12",
      image: LS12,
      text: [],
      gridRowStart: 2,
      gridColumnStart: 156,
      gridRowEnd: 5,
      gridColumnEnd: 170,
      layout: "horizontal",
      title: "Lightscapes Number 11",
      date: "Toronto 2020",
    },
    {
      id: "LS13",
      image: LS13,
      text: [],
      gridRowStart: 2,
      gridColumnStart: 170,
      gridRowEnd: 5,
      gridColumnEnd: 184,
      layout: "horizontal",
      title: "Gas Nebula",
      date: "Toronto 2020",
    },
    {
      id: "LS14",
      image: LS14,
      text: [],
      gridRowStart: 2,
      gridColumnStart: 182,
      gridRowEnd: 5,
      gridColumnEnd: 196,
      layout: "vertical",
      title: "Lachryma Mundi",
      date: "Toronto 2020",
    },
    {
      id: "LS15",
      image: LS15,
      text: [],
      gridRowStart: 2,
      gridColumnStart: 196,
      gridRowEnd: 5,
      gridColumnEnd: 210,
      layout: "horizontal",
      title: "Lightscapes Number 14",
      date: "Toronto 2020",
    },
    {
      id: "LS16",
      image: LS16,
      text: [],
      gridRowStart: 2,
      gridColumnStart: 210,
      gridRowEnd: 5,
      gridColumnEnd: 224,
      layout: "horizontal",
      title: "Elysium",
      date: "Toronto 2020",
    },
  ],
};
