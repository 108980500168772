import React, { useRef } from "react";
import styled from "styled-components";
import useOnScreen from "../hooks/useOnScreen";

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: 1fr;
`;
const ImageWrapper = styled(GridWrapper)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  will-change: transform;
  transform-origin: center left;
  transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1),
    clip-path 1s cubic-bezier(0.77, 0, 0.175, 1);
`;

const GalleryItem = ({ data, index, modalImage, modalText }) => {
  const ref = useRef(null);
  const onScreen = useOnScreen(ref, 0.2);

  //scroll speed
  // const getScrollIndex = () => {
  //   if (index % 2) return 0;
  //   return 1;
  // };

  return (
    <div
      ref={ref}
      // data-scroll
      // data-scroll-speed={getScrollIndex()}
    >
      <ImageWrapper
        style={{
          marginTop: data.marginTop,
          backgroundColor: data.imageBG,
          padding: data.padding,
        }}
      >
        <Image
          style={{
            clipPath: onScreen ? "inset(0% 0% 0% 0%)" : "inset(0% 0% 100% 0%)",
            transform: onScreen ? "scale(1)" : "scale(1.1)",
            gridArea: `1 / ${data.imageGridColumnStart} / 2 /${data.imageGridColumnEnd}`,
          }}
          onClick={() => modalImage(data, data.image)}
          src={data.image}
          alt={data.image}
        />
      </ImageWrapper>
      {data.text && (
        <GridWrapper
          style={{ marginTop: data.textMarginTop ? data.textMarginTop : -1 }}
        >
          <div
            style={{
              backgroundColor: data.textBG,
              gridArea: `1 / ${data.textGridColumnStart} / 2 /${data.textGridColumnEnd}`,
            }}
          >
            {data.text}
          </div>
        </GridWrapper>
      )}
    </div>
  );
};

export default GalleryItem;
