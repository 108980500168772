import React from "react";
import styled from "styled-components";

const Container = styled.div`
  color: #331c65;
  display: flex;
  flex-direction: column;
  width: 100%;
  font: 1vw Kumbh Sans;
  padding: 8%;
  align-items: flex-end;
`;
const Keats = () => (
  <Container>
    <p>Beauty is truth, truth beauty - that is all</p>
    <p style={{ marginRight: -39 }}>
      Ye know on earth, and all ye need to know.
    </p>
    <br />
    <strong>
      <p style={{ marginRight: -39 }}>John Keats</p>
    </strong>
    <i>
      <p style={{ marginRight: -39 }}>Ode on a Grecian Urn</p>
    </i>
  </Container>
);

export default Keats;
