export const menuData = {
  left: [{ url: "contest", name: "name the picture contest" }],
  right: [
    { url: "whynow", name: "why now?" },
    { url: "gallery", name: "galleries" },
    { url: "influences", name: "influences" },
    { url: "photoshoppe", name: "photoShoppe" },
    { url: "contact", name: "contact" },
  ],
};
