import React from "react";
import { Link } from "react-router-dom";
import { museum } from "./dataGallery";
import { Container, Title, Content } from "./styles";
import AutoSlides from "../../components/autoSlider";
import TransitionPage from "../../components/pageTransition";

const Gallery = () => {
  return (
    <TransitionPage>
      <Content>
        {museum.map((room, i) => (
          <Container key={i}>
            <Title>{room.floors}</Title>
            <div>
              <Link to={`/${room.floors}`}>
                <AutoSlides
                  arrIndex={museum.indexOf(room)}
                  slides={room.rooms.map((r) => r.image)}
                />
              </Link>
            </div>
          </Container>
        ))}
      </Content>
    </TransitionPage>
  );
};

export default Gallery;
