import styled from "styled-components";
import { motion } from "framer-motion";

//room.jsx
export const FloorName = styled(motion.h1)`
  text-transform: capitalize;
  font: 8vw Hidden Focus;
  width: 100%;
  grid-area: 13 / 20 / 18 / 30;
  align-items: center;
  display: grid;
  justify-items: center;
  z-index: 1;
  color: #331c65;
  justify-content: center;
`;

// gallery.jsx
export const Title = styled.h1`
  position: absolute;
  right: 0px;
  z-index: 1;
  opacity: 0;
  font: 6vw Hidden Focus;
  color: #331c65;
  text-transform: capitalize;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  &:hover ${Title} {
    opacity: 1;
    transition: opacity 1s;
  }
`;

export const Content = styled.div`
  display: flex;
  height: 100vh;
  & ${Container}:nth-child(1) {
    padding: 2%;
  }
  & ${Container}:nth-child(1) ${Title} {
    left: 80%;
    top: 10%;
  }
  & ${Container}:nth-child(2) {
    padding: 2% 0;
    display: flex;
    align-items: center;
  }
  & ${Container}:nth-child(2) ${Title} {
    left: 80%;
    top: 20%;
  }
  & ${Container}:nth-child(3) {
    padding: 2%;
    display: flex;
    align-items: flex-end;
  }
  & ${Container}:nth-child(3) ${Title} {
    right: 55%;
  }
`;
