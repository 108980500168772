import React, { useContext, useState, useCallback } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { ImagesContext } from "../../contexts/imagesContext";
import GalleryRoom from "../../components/galleryRoom";
import I1 from "../../images/DC/1.jpg";
import Modal from "../../components/Modal";
import ModalImage from "../../components/Images/modalImage";
import Audio from "../../audio/Ray Hanson - Directors Cut(Ver2).mp3";
import { GridContainer } from "../../generalStyles";

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(50, 1fr);
  grid-template-rows: repeat(30, 1fr);
  height: 100vh;
  overflow: hidden;
`;

const Director = () => {
  const { data } = useContext(ImagesContext);
  const [showModal, setShowModal] = useState(false);

  const audio = useCallback((node) => {
    if (node !== null) node.play();
  }, []);

  const openModal = () => setShowModal((prev) => !prev);

  return (
    <div>
      <Modal zIndex={19} showModal={showModal} setShowModal={setShowModal}>
        <audio ref={audio}>
          <source src={Audio} type="audio/mpeg" />
        </audio>

        <ModalImage
          image={I1}
          title={"Shadow of my former self"}
          date={"Cape Cod, South Wellfleet, MA 1991"}
        />
      </Modal>

      <div style={{ backgroundColor: "#252837" }}>
        <GridContainer>
          {data.DIRECTORCUT.images.map((el, i) => (
            <GalleryRoom data={el} index={i} key={i} />
          ))}

          {/* ///////////////// */}
          <div
            onClick={openModal}
            className="img-hover-zoom"
            style={{
              gridArea: "6 / 28 / 20 / 48",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1,
            }}
          >
            <img
              src={I1}
              alt="director's cut"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
            <motion.h1
              initial={{ translateY: 50, opacity: 0 }}
              animate={{
                translateY: 0,
                opacity: 1,
                transition: {
                  duration: 1,
                },
              }}
              exit={{ opacity: 0 }}
              style={{
                font: "5vw Hidden Focus",
                color: "white",
                position: "absolute",
                width: "80%",
              }}
            >
              DIRECTOR’S CUT A Field Guide
            </motion.h1>
          </div>
        </GridContainer>
      </div>
    </div>
  );
};

export default Director;
