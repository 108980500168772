import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import useMousePosition from "../hooks/useMousePosition";
import useOnScreen from "../hooks/useOnScreen";

const ItemGallery = styled.div`
  z-index: 1;
  height: 100%;
`;
const GalleryItemImage = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: clip-path 1s cubic-bezier(0.77, 0, 0.175, 1);
`;
const Image = styled.div`
  will-change: transform;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform-origin: center left;
  width: 100%;
  height: 100%;
  transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1);
`;
const ImageText = styled.div`
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 360px;
  white-space: normal;
  top: 24%;
  background-color: #ffffffa3;
  padding: 16px;
  transition: opacity 0.2s;
`;

const TextTitle = styled.h2`
  font: 30px Hidden Focus;
  text-transform: capitalize;
  color: #331c65;
`;
const TextParagraph = styled.p`
  font: 16px Kumbh Sans;
  color: #331c65;
  margin: 8px 0;
`;

const GalleryItem = ({ data, index, modal }) => {
  const ref = useRef(null);
  const onScreen = useOnScreen(ref, 0.9);

  const [hoverState, setHoverState] = useState(false);
  const { x } = useMousePosition();

  const [state, setState] = useState({ left: 0 });

  useEffect(() => {
    setState({
      left: ref.current.getBoundingClientRect().left,
    });
  }, [hoverState]);

  //scroll speed
  const getScrollIndex = () => {
    if (index % 2) return 0;
    return 1;
  };

  return (
    // <>
    <ItemGallery
      ref={ref}
      data-scroll
      data-scroll-speed={getScrollIndex()}
      style={{
        gridArea: `${data.gridRowStart} / ${data.gridColumnStart} / ${data.gridRowEnd} / ${data.gridColumnEnd}`,
      }}
    >
      <GalleryItemImage
        style={{
          clipPath: onScreen ? "inset(0% 0% 0% 0%)" : "inset(0% 100% 0% 0%)",
        }}
      >
        <Image
          onMouseEnter={() => setHoverState(true)}
          onMouseLeave={() => setHoverState(false)}
          onClick={() => modal(data, data.image)}
          style={{
            backgroundImage: `url(${data.image})`,
            transform: onScreen ? "scale(1)" : "scale(1.1)",
          }}
        />
      </GalleryItemImage>

      {data.floatingTitle && (
        <ImageText
          style={{ opacity: hoverState ? 1 : 0, left: x - state.left + 100 }}
        >
          <TextTitle>{data.floatingTitle}</TextTitle>
          {data.text.map((t, i) => (
            <TextParagraph key={i}>{t}</TextParagraph>
          ))}
        </ImageText>
      )}
    </ItemGallery>
    // </>
  );
};

export default GalleryItem;
