import React from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import I54 from "../../images/LATF/54.jpg";
import {
  HeaderHome,
  HeaderWrapper,
  HomePage,
  ImageWrapper,
  Phrase,
  SubTitle,
} from "./styles";
import ImageHover from "../../components/Images/imageHover";
import { HeaderAnim, ImageAnim, PhraseAnim } from "./homeAnimations";

const Home = ({ setDisclose }) => {
  return (
    <HomePage>
      <HeaderWrapper {...HeaderAnim}>
        <HeaderHome>hidden focus</HeaderHome>
        <SubTitle>Photography by Ray Hanson</SubTitle>
      </HeaderWrapper>
      <ImageWrapper onClick={() => setDisclose(true)} {...ImageAnim}>
        <Link to="/whynow">
          <ImageHover image={I54} />
        </Link>
      </ImageWrapper>
      <Phrase {...PhraseAnim}>
        Each of our lives is a different way of seeing
      </Phrase>
    </HomePage>
  );
};
export default Home;
