import { motion } from "framer-motion";
import styled from "styled-components";
import { device } from "../../generalStyles";

export const HomePage = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1366px;
  margin: auto;
`;

export const ImageWrapper = styled(motion.div)`
  width: 45%;
  margin: 0 auto;
  overflow: hidden;
`;
export const HeaderWrapper = styled(motion.div)`
  width: 78.5%;
  display: flex;
  flex-direction: column;
  align-self: center;
  z-index: 1;
`;
export const HeaderHome = styled.h1`
  font: 40px/34px Hidden Focus;
  color: #331c65;
  text-transform: capitalize;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font: 6vw/4vw Hidden Focus;
  }
`;
export const SubTitle = styled.h3`
  font-family: Kumbh Sans;
  font-size: 14px;
  color: #331c65;
  font-weight: 400;
  margin: 0 auto -28px 6px;
  @media ${device.tablet} {
    font-size: 20px;
    margin: 14px auto -34px 2px;
  }
  @media ${device.small} {
    margin: 14px auto -4% 20px;
  }
  @media ${device.medium} {
    font-size: 24px;
    margin: 14px auto -4% 46px;
  }
`;
export const Phrase = styled(motion.h4)`
  font: 24px/24px Mrs Saint Delafield;
  color: #331c65;
  margin: -34px 10px;
  align-self: flex-end;
  z-index: 1;
  @media ${device.tablet} {
    font: 30px/30px Mrs Saint Delafield;
    align-self: center;
    margin: -40px 0 0 46%;
  }
  @media ${device.small} {
    font: 2em/3vw Mrs Saint Delafield;
    margin: -60px 0 0 50%;
  }
  @media ${device.medium} {
    font: 2.8em/3vw Mrs Saint Delafield;
    margin: -72px 0 0 49%;
  }
`;
