import styled from "styled-components";
import React from "react";

const Container = styled.div`
  color: #331c65;
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 1.5;
  font: 1vw Kumbh Sans;
  padding: 8%;
  align-items: center;
`;

const Valedictory = () => (
  <Container>
    <h4>VALEDICTORY</h4>
    <br />
    <br />
    <p>chopped</p>
    <p>glass</p>
    <p>baked</p>
    <p>brick</p>
    <p>and</p>
    <p>leftover</p>
    <p>systems</p>
    <p>lightly tossed</p>
    <p>with moral dressing</p>
    <p>piled skyscraper high</p>
    <p>I suck acid at your steel breast and shrivel</p>
    <br />
    <p>ALMA MATER</p>
    <p>1968</p>
  </Container>
);

export default Valedictory;
