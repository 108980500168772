import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
// import Footer from "../../components/HorizontalScroll/footer";
import GalleryItem from "./galleryItem";
import Modal from "../../components/Modal";
import ModalImage from "../Images/modalImage";
import Rose from "../../pages/themes/LATF/Texts/rose";
import { P, TextWrapper } from "../../pages/WhyNow/styles";
import { PoemTitle } from "../../pages/themes/LATF/styles";
import { device } from "../../generalStyles";

const Content = styled.div`
  // position: relative;
  // align-items: center;
  // display: flex;
  // height: 100vh;
  // justify-content: center;
`;

const TitleContainer = styled.div`
  margin: auto;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TitleWrapper = styled.div`
  width: 80%;
  white-space: normal;
  text-align: center;
`;
const Title = styled(motion.h1)`
  // position: absolute;
  // top: 50%;
  // left: 0;
  // transform: translate(50%, -50%);
  // text-transform: capitalize;
  font: 3rem Hidden Focus;
  color: #431e57;
  @media ${device.small} {
    font-size: 4rem;
  }
  @media ${device.medium} {
    font-size: 6rem;
  }
`;

const VerticalScroll = ({ title, photos1, photos2, children }) => {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [showModalPoem, setShowModalPoem] = useState(false);

  const openModal = (data) => {
    setSelected(data);
    setShowModal((prev) => !prev);
  };

  const openModalPoems = () => setShowModalPoem((prev) => !prev);

  return (
    <div style={{ backgroundColor: "#f5f5f5" }}>
      <Modal
        showModal={showModalPoem}
        setShowModal={setShowModalPoem}
        style={{ height: "80%", justifyContent: "flex-start" }}
      >
        <div style={{ position: "relative", overflow: "scroll" }}>
          <h1
            style={{
              color: "#dcc02e",
              font: "3vw Mrs Saint Delafield",
              position: "fixed",
              backgroundColor: "#180920",
              width: "100%",
            }}
          >
            Stargate*
          </h1>
          <div style={{ margin: "80px 0 80px auto", width: "90%" }}>
            <TextWrapper>
              <PoemTitle>Setting Sail</PoemTitle>
              <P style={{ color: "#f9efff", margin: "16px 0" }}>
                I would wear no mask at all
                <br />
                Not even that of faithfulness
                <br />
                It shapes itself too easily
                <br />
                To jealousy and pride
                <br />
                <br />
                Yet faith can see through human eyes
                <br />A child yet to be
                <br />I would wear no mask at all
                <br />
                And seek that face with thee
              </P>
              <br />
            </TextWrapper>
            <TextWrapper style={{ marginLeft: "auto" }}>
              <PoemTitle style={{ color: "#a600ff" }}>Mirage</PoemTitle>
              <P style={{ color: "#f9efff", margin: "16px 0" }}>
                The Horseneck days have come again
                <br />
                And we begin our pilgrimage to the crescent beach
                <br />
                Where yearly we return like orphaned water creatures
                <br />
                To shed our coverings
                <br />
                <br />
                Tentative our words reach out and touch each other softly
                <br />
                Where the hurt and secret joys have been
                <br />
                Our silences embrace
                <br />
                <br />
                The Horseneck days have come again
                <br />
                Only this year when the coverings are shed
                <br />
                My lips would touch your gentleness
              </P>
              <br />
            </TextWrapper>
            <TextWrapper>
              <PoemTitle>Mutiny</PoemTitle>
              <P style={{ color: "#f9efff", margin: "16px 0" }}>
                Day after day the sun runs its course
                <br />
                Spring blossoms into summer
                <br />
                Ripens into autumn
                <br />
                And sleeps in the snow
                <br />
                <br />
                Eternally the river runs to the sea
                <br />
                The wheel of life turns the same for all
                <br />
                Ashes to ashes and dust to dust
                <br />
                <br />
                Yet while the wheel holds us high
                <br />
                Burning with the fullness of youth
                <br />
                Let us raise the brimming cup of passion to our lips
                <br />
                And taste the sweetness of love
                <br />
                <br />
                Eternally the river runs to the sea
                <br />
                The wheel of life turns the same for all
                <br />
                Ashes to ashes and dust to dust
              </P>
              <br />
            </TextWrapper>
            <TextWrapper style={{ marginLeft: "auto" }}>
              <PoemTitle style={{ color: "#a600ff" }}>Midnight</PoemTitle>
              <P style={{ color: "#f9efff", margin: "16px 0" }}>
                Where do poems come from
                <br />
                What makes them organize
                <br />
                They come from molten feelings
                <br />
                That make words crystallize
                <br />
                <br />
                This poem comes from longing
                <br />
                To stand once more by your side
                <br />
                And see my love reflected
                <br />
                In the lightning of your eyes
              </P>
              <br />
            </TextWrapper>
            <TextWrapper style={{ margin: "auto" }}>
              <PoemTitle>Homecoming</PoemTitle>
              <P style={{ color: "#f9efff", margin: "16px 0" }}>
                My love for you is an evening star
                <br />
                That glows once more toward the end of day
                <br />
                <br />
                My love for you is an ancient scar
                <br />
                A gash in youth that bled away
                <br />
                <br />
                My love for you is the morningstar
                <br />
                The glint of light that leads the way
              </P>
              <br />
            </TextWrapper>
            <TextWrapper style={{ width: "100%", marginTop: "10%" }}>
              <P style={{ color: "#dcc02e" }}>
                *This is a family of 5 poems that were born unrelated. They
                emerged in this order independently and nameless over the years.
                The first appeared in college days, and the fifth in 2019.
                Shortly after the last one arrived, I woke up one morning with
                an image of all 5 working together like a traditional song
                cycle. <i>Stargate</i> was its name, and the 5 individual titles
                told the story of a pilgrim’s progress…
              </P>
            </TextWrapper>
          </div>
        </div>
      </Modal>

      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        style={{ height: selected.layout === "vertical" ? "80%" : "auto" }}
      >
        <ModalImage
          id={selected.id}
          image={selected.image}
          title={selected.title}
          date={selected.date}
          noInfo={selected.noInfo}
          layout={selected.layout}
        />
      </Modal>

      {/* <div className="scroll-container" data-scroll-container ref={ref}> */}
      <div>
        <Content>
          {title && (
            <TitleContainer>
              <TitleWrapper>
                <Title
                  data-scroll
                  data-scroll-speed={-5}
                  initial={{ translateY: 50, opacity: 0 }}
                  animate={{ translateY: 0, opacity: 1 }}
                  transition={{
                    duration: 1,
                  }}
                  exit={{ opacity: 0 }}
                >
                  {title}
                </Title>
              </TitleWrapper>
            </TitleContainer>
          )}
          <div>
            {children}
            {photos1.map((el, i) => (
              <GalleryItem
                data={el}
                key={i}
                index={i}
                modalImage={openModal}
                modalText={openModalPoems}
              />
            ))}

            {photos2 && (
              <>
                <Rose modal={openModalPoems} />
                {photos2.map((el, i) => (
                  <GalleryItem
                    data={el}
                    key={i}
                    index={i}
                    modalImage={openModal}
                    modalText={openModalPoems}
                  />
                ))}
              </>
            )}
          </div>
          {/* <Footer /> */}
        </Content>
      </div>
    </div>
  );
};

export default VerticalScroll;
