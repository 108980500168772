import Enrico1 from "../../images/INFL/enrico1.jpg";
import Enrico2 from "../../images/INFL/enrico2.jpg";
import BookCard from "../../images/INFL/bookCardCollage.jpg";
import Circles from "../../images/INFL/book.jpg";
import Robert from "../../images/INFL/WEB-Infl-2-Robert-Mann-33.3KB.jpg";
import RayLawrence from "../../images/INFL/Lawrence_Ray.jpg";
import Lawrence from "../../images/INFL/WEB-Infl-3-Lawrence-2037-263kb.jpg";
import Laliberte from "../../images/INFL/WEB-Infl-4-2037-Laliberte-258kb.jpg";
import Hands from "../../images/INFL/WEB--890-253kb.jpg";
import Dorothy from "../../images/INFL/WEB-Infl-5-8063-Dorothy-371kb.jpg";
import Carvalho from "../../images/INFL/WEB-Infl-6-John-Carvalho-284kb.jpg";
import Amel from "../../images/INFL/WEB-Infl-7-9517-Amel-274kb.jpg";
import Michelle from "../../images/INFL/WEB-Infl-8-7570-Michelle-275kb.jpg";
import Cathy from "../../images/INFL/WEB-Infl-9.-676-250kb.jpg";
import React from "react";

import styled from "styled-components";
import { Link } from "react-router-dom";

export const AHref = styled.a`
  text-decoration: none;
  color: #ced4a7;
  font-weight: bold;
`;

export const EnricoText = (
  <>
    <p>
      Artist, scholar, musician, composer, dramatist and teacher.&nbsp;&nbsp;
      Friend and mentor since college days.&nbsp;&nbsp; His insight and
      compassion are a formative and abiding light.&nbsp;&nbsp; Enrico is the
      first person ever to be awarded a Doctorate in Comparative Literature from
      Brown University.&nbsp;&nbsp; His doctoral thesis{" "}
      <i>Circles Without Center</i> was published by Harvard University Press in
      1972, and re-editioned in 2014.
      <br />
      <br />I wonder at the synchronicity of our titles and cover art...
    </p>
    <br />
    <p style={{ paddingBottom: "16%" }}>
      Explore{" "}
      <AHref href="https://www.enricogarzilli.com/">Enrico’s world</AHref>, and
      hear the music.
    </p>
    <br />
    <p style={{ color: "lightgray", marginBottom: "20%" }}>
      Photos courtesy Enrico F. Garzilli
    </p>
  </>
);

export const RobertText = (
  <>
    <p style={{ paddingBottom: "16%" }}>
      Bob introduced me to the world of collectible photography shortly after I
      moved to New York in 1980.&nbsp;&nbsp; He founded{" "}
      <AHref href="http://www.robertmann.com/">Robert Mann Gallery</AHref> in
      1985.&nbsp;&nbsp; For the next decade plus, he helped sharpen my
      photographic eye, rifling together through the semi annual Christie’s and
      Sotheby’s auction catalogues, and introducing me to the work of (among
      others) Louis Faurer, Aaron Siskind, Lewis Hine, Richard Misrach, and
      Margaret Watkins. Over the years, Bob has sunk deep roots in the classical
      photography market, while introducing and publishing the work of
      significant new artists.
    </p>
    <br />
    <p style={{ color: "lightgray" }}>Photo courtesy Robert Mann Gallery</p>
  </>
);

export const LawrenceText = (
  <>
    <p>
      Singer/Actor/Dancer.&nbsp;&nbsp; We met in Toronto in 1991, made a home
      together in 1996, and have been married since 2004.&nbsp;&nbsp; More than
      “just” a performance triple threat, Laurance has always radiated through
      the grace of his physical bearing and the rich timbre of his voice a sense
      of deep connection with our primal source of energy.
    </p>
    <br />
    <p>
      His persistent devotion to performance excellence even in the face of
      chronic illness has inspired me, and the chance to{" "}
      <AHref href="https://www.youtube.com/watch?v=b7wFW4xxqVk">
        appear on stage with him{" "}
      </AHref>{" "}
      and sing a song has been a source of great joy.
    </p>
    <br />
    <p>
      Watch Lawrence’s spellbinding rendition of{" "}
      <AHref
        style={{ fontStyle: "italic" }}
        href="https://www.youtube.com/watch?v=ROppespP1Jk"
      >
        “Music of the Night”{" "}
      </AHref>
      , hear him voice our deepest longing in{" "}
      <AHref
        style={{ fontStyle: "italic" }}
        href="https://www.youtube.com/watch?v=T4ADdD3MIL0"
      >
        “Take Me Home”
      </AHref>
      .
    </p>
    <br />
    <p>
      In recent times, Lawrence has become something of a self-taught master in
      getting the most out of the iMovies program. Enjoy the startling effects
      in this presentation of his original song{" "}
      <AHref
        style={{ fontStyle: "italic" }}
        href="https://www.youtube.com/watch?v=qwLHlIBvAz4"
      >
        "Next Plane to Tibet"
      </AHref>
      .
    </p>
    <br />
    <p style={{ paddingBottom: "16%" }}>
      See more on Lawrence
      <AHref href="https://www.youtube.com/channel/UCvOD5HwPtCWt7tu_42YUYIQ">
        YouTube channel
      </AHref>
      .
    </p>
  </>
);

export const LaliberteText = (
  <>
    <p>
      Montreal was a prominent stop on my semi-annual research marketing tours
      for RBC.&nbsp;&nbsp; Over the years, I had noted and enjoyed Robert’s
      cover photos for <i>Fugues magazine</i>, but we had never met.&nbsp;&nbsp;
      The opportunity came in 2003 when Lawrence needed something special for
      his business card.&nbsp;&nbsp; When we met for the shoot, I was impressed
      by Robert’s professionalism and no nonsense approach to the
      work.&nbsp;&nbsp; Equally, though, I was taken with his warmth and power
      to connect, qualities that since the early 1980’s had supported his role
      as a major player in the emergence of gay culture in Montreal.
    </p>
    <br />
    <p>
      On subsequent visits I would look forward to catching up with him over
      dinner, excited for the chance to show him a few shots from my most recent
      travels.&nbsp;&nbsp; His observations were always supportive, measured and
      on point.
    </p>
    <br />
    <p style={{ paddingBottom: "16%" }}>
      Sitting in Robert’s living room, I could feel from his images around me
      the ironic stare of Diane Arbus, the streetwise capture of
      Cartier-Bresson, the canine play of William Wegman, the erotic thrust and
      elegance of Robert Mapplethorpe.&nbsp;&nbsp; But no lame stylistic
      imitations here.&nbsp;&nbsp; Just pure Robert, and his unique vision of
      our shared humanity.&nbsp;&nbsp; Salut, Robert!
    </p>
    <br />
    <p style={{ paddingBottom: "16%" }}>
      Visit with Robert at{" "}
      <AHref href="https://robertlaliberte.com/">robertlaliberte.com</AHref>
    </p>
    <br />
    <p style={{ color: "lightgray", marginBottom: "20%" }}>
      <i>Healing Reiki Hands</i> and couple portrait by Robert Laliberté
    </p>
  </>
);

export const DorothyText = (
  <>
    <p style={{ paddingBottom: "16%" }}>
      My Ariadne.&nbsp;&nbsp; In the summer of 2005, Dorothy conducted a vocal
      masterclass in Toronto, and I decided to attend as an auditor.&nbsp;&nbsp;
      She had apprenticed for many years with bel canto master Cornelius L.
      Reid, and internalized the fundamental principles he articulated in{" "}
      <i>The Free Voice: A Guide to Natural Singing</i>.&nbsp;&nbsp; What I
      heard and saw that day at masterclass convinced me that if ever I was to
      deliver a song the way I felt it should be sung, Dorothy was the one to
      enable it.&nbsp;&nbsp; And so it has been!
    </p>
    <br />
    <p>
      Find Dorothy at{" "}
      <AHref href="https://restoremyvoice.com/"> restoremyvoice.com</AHref>
    </p>
  </>
);

export const JohnText = (
  <>
    <p>
      For several years John had been making 8.5" x 11" prints from my photo
      exploits on business trips and holidays.&nbsp;&nbsp; One day in late 2009,
      he took me by surprise saying "You know, you really should step up and buy
      a good camera... the kind of shots you’re getting would look great in
      larger formats, but what you’re shooting now just can’t handle
      that."&nbsp;&nbsp; So, with his help, I got my first Canon EOS 5D at New
      Years 2010, just before heading off on a trip to Cuba.&nbsp;&nbsp; He was
      so right!
    </p>
    <br />
    <p>
      John is an accomplished portrait artist and dauntless teacher.&nbsp;&nbsp;
      With infinite patience he has guided me into the age of digital
      photography, and freely shared his encyclopedic knowledge of Photoshop and
      Picassa.&nbsp;&nbsp; Thanks to him, my pictures are now ready to be shown.
    </p>
    <br />
    <p>
      Meet John at{" "}
      <AHref href="https://exposures.ca/"> Exposures Photography</AHref>.
    </p>
    <br />
    <p
      style={{ color: "lightgray", marginBottom: "20%", paddingBottom: "16%" }}
    >
      Photo courtesy Exposures Photography
    </p>
  </>
);

export const AmelText = (
  <>
    <p>
      World class painter, sculptor, graphic artist and designer now living in
      Belgium.&nbsp;&nbsp; We met in his native Havana in early 2009, where his
      intensity, mastery and devotion to his art rekindled in me creative fires
      that had lain dormant for many years.&nbsp;&nbsp; A few months later we
      showed our work together in a four man exposition called{" "}
      <Link
        style={{ textDecoration: "none", color: "#ced4a7", fontWeight: "bold" }}
        to="/cuatro-amigos"
      >
        'Quattro Amigos, Una Cuba.'
      </Link>
    </p>
    <br />
    <p style={{ paddingBottom: "16%" }}>
      Since moving to Europe, Amel has begun exploring the artistic potential of
      the digital world.&nbsp;&nbsp; It is{" "}
      <AHref href="https://amelcaballero.com">Amel</AHref> who has designed and
      built this website for Hidden Focus.
    </p>
  </>
);

export const MichelleText = (
  <>
    <p>
      Michelle, a mother of twin teenage daughters, wedding planner, home
      renovator and skilled graphic artist, spent a disproportionate part of her
      youth touring museums and galleries across North America and abroad.
    </p>
    <br />
    <p>
      We became friends in June 2018 when Lawrence and I prepared to move out of
      Toronto’s downtown core to the wilds of Scarborough.&nbsp;&nbsp; For a
      gruelling week, Michelle and I worked day and night sprucing up the new
      place just prior to the move.&nbsp;&nbsp; We talked a lot about art, and I
      promised to show her some of my pictures after settling in.
    </p>
    <br />
    <p>
      So, one day the following Spring, after touring my photo world of Cuba,
      Cape Cod, New York and Paris, and seeing the earliest of the{" "}
      <i>Look at the Flowers</i> images, she remarked: “This is most
      unusual.&nbsp;&nbsp; Your eye for composition and use of so many different
      styles is something I have never seen.&nbsp;&nbsp; I’m blown
      away...&nbsp;&nbsp; But you’ve got to print these in larger formats, much
      larger.&nbsp;&nbsp; You think you know what’s in the frames because you
      took them.&nbsp;&nbsp; But I’m telling you, even you won’t really be able
      to appreciate what you’ve got until you see them bigger.”
    </p>
    <br />
    <p>
      As she put on her coat that day to leave, she turned to me with a kind but
      troubled look and said: “As much as I love your pictures, I must tell you
      something that will come as a surprise.&nbsp;&nbsp; I’m really angry right
      now.&nbsp;&nbsp; Angry that your work has been hidden away for so long in
      little boxes.&nbsp;&nbsp; They must be shown!&nbsp;&nbsp; You must find a
      way to do that.&nbsp;&nbsp; I will help any way I can.”
    </p>
    <br />
    <p style={{ paddingBottom: "16%" }}>
      Deepest gratitude, Michelle, for pushing my little boxes out of the nest!
    </p>
  </>
);

export const CathyText = (
  <div>
    <p>
      For Cathy, whose selfless love in the early Wall Street days grounded
      everything that’s followed...
    </p>
  </div>
);

export const info = [
  {
    id: 0,
    date: "1965",
    image: [
      {
        image: Enrico1,
        css: { width: "100%", objectFit: "contain", height: "100%" },
      },
      {
        image: Circles,
        css: { width: "60%", objectFit: "contain", height: "100%" },
      },
      {
        image: Enrico2,
        css: { width: "100%", objectFit: "contain", height: "100%" },
      },
      {
        image: BookCard,
        css: { width: "100%", objectFit: "contain", height: "100%" },
      },
    ],
    name: "Enrico Garzilli",
    content: EnricoText,
  },
  {
    id: 1,
    date: "1981",
    image: [
      {
        img: Robert,
        css: { width: "100%", objectFit: "contain", height: "100%" },
      },
    ],
    name: "Robert Mann",
    content: RobertText,
    css: { width: "100%", objectFit: "contain", height: "100%" },
  },
  {
    id: 2,
    date: "1991",
    image: [
      {
        img: Lawrence,
        css: { width: "100%", objectFit: "contain", height: "100%" },
      },
    ],
    name: "Lawrence Tan",
    content: LawrenceText,
  },
  {
    id: 3,
    date: "2003",
    image: [
      {
        image: Laliberte,
        css: { width: "100%", objectFit: "contain", height: "100%" },
      },
      {
        image: Hands,
        css: { width: "60%", objectFit: "contain", height: "100%" },
      },
      {
        image: RayLawrence,
        css: { width: "60%", objectFit: "contain", height: "100%" },
      },
    ],
    name: "Robert Laliberté",
    content: LaliberteText,
    // css: { width: "80%" },
  },
  {
    id: 4,
    date: "2005",
    image: [
      {
        img: Dorothy,
        css: { width: "100%", objectFit: "contain", height: "100%" },
      },
    ],
    name: "Dorothy Stone",
    content: DorothyText,
    // css: { width: "70%" },
  },
  {
    id: 5,
    date: "2006",
    image: [
      {
        img: Carvalho,
        css: { width: "100%", objectFit: "contain", height: "100%" },
      },
    ],
    name: "John Carvalho",
    content: JohnText,
    // css: { width: "70%" },
  },
  {
    id: 6,
    date: "2009",
    image: [
      {
        img: Amel,
        css: { width: "100%", objectFit: "contain", height: "100%" },
      },
    ],
    name: "Amel Caballero",
    content: AmelText,
    // css: { width: "70%" },
  },
  {
    id: 7,
    date: "2018",
    image: [
      {
        img: Michelle,
        css: { width: "100%", objectFit: "contain", height: "100%" },
      },
    ],
    name: "Michelle Patience",
    content: MichelleText,
    // css: { width: "70%" },
  },
  {
    id: 8,
    date: "1978",
    image: [
      {
        img: Cathy,
        css: { width: "100%", objectFit: "contain", height: "100%" },
      },
    ],
    name: "Cathy Bedard",
    content: CathyText,
    // css: { width: "70%" },
  },
];
