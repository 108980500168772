import styled from "styled-components";
import React from "react";

const Container = styled.div`
  color: #737e26;
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 1.5;
  font: 1vw Kumbh Sans;
  padding: 8% 0;
`;

const Thomas = () => (
  <Container>
    <div>
      <p>Full many a flow'r is born to blush unseen</p>
      <p>and waste its sweetness on the desert air.</p>
    </div>
    <div
      style={{
        margin: "2%",
        alignItems: "flex-end",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p style={{ alignSelf: "flex-end", fontWeight: 600 }}>Thomas Gray</p>
      <p>
        <i>Elegy Written in a Country Churchyard</i>
      </p>
    </div>
  </Container>
);

export default Thomas;
