import { useContext } from "react";
import HorizontalScroll from "../../components/HorizontalScroll/horizontalScroll";
import { ImagesContext } from "../../contexts/imagesContext";
import React from "react";

const FDTL = () => {
  const { data } = useContext(ImagesContext);

  return (
    <HorizontalScroll
      width={"220vw"}
      columnAmount={90}
      title={data.FROMDARKNESSTOLIGHT.title}
      photos={data.FROMDARKNESSTOLIGHT.images}
    ></HorizontalScroll>
  );
};

export default FDTL;
