import { GridContainer } from "../../generalStyles";
import { FloorName } from "./styles";
import { museum } from "./dataGallery";
import GalleryRoom from "../../components/galleryRoom";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { staggerChildren: 0.35 } },
  exit: { opaciy: 0, transition: { ease: "easeInOut" } },
};

const Room = ({ floor }) =>
  museum.map((room, i) => {
    if (room.floors === floor) {
      return (
        <GridContainer {...containerVariants} key={i}>
          <FloorName
            key={i}
            initial={{ translateY: 50, opacity: 0 }}
            animate={{ translateY: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            exit={{ opacity: 0 }}
          >
            {room.floors}
          </FloorName>
          {room.rooms.map((r, i) => (
            <GalleryRoom data={r} index={i} key={i} />
          ))}
        </GridContainer>
      );
    }
  });

export default Room;
