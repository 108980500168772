import React, { useState, useEffect, createContext } from "react";
import { ANGKORWAT } from "../NewData/dataAngkorWat";
import { LOOKATTHEFLOWERS1, LOOKATTHEFLOWERS2 } from "../NewData/dataLatf";
import { NEWYORK } from "../NewData/dataNewYork";
import { LIGHTSCAPES } from "../NewData/dataLightscapes";
import { DIRECTORCUT } from "../NewData/dataDirector";
import { FROMDARKNESSTOLIGHT } from "../NewData/dataFdtl";
import { ENESCU } from "../NewData/dataEnescu";
import { CUATROAMIGOS } from "../NewData/dataAmigos";
import { JUSTCOLOR } from "../NewData/dataJustColor";
import { ABSTRACT } from "../NewData/dataAbstract";
import { CARAVAN } from "../NewData/dataCaravan";
import { PARIS } from "../NewData/dataParis";
import { VENICE } from "../NewData/dataVenice";

export const ImagesContext = createContext();

const ImagesContextProvider = ({ children }) => {
  const [imagesPS, setImagesPS] = useState(
    localStorage.getItem("arrLocalStorage")
      ? JSON.parse(localStorage.getItem("arrLocalStorage"))
      : []
  );
  const [toggle, setToggle] = useState(false);

  const clearImagesOnSubmit = () => setImagesPS([]);

  const addToPS = (id, image) => {
    data.imagesPS.findIndex((el) => el.id === id) === -1
      ? data.imagesPS.push({ id, image })
      : data.imagesPS.splice(
          data.imagesPS.findIndex((el) => el.id === id),
          1
        );
    setImagesPS([...imagesPS]);
    setToggle((prev) => !prev);
  };

  const data = {
    toggle,
    imagesPS,
    addToPS,
    clearImagesOnSubmit,
    ANGKORWAT,
    LOOKATTHEFLOWERS1,
    LOOKATTHEFLOWERS2,
    NEWYORK,
    LIGHTSCAPES,
    DIRECTORCUT,
    FROMDARKNESSTOLIGHT,
    ENESCU,
    CUATROAMIGOS,
    JUSTCOLOR,
    ABSTRACT,
    CARAVAN,
    PARIS,
    VENICE,
  };

  useEffect(() => {
    localStorage.setItem("arrLocalStorage", JSON.stringify(imagesPS));
  }, [imagesPS]);

  return (
    <ImagesContext.Provider value={{ data }}>{children}</ImagesContext.Provider>
  );
};

export default ImagesContextProvider;
