import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import useMousePosition from "../components/hooks/useMousePosition";
import OfficialLogo from "../images/SVG/Logo_Official.svg";

const Image = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  transform-origin: center;
  transition: transform 0.8s ease;
  &:hover {
    transform: scale(1.1);
  }
`;

const ItemGallery = styled(motion.div)`
  z-index: 1;
  height: 100%;
  position: relative;
`;
const GalleryItemImage = styled.div`
  width: 100%;
  height: 100%;
`;

const LogoLAyer = styled.div`
  overflow: hidden;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 5%;
`;
const ImageText = styled.div`
  position: absolute;
  width: 100%;
  opacity: 1;
  padding: 16px;
  z-index: 20;
`;

const TextTitle = styled.h2`
  padding: 16px;
  font: 30px Hidden Focus;
  text-transform: capitalize;
  color: #331c65;
  background-color: #ffffffa3;
  position: absolute;
`;

const GalleryRoom = ({ data, index }) => {
  const ref = useRef(null);
  const [hoverState, setHoverState] = useState(false);

  const { x, y } = useMousePosition();

  return (
    <>
      <ItemGallery
        ref={ref}
        style={{
          gridArea: `${data.gridRowStart} / ${data.gridColumnStart} / ${data.gridRowEnd} / ${data.gridColumnEnd}`,
          zIndex: hoverState ? 4 : 0,
        }}
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: 1,
            delay: index % 2 === 0 ? index * 0.5 - 1 : index * 0.2,
          },
        }}
        exit={{ opacity: 0 }}
      >
        <GalleryItemImage>
          <Image
            style={{
              backgroundImage: `url(${data.image})`,
            }}
          >
            <Link to={`/${data.to}`}>
              <LogoLAyer
                onMouseOver={() => setHoverState(true)}
                onMouseOut={() => setHoverState(false)}
                style={{
                  backgroundImage:
                    hoverState && data.comingSoon ? `url(${OfficialLogo})` : "",
                  backgroundColor:
                    hoverState && data.comingSoon ? "#2f123db3" : "",
                }}
              />
            </Link>
          </Image>
        </GalleryItemImage>
      </ItemGallery>
      {hoverState && (
        <ImageText
          style={{
            left: x + 20,
          }}
        >
          {!data.comingSoon ? (
            <TextTitle style={{ top: y }}>{data.title}</TextTitle>
          ) : (
            <TextTitle style={{ top: y }}>Coming Soon</TextTitle>
          )}
        </ImageText>
      )}
    </>
  );
};

export default GalleryRoom;
