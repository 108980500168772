import I1 from "../images/PARIS/1.jpg";
import I2 from "../images/PARIS/2.jpg";
import I3 from "../images/PARIS/3.jpg";
import I4 from "../images/PARIS/4.jpg";
import I5 from "../images/PARIS/5.jpg";
import I6 from "../images/PARIS/6.jpg";
import I7 from "../images/PARIS/7.jpg";
import I8 from "../images/PARIS/8.jpg";
import I9 from "../images/PARIS/9.jpg";
import I10 from "../images/PARIS/10.jpg";
import I11 from "../images/PARIS/11.jpg";
import I12 from "../images/PARIS/12.jpg";
import I13 from "../images/PARIS/13.jpg";
import I14 from "../images/PARIS/14.jpg";
import I15 from "../images/PARIS/15.jpg";
import I16 from "../images/PARIS/16.jpg";
import I17 from "../images/PARIS/17.jpg";

export const PARIS = {
  title: "paris",
  images: [
    {
      image: I1,
      title: "",
      text: [],
      // gridRowStart: 1,
      // gridColumnStart: 50,
      // gridRowEnd: 4,
      // gridColumnEnd: 58,
      layout: "horizontal",
      noInfo: true,
    },
    {
      image: I2,
      title: "",
      text: [],
      // gridRowStart: 1,
      // gridColumnStart: 50,
      // gridRowEnd: 4,
      // gridColumnEnd: 58,
      layout: "horizontal",
    },
    {
      image: I3,
      title: "",
      text: [],
      // gridRowStart: 1,
      // gridColumnStart: 50,
      // gridRowEnd: 4,
      // gridColumnEnd: 58,
      layout: "horizontal",
    },
    {
      image: I4,
      title: "",
      text: [],
      // gridRowStart: 1,
      // gridColumnStart: 50,
      // gridRowEnd: 4,
      // gridColumnEnd: 58,
      layout: "horizontal",
    },
    {
      image: I5,
      title: "",
      text: [],
      // gridRowStart: 1,
      // gridColumnStart: 50,
      // gridRowEnd: 4,
      // gridColumnEnd: 58,
      layout: "horizontal",
    },
    {
      image: I6,
      title: "",
      text: [],
      // gridRowStart: 1,
      // gridColumnStart: 50,
      // gridRowEnd: 4,
      // gridColumnEnd: 58,
      layout: "horizontal",
    },
    {
      image: I7,
      title: "",
      text: [],
      // gridRowStart: 1,
      // gridColumnStart: 50,
      // gridRowEnd: 4,
      // gridColumnEnd: 58,
      layout: "horizontal",
    },
    {
      image: I8,
      title: "",
      text: [],
      // gridRowStart: 1,
      // gridColumnStart: 50,
      // gridRowEnd: 4,
      // gridColumnEnd: 58,
      layout: "horizontal",
    },
    {
      image: I9,
      title: "",
      text: [],
      // gridRowStart: 1,
      // gridColumnStart: 50,
      // gridRowEnd: 4,
      // gridColumnEnd: 58,
      layout: "horizontal",
    },
    {
      image: I10,
      title: "",
      text: [],
      // gridRowStart: 1,
      // gridColumnStart: 50,
      // gridRowEnd: 4,
      // gridColumnEnd: 58,
      layout: "horizontal",
    },
    {
      image: I11,
      title: "",
      text: [],
      // gridRowStart: 1,
      // gridColumnStart: 50,
      // gridRowEnd: 4,
      // gridColumnEnd: 58,
      layout: "horizontal",
    },
    {
      image: I12,
      title: "",
      text: [],
      // gridRowStart: 1,
      // gridColumnStart: 50,
      // gridRowEnd: 4,
      // gridColumnEnd: 58,
      layout: "horizontal",
    },
    {
      image: I13,
      title: "",
      text: [],
      // gridRowStart: 1,
      // gridColumnStart: 50,
      // gridRowEnd: 4,
      // gridColumnEnd: 58,
      layout: "horizontal",
    },
    {
      image: I14,
      title: "",
      text: [],
      // gridRowStart: 1,
      // gridColumnStart: 50,
      // gridRowEnd: 4,
      // gridColumnEnd: 58,
      layout: "horizontal",
    },
    {
      image: I15,
      title: "",
      text: [],
      // gridRowStart: 1,
      // gridColumnStart: 50,
      // gridRowEnd: 4,
      // gridColumnEnd: 58,
      layout: "horizontal",
    },
    {
      image: I16,
      title: "",
      text: [],
      // gridRowStart: 1,
      // gridColumnStart: 50,
      // gridRowEnd: 4,
      // gridColumnEnd: 58,
      layout: "horizontal",
    },
    {
      image: I17,
      title: "",
      text: [],
      // gridRowStart: 1,
      // gridColumnStart: 50,
      // gridRowEnd: 4,
      // gridColumnEnd: 58,
      layout: "horizontal",
    },
  ],
};
