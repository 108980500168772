import React, { useState, useEffect } from "react";
import SliderContent from "./sliderContent";
import Arrows from "./arrow";
import Dots from "./dots";
const Slider = ({
  images,
  withText,
  autoPlay,
  dotsOff,
  arrowsOff,
  delay,
  activeIndexMementos,
  width,
  height,
}) => {
  const secDelay = delay * 1000;
  const len = images.length - 1;
  const [activeIndex, setActiveIndex] = useState(
    activeIndexMementos > 0 ? activeIndexMementos : 0
  );

  useEffect(() => {
    if (autoPlay) {
      const interval = setInterval(() => {
        setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
      }, secDelay);
      return () => clearInterval(interval);
    }
  }, [activeIndex, autoPlay, len, secDelay, activeIndexMementos]);

  return (
    <div style={{ position: "relative", margin: "auto" }}>
      <SliderContent
        activeIndex={activeIndex}
        images={images}
        withText={withText}
        width={width}
        height={height}
        // arrIndex={activeIndex}
      />

      {!arrowsOff && !autoPlay && (
        <Arrows
          prevSlide={() =>
            setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
          }
          nextSlide={() =>
            setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
          }
        />
      )}

      {images.length > 1 && !dotsOff && (
        <Dots
          activeIndex={activeIndex}
          images={images}
          onclick={(activeIndex) => setActiveIndex(activeIndex)}
        />
      )}
    </div>
  );
};

export default Slider;
