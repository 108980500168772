export const DDMenuLayer1 = {
  initial: {
    height: 0,
  },
  animate: {
    height: "100vh",
    transition: { duration: 0.3 },
  },
  exit: {
    height: 0,
    transition: { delay: 0.1, duration: 0.5 },
  },
};
export const DDMenuLayer2 = {
  initial: {
    height: 0,
  },
  animate: {
    height: "100vh",
    transition: { delay: 0.1, duration: 0.3 },
  },
  exit: {
    height: 0,
    transition: { delay: 0.1, duration: 0.4 },
  },
};

export const MenuItems = {
  initial: {
    x: -50,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: { delay: 0.2, duration: 0.4 },
  },
  exit: {
    x: 50,
    opacity: 0,
    transition: { duration: 0.2 },
  },
};

export const ConstestImageAnim = {
  initial: {
    clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
  },
  animate: {
    clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
    transition: { delay: 0.2, duration: 0.4 },
  },
  exit: {
    clipPath: "polygon(100% 0, 100% 0%, 100% 100%, 100% 100%)",
    transition: { duration: 0.2 },
  },
};
