import ComingSoon from "../../../components/ComingSoon/ComingSoon";
import { DIRECTORCUT } from "../../../NewData/dataDirector";
import React from "react";

const FLUA = () => (
  <ComingSoon
    title={DIRECTORCUT.images[5].title}
    image={DIRECTORCUT.images[5]}
  />
);

export default FLUA;
