import styled from "styled-components";
import React from "react";

const Container = styled.div`
  color: #331c65;
  display: flex;
  flex-direction: column;
  padding: 4%;
  font: 1vw Kumbh Sans;
`;

const Windhover = () => (
  <Container>
    <p style={{ font: "40px Hidden Focus", color: "#331c65" }}>The Windhover</p>
    <p
      style={{
        font: "600 20px Kumbh Sans",
        color: "#602380",
        marginLeft: 10,
      }}
    >
      To Christ our Lord
    </p>
    <br />
    <div style={{ width: "100%" }}>
      <p>I caught this morning morning's minion, king-</p>
      <p style={{ marginLeft: 16 }}>
        dom of daylight's dauphin, dapple-dawn-drawn Falcon, in his riding
      </p>
      <p style={{ marginLeft: 16 }}>
        Of the rolling level underneath him steady air, and striding
      </p>
      <p>High there, how he rung upon the rein of a wimpling wing</p>
      <p>In this ecstasy! then off, off forth on swing.</p>
      <p style={{ marginLeft: 16 }}>
        As a skate's heel sweeps smooth on a bow-bend: the hurl and gliding
      </p>
      <p style={{ marginLeft: 16 }}>
        Rebuffed the big wind. My heart in hiding
      </p>
      <p>Stirred for a bird, --the achieve of, the mastery of the thing!</p>
      <br />
      <p>Brute beauty and valour and act, oh, air, pride, plume, here</p>
      <p style={{ marginLeft: 16 }}>
        Buckle! AND the fire that breaks from thee then, a billion
      </p>
      <p>Times told lovelier, more dangerous, O my chevailer!</p>
      <br />
      <p style={{ marginLeft: 16 }}>
        No wonder of it: sheer plod makes plough down sillion
      </p>
      <p>Shine, and blue-bleak embers, ah my dear,</p>
      <p style={{ marginLeft: 16 }}>
        Fall, gall themselves, and gash gold-vermillion
      </p>

      <p
        style={{
          margin: "4% 0 0 20%",
          fontWeight: 600,
          color: "#602380",
        }}
      >
        Gerard Manley Hopkins
      </p>
    </div>
  </Container>
);

export default Windhover;
