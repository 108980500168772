import styled from "styled-components";
import React from "react";

const Container = styled.div`
  color: #431e57;
  display: flex;
  flex-direction: column;
  width: 100%;
  font: 1vw Kumbh Sans;
  padding: 12% 8%;
`;

const Georgia = () => (
  <Container>
    <p>
      When you take a flower in your hand and really look at it, it’s your world
      for a moment. I want to give that world to someone else. Most people in
      the city rush around so, they have no time to look at a flower. I want
      them to see it whether they want to or not.
    </p>
    <br />
    <br />
    <p>
      <strong>Georgia O’Keeffe</strong>
    </p>
  </Container>
);

export default Georgia;
