import W0241 from "../../../images/GE/WEB-0241-281kb.jpg";
import W0563 from "../../../images/GE/WEB-0563-247kb.jpg";
import W0789 from "../../../images/GE/WEB-0789-254kb.jpg";
import W0900 from "../../../images/GE/WEB-0900-247kb.jpg";
import W2169 from "../../../images/GE/WEB-2169-258kb.jpg";
import W2555 from "../../../images/GE/WEB-2555-263kb.jpg";
import I1 from "../../../images/MX/WEB-0762-250kb.jpg";
import I2 from "../../../images/MX/WEB-Contest-Sign-251kb.jpg";

export const dataMexico = {
  title: "Como en Casa",
  images: [
    {
      image: I1,
      title: "",
      text: [],
      gridRowStart: 2,
      gridColumnStart: 18,
      gridRowEnd: 5,
      gridColumnEnd: 30,
      layout: "horizontal",
      noInfo: true,
    },
    {
      image: I2,
      title: "",
      text: [],
      gridRowStart: 3,
      gridColumnStart: 32,
      gridRowEnd: 5,
      gridColumnEnd: 40,
      layout: "horizontal",
      noInfo: true,
    },
    {
      image: W2169,
      title: "",
      text: [],
      gridRowStart: 2,
      gridColumnStart: 46,
      gridRowEnd: 4,
      gridColumnEnd: 52,
      layout: "vertical",
      date: "Toronto 2019",
    },
    {
      image: W2555,
      title: "A Hummingbird’s Dream",
      text: [],
      gridRowStart: 2,
      gridColumnStart: 53,
      gridRowEnd: 4,
      gridColumnEnd: 64,
      layout: "horizontal",
      date: "Toronto 2019",
      contest: true,
    },
    {
      image: W0789,
      title: "",
      text: [],
      gridRowStart: 2,
      gridColumnStart: 65,
      gridRowEnd: 4,
      gridColumnEnd: 73,
      layout: "horizontal",
      date: "Toronto 2019",
    },
    {
      image: W0241,
      title: "",
      text: [],
      gridRowStart: 4,
      gridColumnStart: 46,
      gridRowEnd: 5,
      gridColumnEnd: 54,
      layout: "horizontal",
      date: "Toronto 2019",
    },
    {
      image: W0563,
      title: "",
      text: [],
      gridRowStart: 4,
      gridColumnStart: 55,
      gridRowEnd: 5,
      gridColumnEnd: 64,
      layout: "horizontal",
      date: "Toronto 2019",
    },
    {
      image: W0900,
      title: "",
      text: [],
      gridRowStart: 4,
      gridColumnStart: 65,
      gridRowEnd: 5,
      gridColumnEnd: 74,
      layout: "horizontal",
      date: "Toronto 2019",
    },
  ],
};
