import React, { useState, useEffect, useRef } from "react";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import "react-lazy-load-image-component/src/effects/blur.css";

const AutoSlides = ({ slides, arrIndex }) => {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);
  const delay = 3000;

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === slides.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
    return () => {
      resetTimeout();
    };
  });

  return (
    <div style={{ overflow: "hidden" }}>
      <div
        className={`slideshowSlider slideshowSlider-${arrIndex}`}
        style={{
          transform: `translate3d(${-index * 100}%, 0, 0)`,
          display: "flex",
        }}
      >
        {slides.map((img, i) => (
          <img
            key={i}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              padding: 1,
            }}
            src={img}
            alt={i}
          />
        ))}
      </div>
    </div>
  );
};
export default AutoSlides;
