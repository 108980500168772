import styled from "styled-components";
import React from "react";

const Container = styled.div`
  color: #737e26;
  display: flex;
  flex-direction: column;
  width: 100%;
  font: 1vw Kumbh Sans;
  padding: 8%;
`;

const Matthew = () => (
  <Container>
    <div>
      <p>Consider the lillies of the field, how they grow:</p>
      <p>they neither toil nor spin. &ensp; Yet I tell you, even Solomon</p>
      <p>in all his glory was not arrayed like one of these</p>
    </div>
    <div
      style={{
        marginTop: "2%",
        fontWeight: 600,
        alignSelf: "flex-end",
      }}
    >
      <p>Matthew 6: 28-29</p>
    </div>
  </Container>
);

export default Matthew;
