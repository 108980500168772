import React, { useContext } from "react";
import { ImagesContext } from "../../contexts/imagesContext";
import TransitionPage from "../../components/pageTransition";
import CloseIcon from "../../images/SVG/HF_Close_Icon.svg";
import Form from "../../components/Form/form";
import AW2 from "../../images/AW/2.jpg";
import GE1 from "../../images/GE/WEB-0995-249kb.jpg";
import {
  BG,
  Cross,
  DashedBox,
  Hover,
  ImagelessText,
  ImagelessWrapper,
  InfoWrapper,
  PageHeader,
  PSContainer,
  PSImage,
  PSWrapper,
  SpanBtn,
  SubTitle,
} from "./styles";
// import Modal from "../../components/Modal";

const PhotoShoppe = () => {
  const { data } = useContext(ImagesContext);

  return (
    <TransitionPage>
      <BG>
        <div style={{ maxWidth: 1366, margin: "auto" }}>
          <PageHeader>PhotoShoppe</PageHeader>
          <InfoWrapper>
            <PSImage src={GE1} alt="people at an exhibition" />
            <div>
              <p>
                If you would like to have a print of any of the photographs,
                please scroll down to "My Gallery" below.
              </p>
              <p>
                Most images are available in signed, small editions in 11 x 17
                and 17 x 22 inch formats. If you have a special size or
                application in mind, please let me know.
              </p>
            </div>
            <PSImage
              src={AW2}
              style={{ display: "flex", alignSelf: "flex-start" }}
              alt="girl selling postcards"
            />
          </InfoWrapper>

          <SubTitle>My Gallery</SubTitle>
          <PSContainer>
            <PSWrapper>
              {data.imagesPS.length > 0 ? (
                data.imagesPS.map((el, i) => (
                  <Hover onClick={() => data.addToPS(el.id, el.image)} key={i}>
                    <Cross src={CloseIcon} alt="close" />
                    <img
                      src={el.image}
                      alt={el.image}
                      style={{ height: "100%", maxHeight: 140 }}
                    />
                  </Hover>
                ))
              ) : (
                <ImagelessWrapper>
                  <ImagelessText>
                    While browsing the photographs, click on{" "}
                    <SpanBtn>Add to My PhotoShoppe</SpanBtn> at the lower right
                    of the image title box, and your selected image(s) will
                    automatically be loaded here, ready to be sent to me along
                    with any message.
                  </ImagelessText>
                  <DashedBox>
                    <p style={{ margin: "8% auto", fontSize: "1rem" }}>
                      No photos selected yet
                    </p>
                  </DashedBox>
                </ImagelessWrapper>
              )}
            </PSWrapper>
            <div style={{ marginTop: "8%" }}>
              <Form
                data={data}
                images={true}
                location={"PhotoShoppe"}
                message
                light
              />
            </div>
          </PSContainer>
        </div>
      </BG>
    </TransitionPage>
  );
};

export default PhotoShoppe;
