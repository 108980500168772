import styled from "styled-components";
import { device } from "../../generalStyles";

export const Title = styled.h2`
  font: 2rem Hidden Focus;
  margin: 2% 0;
  color: white;
  @media ${device.tablet} {
    font-size: 3rem;
  }
`;
