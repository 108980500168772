import React, { useState, useContext } from "react";
import { ImagesContext } from "../../contexts/imagesContext";
import Form from "../../components/Form/form";
import TransitionPage from "../../components/pageTransition";
import { BG, P, Subheading, Title, Wrapper } from "./styles";
import Modal from "../../components/Modal";
import ModalImage from "../../components/Images/modalImage";

const Ballot = () => {
  const { data } = useContext(ImagesContext);

  const contestData = [
    data.JUSTCOLOR,
    data.CARAVAN,
    data.PARIS,
    data.VENICE,
    data.LIGHTSCAPES,
    data.ABSTRACT,
  ];

  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState({});

  const openModal = (ballot) => {
    setSelected(ballot);
    setShowModal((prev) => !prev);
  };

  return (
    <TransitionPage>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        style={{ height: selected.layout === "vertical" ? "80%" : "auto" }}
      >
        <ModalImage
          id={selected.id}
          image={selected.image}
          title={selected.title}
          date={selected.date}
          noInfo={selected.noInfo}
          layout={selected.layout}
        />
      </Modal>
      <BG>
        <Title style={{ textAlign: "center" }}>Ballot</Title>
        <Wrapper>
          <Subheading>First Prize</Subheading>
          <P style={{ textTransform: "none" }}>
            A free 17" x 22" signed and numbered print of any image on the
            Hidden Focus website
          </P>
          <br />
          <br />
          <br />
          <br />
          <br />
          <P
            style={{
              textTransform: "none",
              fontSize: "1rem",
              marginLeft: "20%",
            }}
          >
            Click image to enlarge
          </P>
        </Wrapper>
        <div style={{ marginBottom: "16%" }}>
          <Form
            ballot={contestData}
            location={"Ballot"}
            openModal={openModal}
            light
          />
        </div>
      </BG>
    </TransitionPage>
  );
};

export default Ballot;
