import styled from "styled-components";
import React from "react";

const Container = styled.div`
  color: #737e26;
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 1.5;
  font: 1vw Kumbh Sans;
  padding: 12%;
`;

const William = () => (
  <Container>
    <div>
      <p>To see a World in a Grain of Sand</p>
      <p>And a Heaven in a Wild Flower</p>
      <p>Hold Infinity in the palm of your hand</p>
      <p>And Eternity in an hour</p>
    </div>
    <div style={{ margin: "2%", fontWeight: 600 }}>
      <p>William Blake</p>
    </div>
  </Container>
);

export default William;
