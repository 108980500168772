import { Dot } from "./styles";

const Dots = ({ activeIndex, onclick, images }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", margin: 8 }}>
      {images.map((slide, index) => (
        <Dot
          key={index}
          style={{
            backgroundColor: `${activeIndex === index ? "#b3c53f" : ""}`,
          }}
          onClick={() => onclick(index)}
        ></Dot>
      ))}
    </div>
  );
};

export default Dots;
