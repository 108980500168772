import React from "react";
import useAudio from "./hooks/useAudio";
import AudioOn from "../images/SVG/HF_Audio_On.svg";
import AudioOff from "../images/SVG/HF_Audio_Off.svg";

const Audio = ({ audio, addedStyle }) => {
  const [playing, toggle] = useAudio(audio);
  return (
    <div onClick={toggle} style={{ margin: 4 }}>
      {playing ? (
        <img width="40px" style={addedStyle} src={AudioOn} alt="Audio_Icon" />
      ) : (
        <img width="40px" style={addedStyle} src={AudioOff} alt="Audio_Icon" />
      )}
    </div>
  );
};

export default Audio;
