import W0001 from "../images/GE/WEB-0001-251kb.jpg";
import W7897 from "../images/GE/WEB-2N7A7897-255kb.jpg";
import W8110 from "../images/GE/WEB-2N7A8110-282kb.jpg";
import W8251 from "../images/GE/WEB-2N7A8251-250kb.jpg";
import W0241 from "../images/GE/WEB-0241-281kb.jpg";
import W0291 from "../images/GE/WEB-0291-251kb.jpg";
import W0403 from "../images/GE/WEB-0403-250ob.jpg";
import W0563 from "../images/GE/WEB-0563-247kb.jpg";
import W0769 from "../images/GE/WEB-0769-255.jpg";
import W0789 from "../images/GE/WEB-0789-254kb.jpg";
import W0831 from "../images/GE/WEB-0831-252kb.jpg";
import W0900 from "../images/GE/WEB-0900-247kb.jpg";
import W0921 from "../images/GE/WEB-0921-258kb.jpg";
import W0995 from "../images/GE/WEB-0995-249kb.jpg";
import W1384 from "../images/GE/WEB-1384-312kb.jpg";
import W1669 from "../images/GE/WEB-1669-254kb.jpg";
import W2169 from "../images/GE/WEB-2169-258kb.jpg";
import W2421 from "../images/GE/WEB-2421-254kb.jpg";
import W2555 from "../images/GE/WEB-2555-263kb.jpg";
import W2613 from "../images/GE/WEB-2613-257kb.jpg";
import W8175 from "../images/GE/WEB-8175-256kb.jpg";
import W8782 from "../images/GE/WEB-8782-259kb.jpg";
import Michael from "../images/GE/Michael_Berkovski.jpg";

export const ENESCU = {
  title: "george enescu's secret garden",
  images: [
    {
      image: W0001,
      title: "",
      text: [],
      gridRowStart: 4,
      gridColumnStart: 8,
      gridRowEnd: 6,
      gridColumnEnd: 13,
      layout: "horizontal",
    },
    {
      image: W8782,
      title: "The World in Harmony",
      text: [],
      gridRowStart: 1,
      gridColumnStart: 14,
      gridRowEnd: 4,
      gridColumnEnd: 20,
      layout: "vertical",
    },
    {
      image: Michael,
      title: "",
      text: [],
      gridRowStart: 4,
      gridColumnStart: 22,
      gridRowEnd: 6,
      gridColumnEnd: 30,
      layout: "horizontal",
    },

    {
      image: W0995,
      title: true,
      text: ["Concert intermission at the Glenn Gould Studio CBC."],
      gridRowStart: 1,
      gridColumnStart: 30,
      gridRowEnd: 4,
      gridColumnEnd: 34,
      layout: "vertical",
    },
    {
      image: W0921,
      title: true,
      text: ["This little girl has been inspired to make her own exhibit!"],
      gridRowStart: 3,
      gridColumnStart: 35,
      gridRowEnd: 5,
      gridColumnEnd: 40,
      layout: "horizontal",
    },
    {
      image: W2169,
      title: "",
      text: [],
      gridRowStart: 3,
      gridColumnStart: 46,
      gridRowEnd: 4,
      gridColumnEnd: 48,
      layout: "vertical",
      date: "Toronto 2019",
      noInfo: true,
    },
    {
      image: W0900,
      title: "The Old Fiddler",
      text: [],
      gridRowStart: 2,
      gridColumnStart: 48,
      gridRowEnd: 4,
      gridColumnEnd: 55,
      layout: "horizontal",
      date: "Toronto 2019",
      contest: true,
    },
    {
      image: W0789,
      title: "",
      text: [],
      gridRowStart: 3,
      gridColumnStart: 55,
      gridRowEnd: 4,
      gridColumnEnd: 59,
      layout: "horizontal",
      date: "Toronto 2019",
      noInfo: true,
    },
    {
      image: W0563,
      title: "",
      text: [],
      gridRowStart: 4,
      gridColumnStart: 46,
      gridRowEnd: 5,
      gridColumnEnd: 49,
      layout: "horizontal",
      date: "Toronto 2019",
      noInfo: true,
    },
    {
      image: W0241,
      title: "",
      text: [],
      gridRowStart: 4,
      gridColumnStart: 50,
      gridRowEnd: 5,
      gridColumnEnd: 54,
      layout: "horizontal",
      date: "Toronto 2019",
      noInfo: true,
    },
    {
      image: W2555,
      title: "",
      text: [],
      gridRowStart: 4,
      gridColumnStart: 55,
      gridRowEnd: 5,
      gridColumnEnd: 58,
      layout: "horizontal",
      date: "Toronto 2019",
      noInfo: true,
    },
    {
      image: W2421,
      title: "",
      text: [],
      gridRowStart: 3,
      gridColumnStart: 64,
      gridRowEnd: 4,
      gridColumnEnd: 67,
      layout: "horizontal",
      date: "Toronto 2019",
      noInfo: true,
    },
    {
      image: W1384,
      title: "Impressions d’enfance",
      text: [],
      gridRowStart: 2,
      gridColumnStart: 67,
      gridRowEnd: 4,
      gridColumnEnd: 73,
      layout: "horizontal",
      date: "Toronto 2019",
      contest: true,
    },
    {
      image: W0403,
      title: "",
      text: [],
      gridRowStart: 3,
      gridColumnStart: 73,
      gridRowEnd: 4,
      gridColumnEnd: 76,
      layout: "horizontal",
      date: "Toronto 2019",
      noInfo: true,
    },
    {
      image: W0291,
      title: "",
      text: [],
      gridRowStart: 4,
      gridColumnStart: 64,
      gridRowEnd: 5,
      gridColumnEnd: 67,
      layout: "horizontal",
      date: "Toronto 2019",
      noInfo: true,
    },
    {
      image: W8251,
      title: "",
      text: [],
      gridRowStart: 4,
      gridColumnStart: 69,
      gridRowEnd: 5,
      gridColumnEnd: 72,
      layout: "horizontal",
      date: "Toronto 2019",
      noInfo: true,
    },
    {
      image: W0769,
      title: "",
      text: [],
      gridRowStart: 4,
      gridColumnStart: 72,
      gridRowEnd: 5,
      gridColumnEnd: 75,
      layout: "horizontal",
      date: "Toronto 2019",
      noInfo: true,
    },
    {
      image: W7897,
      title: "",
      text: [],
      gridRowStart: 3,
      gridColumnStart: 86,
      gridRowEnd: 4,
      gridColumnEnd: 89,
      layout: "vertical",
      date: "Toronto 2019",
      noInfo: true,
    },
    {
      image: W2613,
      title: "The Heart of an Artist",
      text: [],
      gridRowStart: 2,
      gridColumnStart: 89,
      gridRowEnd: 4,
      gridColumnEnd: 96,
      layout: "horizontal",
      date: "Toronto 2019",
      contest: true,
    },
    {
      image: W8175,
      title: "",
      text: [],
      gridRowStart: 3,
      gridColumnStart: 96,
      gridRowEnd: 4,
      gridColumnEnd: 98,
      layout: "vertical",
      date: "Toronto 2019",
      noInfo: true,
    },
    {
      image: W0831,
      title: "",
      text: [],
      gridRowStart: 4,
      gridColumnStart: 86,
      gridRowEnd: 5,
      gridColumnEnd: 89,
      layout: "horizontal",
      date: "Toronto 2019",
      noInfo: true,
    },
    {
      image: W1669,
      title: "",
      text: [],
      gridRowStart: 4,
      gridColumnStart: 91,
      gridRowEnd: 5,
      gridColumnEnd: 94,
      layout: "horizontal",
      date: "Toronto 2019",
      noInfo: true,
    },
    {
      image: W8110,
      title: "",
      text: [],
      gridRowStart: 4,
      gridColumnStart: 96,
      gridRowEnd: 5,
      gridColumnEnd: 99,
      layout: "horizontal",
      date: "Toronto 2019",
      noInfo: true,
    },
  ],
};
