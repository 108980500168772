import { Link } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../../generalStyles";
import HorizontalScroll from "../../../components/HorizontalScroll/horizontalScroll";
import Audio from "../../../audio/Ray Hanson - Lightscapes(V1).mp3";
import AudioOn from "../../../images/SVG/HF_Audio_On.svg";
import AudioOff from "../../../images/SVG/HF_Audio_Off.svg";
import useAudio from "../../../components/hooks/useAudio";
import React from "react";

import { useContext } from "react";
import { ImagesContext } from "../../../contexts/imagesContext";

const TextWithHref = styled.p`
  grid-area: 2 / 1 / 3 / 12;
  font: 14px Kumbh Sans;
  white-space: normal;
  place-items: center;
  align-self: center;
  color: #331c65;
  @media ${device.tablet} {
    font: 1.2vw Kumbh Sans;
  }
`;

const Text = styled.p`
  display: grid;
  place-items: center;
  white-space: normal;
  font: 14px Kumbh Sans;
  color: #331c65;
  @media ${device.tablet} {
    font: 1.2vw Kumbh Sans;
  }
`;

const Lightscapes = () => {
  const { data } = useContext(ImagesContext);
  const [playing, toggle] = useAudio(Audio);

  return (
    <HorizontalScroll
      width={"700vw"}
      columnAmount={233}
      title={data.LIGHTSCAPES.title}
      photos={data.LIGHTSCAPES.images}
    >
      <TextWithHref>
        I began taking "portraits" of flowers in the Spring of 2019.  By summer,
        they had grown into a body of work called{" "}
        <Link to="look-at-the-flowers">"Look at the Flowers..."</Link> and, in
        the fall, became the subject of four local exhibits.  As winter
        unfolded, I began to wonder what would show up through the lens come
        Spring.  Would the 2020 blossoms look the same?
      </TextWithHref>
      <br />
      <br />
      <Text style={{ gridArea: "3 / 1 / 4 / 7" }}>
        "Homage to Monet" appeared on the first photo-shoot of Spring, and
        radiated the answer: the possibilities, even within the confines of my
        small garden, are infinite.
      </Text>
      <br />
      <br />
      <Text style={{ gridArea: "4 / 1 / 5 / 7" }}>
        The visual feel of this image and of others soon to follow is decidedly
        watercolor.  Since the distinctive medium for all these images is light,
        I've settled on a new name to describe them: "Lightscapes."
      </Text>
      <div onClick={toggle} style={{ margin: 8, gridArea: "5 / 13 / 6 / 14" }}>
        {playing ? (
          <img width="40px" src={AudioOn} alt="Audio_Icon" />
        ) : (
          <img width="40px" src={AudioOff} alt="Audio_Icon" />
        )}
      </div>
    </HorizontalScroll>
  );
};

export default Lightscapes;
