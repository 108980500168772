import xmas from "../../images/WHYNOW/xmas.png";
import highschool from "../../images/WHYNOW/highschool.jpg";
import olp from "../../images/WHYNOW/olp.jpg";
import passport from "../../images/WHYNOW/passport.jpg";
import venice from "../../images/WHYNOW/venice.jpg";
import singing from "../../images/WHYNOW/butterfly.jpg";
import bookmark from "../../images/WHYNOW/bookmark.jpg";
import I1 from "../../images/MEMENTOS/1.jpg";
import MS2 from "../../images/MEMENTOS/WEB-Dinner-02.jpg";
import I3 from "../../images/MEMENTOS/3.jpg";
import MS4 from "../../images/MEMENTOS/WEB-Dinner-04.jpg";
import I5 from "../../images/MEMENTOS/5.jpg";
import MS6 from "../../images/MEMENTOS/WEB-Dinner-06.jpg";
import MS7 from "../../images/MEMENTOS/WEB-Dinner--07.jpg";
import MS8 from "../../images/MEMENTOS/WEB-Dinner-08.jpg";
import I10 from "../../images/MEMENTOS/10.jpg";
import MS11 from "../../images/MEMENTOS/WEB-Dinner-11.jpg";
import MS12 from "../../images/MEMENTOS/WEB-Dinner-12.jpg";
import MS13 from "../../images/MEMENTOS/WEB-Dinner-13.jpg";
import MS14 from "../../images/MEMENTOS/WEB-Dinner-14.jpg";
import MS15 from "../../images/MEMENTOS/WEB-Dinner-15.jpg";

import I2notSlider from "../../images/MEMENTOS/2notSlider.jpg";
import I3notSlider from "../../images/MEMENTOS/3notSlider.jpg";
import I4notSlider from "../../images/MEMENTOS/4notSlider.jpg";
import I7notSlider from "../../images/MEMENTOS/1notSlider.jpg";
import I5notSlider from "../../images/MEMENTOS/5notSlider.jpg";

import NRP1 from "../../images/MEMENTOS/NRP-01.jpg";
import NRP2 from "../../images/MEMENTOS/NRP-02.jpg";
import NRP3 from "../../images/MEMENTOS/NRP-03.jpg";
import NRP4 from "../../images/MEMENTOS/NRP-04.jpg";
import NRP5 from "../../images/MEMENTOS/NRP-05.jpg";
import NRP6 from "../../images/MEMENTOS/NRP-06.jpg";
import NRP7 from "../../images/MEMENTOS/NRP-07.jpg";

export const WNData = [
  {
    image: xmas,
    text: "My 1st Christmas",
  },
  {
    image: highschool,
    text: "High School Yearbook",
  },
  {
    image: olp,
    text: "On OLP Seminary grounds, Warwick Neck RI",
  },
  {
    image: passport,
    text: "Passport from the Wall Street years",
  },
  {
    image: venice,
    text: "On Isola di San Giorgio, Venice",
  },
  {
    image: singing,
    text: "Performing in “Butterfly Up High”",
  },
  {
    image: bookmark,
    text: "5th Grade Bookmark",
  },
];
export const MementosData = [
  {
    id: 0,
    image: I1,
    text: "",
    hasSlider: true,
    layout: "horizontal",
  },
  {
    id: "1false",
    image: I7notSlider,
    text: "",
    hasSlider: true,
    layout: "vertical",
  },
  {
    id: 5,
    image: MS6,
    text: "",
    hasSlider: true,
  },
  {
    id: 2,
    image: I3,
    text: "",
    hasSlider: true,
  },
  {
    id: "4false",
    image: I4notSlider,
    text: "",
    hasSlider: false,
    layout: "horizontal",
  },
  {
    id: "5false",
    image: I3notSlider,
    text: "",
    hasSlider: false,
    layout: "vertical",
  },
  {
    id: 6,
    image: I2notSlider,
    text: "",
    hasSlider: false,
    layout: "horizontal",
  },
  {
    id: 8,
    image: I10,
    text: "",
    hasSlider: true,
  },
  {
    id: "9false",
    image: I5notSlider,
    text: "",
    hasSlider: false,
    layout: "horizontal",
  },
];

export const NonRandomSlidesData = [
  {
    id: 0,
    hasMagnifier: true,
    image: I7notSlider,
  },
  {
    id: 1,
    hasMagnifier: true,
    image: NRP1,
  },
  {
    id: 2,
    hasMagnifier: true,
    image: NRP2,
  },
  {
    id: 3,
    hasMagnifier: true,
    image: NRP3,
  },
  {
    id: 4,
    hasMagnifier: true,
    image: NRP4,
  },
  {
    id: 5,
    hasMagnifier: true,
    image: NRP5,
  },
  {
    id: 6,
    hasMagnifier: true,
    image: NRP6,
  },
  {
    id: 7,
    hasMagnifier: true,
    image: NRP7,
  },
];

export const MementosSlidesData = [
  {
    id: 0,
    hasMagnifier: true,
    image: I1,
  },
  {
    id: 1,
    hasMagnifier: true,
    image: MS2,
  },
  {
    id: 2,
    hasMagnifier: true,
    image: I3,
  },
  {
    id: 3,
    hasMagnifier: true,
    image: MS4,
  },
  {
    id: 4,
    hasMagnifier: true,
    image: I5,
  },
  {
    id: 5,
    hasMagnifier: true,
    image: MS6,
  },
  {
    id: 6,
    hasMagnifier: true,
    image: MS7,
  },
  {
    id: 7,
    hasMagnifier: true,
    image: MS8,
  },
  {
    id: 9,
    hasMagnifier: true,
    image: I10,
  },
  {
    id: 9,
    hasMagnifier: true,
    image: MS11,
  },
  {
    id: 10,
    hasMagnifier: true,
    image: MS12,
  },
  {
    id: 11,
    hasMagnifier: true,
    image: MS13,
  },
  {
    id: 12,
    hasMagnifier: true,
    image: MS14,
  },
  {
    id: 13,
    hasMagnifier: true,
    image: MS15,
  },
];
