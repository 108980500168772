import HorizontalScroll from "../../../components/HorizontalScroll/horizontalScroll";
import { dataFotm } from "./dataFotm";
import React from "react";

const FDTL = () => {
  return (
    <HorizontalScroll
      width={"420vw"}
      columnAmount={140}
      title={dataFotm.title}
      photos={dataFotm.images}
    ></HorizontalScroll>
  );
};

export default FDTL;
