import I1 from "../images/CARAVAN/1.jpg";

export const CARAVAN = {
  title: "caravan",
  images: [
    {
      image: I1,
      title: "",
      text: [],
      // gridRowStart: 1,
      // gridColumnStart: 50,
      // gridRowEnd: 4,
      // gridColumnEnd: 58,
      layout: "horizontal",
      noInfo: true,
    },
  ],
};
