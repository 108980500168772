import React, { useContext } from "react";
import { Link } from "react-router-dom";
import TransitionPage from "../../components/pageTransition";
import { ImagesContext } from "../../contexts/imagesContext";
import {
  BG,
  BlockWrapper,
  Button,
  Grid,
  PWImage,
  PWShow,
  PWSubtitle,
  PWTitle,
  SubTitle,
  Title,
} from "./styles";

const WinnerImage = ({ subtitle, image, title, style }) => {
  return (
    <div style={style}>
      {subtitle && <PWSubtitle>{subtitle}</PWSubtitle>}
      <PWImage src={image} alt={image} />
      <PWTitle>{title}</PWTitle>
    </div>
  );
};

const PastWinners = () => {
  const { data } = useContext(ImagesContext);

  const pastWinnersData = [data.LOOKATTHEFLOWERS1, data.ENESCU];

  return (
    <TransitionPage>
      <BG>
        <BlockWrapper style={{ marginBottom: "8%", flexDirection: "column" }}>
          <Title>Name the Picture Contest</Title>
          <SubTitle style={{ font: "500 3vw Kumbh Sans" }}>
            Past Winners
          </SubTitle>
        </BlockWrapper>

        <PWShow>Enescu's Secret Garden</PWShow>

        <Grid style={{ gridTemplateRows: "repeat(3,34%)" }}>
          <WinnerImage
            style={{ gridArea: "1/2/2/3", justifyContent: "center" }}
            subtitle={"Concert 1"}
            image={pastWinnersData[0].images[3].image}
            title={pastWinnersData[0].images[3].title}
          />
          <WinnerImage
            style={{ gridArea: "2/1/3/2", justifyContent: "center" }}
            subtitle={"Concert 2"}
            image={pastWinnersData[1].images[12].image}
            title={pastWinnersData[1].images[12].title}
          />
          <WinnerImage
            style={{ gridArea: "2/3/3/4", justifyContent: "center" }}
            subtitle={"Concert 3"}
            image={pastWinnersData[0].images[10].image}
            title={pastWinnersData[0].images[10].title}
          />
          <div style={{ gridArea: "3/2/4/3", justifyContent: "center" }}>
            <PWShow>Como en Casa</PWShow>
            <WinnerImage
              show={"Como en Casa"}
              image={pastWinnersData[0].images[0].image}
              title={pastWinnersData[0].images[0].title}
            />
          </div>
        </Grid>

        <div style={{ padding: "8%" }}>
          <Link to="/contest">
            <Button>back to contest</Button>
          </Link>
        </div>
      </BG>
    </TransitionPage>
  );
};

export default PastWinners;
