import styled from "styled-components";
import { device } from "../../generalStyles";

export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  font: 600 0.8rem Kumbh Sans;
  color: #6a0dad;
  text-transform: uppercase;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${device.small} {
    width: 50%;
  }
`;

export const NEMWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: auto;
`;

export const Input = styled.input`
  background: transparent;
  border: 1px solid #6a0dad;
  border-radius: 0.5rem;
  margin: 2% 0;
  height: 2rem;
  font-family: Kumbh Sans;
  color: #6a0dad;
  padding-left: 0.5rem;
  &:focus-visible {
    outline: none;
  }
`;

export const InputBallot = styled.input`
  margin: 0px;
  width: 100%;
  border: none;
  border-bottom: 1px #6a0dad solid;
  height: 2rem;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #c4c4c4;
    font: 1rem Kumbh Sans;
  }
`;

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  background: transparent;
  border: 1px solid #6a0dad;
  border-radius: 0.5rem;
  margin: 2% 1% 2% 0;
  height: 2rem;
  color: ${(props) => (props.light ? "#6a0dad" : "#f9efff")};
  padding-left: 0.5rem;
  &:focus-visible {
    outline: none;
  }
`;
export const Textarea = styled.textarea`
  background: transparent;
  border: 1px solid #6a0dad;
  border-radius: 0.5rem;
  margin: 2% 0;
  height: 3.375rem;
  color: ${(props) => (props.light ? "#6a0dad" : "#f9efff")};
  padding-left: 8px;
  font-family: Kumbh Sans;
  &:focus-visible {
    outline: none;
  }
`;
export const Button = styled.input`
  width: 40%;
  align-self: center;
  height: 2rem;
  text-transform: uppercase;
  border-radius: 0.5rem;
  background-color: #6a0dad;
  color: #f9efff;
  font: bold 0.8rem Kumbh Sans;
  border: none;
`;

export const Sent = styled.div`
  background-color: #b3c53f;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2% 2% 6%;
  margin: 2%;
  color: #431e57;
`;
