import styled from "styled-components";
import { device } from "../../generalStyles";

export const PageHeader = styled.h1`
  font: 4em Hidden Focus;
  color: #431e57;
  position: absolute;
  top: -2rem;
  display: flex;
  width: 100%;
  justify-content: center;
  @media ${device.small} {
    font-size: 5rem;
  }
`;
export const Text = styled.p`
  width: 68%;
  text-align: center;
  font: 400 1.2rem Kumbh Sans;
  color: #331c65;
  margin: 8% auto 0;
`;

export const BGImage = styled.div`
  width: 100%;
  filter: blur(8px);
  position: fixed;
  background-size: cover;
  height: 100vh;
`;

export const Box = styled.div`
  position: absolute;
  top: 12%;
  margin-top: 4%;
  background-color: #f9efff5e;
  width: 60%;
  padding: 4% 0px;
`;
