import React, { useContext } from "react";
import HorizontalScroll from "../../components/HorizontalScroll/horizontalScroll";
import { ImagesContext } from "../../contexts/imagesContext";
import { Text } from "../../generalStyles";

const Amigos = () => {
  const { data } = useContext(ImagesContext);

  return (
    <HorizontalScroll
      title={data.CUATROAMIGOS.title}
      photos={data.CUATROAMIGOS.images}
      width={"400vw"}
      columnAmount={100}
    >
      <Text style={{ gridArea: "1 / 8 / 3 / 16 ", placeItems: "center" }}>
        <i style={{ display: "contents" }}>
          Four artists, three Cuban and one Canadian
        </i>
        , have come together to share the work they have rendered in painting,
        paper and photography. The expo celebrates the inner life of our cities
        and the bountiful nature that nurtures and surrounds them. Together,
        they invite us to reflect on the broad range of experiences that we
        share in common, and that lay the foundation for a life lived in
        harmony.
      </Text>
      <Text style={{ gridArea: "2 / 38 / 5 / 48 ", placeItems: "center" }}>
        Lilia Ulla was a teenager at the time of the Revolution, and has
        experienced firsthand the remaking of Cuban society as it unfolded over
        the decades. Through her eyes, we could still catch a glimpse of
        Havana’s former splendor.
        {<br />}
        {<br />}
        Affectionately known as Dr. Lilly, she introduced Lawrence and me to
        many of her friends, including Amel, Boris and Abel. New friendships
        quickly developed, and Lilia happily and capably took charge of making
        this expo happen.
      </Text>
    </HorizontalScroll>
  );
};

export default Amigos;
