import { useState } from "react";
import Form from "../../components/Form/form";
import TransitionPage from "../../components/pageTransition";
import BG from "../../images/AW/11.jpg";
import Modal from "../../components/Modal";
import { BGImage, Box, PageHeader, Text } from "./styles";
import ModalImage from "../../components/Images/modalImage";

const Contact = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal((prev) => !prev);
  };
  return (
    <TransitionPage>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <ModalImage image={BG} title={"Phone Home"} date={"Angkor Wat 2020"} />
      </Modal>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <BGImage
          onClick={() => openModal()}
          style={{ backgroundImage: `url(${BG})` }}
        />
        <Box>
          <PageHeader>Contact</PageHeader>
          <Text>
            Your comments, questions and free associations are all most
            welcome...
          </Text>
          <Form location={"Contact"} message />
        </Box>
      </div>
    </TransitionPage>
  );
};

export default Contact;
