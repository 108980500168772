import React, { useContext } from "react";
import VerticalScroll from "../../../components/VerticalScroll/verticalScroll";
import { ImagesContext } from "../../../contexts/imagesContext";

const Latf = () => {
  const { data } = useContext(ImagesContext);

  return (
    <VerticalScroll
      photos1={data.LOOKATTHEFLOWERS1.images}
      title={data.LOOKATTHEFLOWERS1.title}
      photos2={data.LOOKATTHEFLOWERS2.images}
    />
  );
};

export default Latf;
