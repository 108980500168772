import styled from "styled-components";
import React from "react";

const Container = styled.div`
  color: #331c65;
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 1.5;
  font: 1vw Kumbh Sans;
  padding: 8%;
`;

const Goethe = () => (
  <Container>
    <div>
      <p>Here, where the roses are blooming</p>
      <p>Where vines interwine with the laurel</p>
      <p>Where the turtledove coos, and chrickets chirp</p>
    </div>
    <br />
    <div>
      <p>What manner of grave is this</p>
      <p>Lavished by all the gods</p>
      <p>With such beauty and exuberant life</p>
      <p>It is the ground where Anakreon rests</p>
    </div>
    <br />
    <div>
      <p>The beloved poet lived springtime, summer</p>
      <p>And autumn to the full</p>
      <p>Now the hillside cradles him from winter forever</p>
    </div>
    <br />
    <div>
      <p>Anakreons Grab</p>
      <br />
      <br />
      <p style={{ fontWeight: 600 }}>J. W. von Goethe</p>
    </div>
  </Container>
);

export default Goethe;
