import styled from "styled-components";
import { motion } from "framer-motion";
import { device } from "../../generalStyles";

//ContestPage.jsx

export const BG = styled.div`
  max-width: 1366px;
  padding: 8%;
  margin: auto;
`;
export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 50%);
  gap: 2%;
`;

export const Title = styled.h1`
  font: 2em Hidden Focus;
  color: #6a0dad;
  @media ${device.small} {
    font-size: 4rem;
  }
`;
export const SubTitle = styled.h2`
  font: 2rem Hidden Focus;
  color: #6a0dad;
  @media ${device.small} {
    font-size: 4rem;
  }
`;

export const BlockWrapper = styled.div`
  display: grid;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Header = styled.h3`
  font: 500 1.2rem/1.25rem Kumbh Sans;
  display: grid;
  align-items: center;
  justify-content: center;
  margin: 2% auto;
  text-transform: capitalize;
  width: 100%;
  color: #331c65;
  @media ${device.medium} {
    font: 500 2rem/1.25rem Kumbh Sans;
  }
`;
export const P = styled.p`
  font: 1rem Kumbh Sans;
  text-transform: capitalize;
  color: #331c65;
`;

export const Button = styled.button`
  margin: 8% auto;
  font: 400 1rem/1.25rem Kumbh Sans;
  border: #b3c53f 1px solid;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  text-transform: capitalize;
  background-color: transparent;
  cursor: pointer;
  color: #331c65;
  &:hover {
    background-color: #b3c53f;
  }
`;

//PastWinners.jsx

export const PWShow = styled.h5`
  font: 1.6rem/2rem Mrs Saint Delafield;
  color: #431e57;
  text-align: center;
  word-spacing: 1.6rem;
  margin-bottom: 1rem;
  @media ${device.tablet} {
    font-size: 4vw;
  }
  @media ${device.medium} {
    font-size: 4vw;
  }
`;
export const PWSubtitle = styled.h5`
  font: 400 0.8rem/2.5rem Kumbh Sans;
  color: #431e57;
  text-align: center;
  @media ${device.tablet} {
    font-size: 2vw;
  }
  @media ${device.medium} {
    font-size: 1.5vw;
  }
`;
export const PWTitle = styled.h5`
  font: 500 1rem/3.5rem Kumbh Sans;
  color: #431e57;
  font-style: italic;
  text-align: center;
  @media ${device.tablet} {
    font-size: 3vw;
  }
  @media ${device.medium} {
    font-size: 2.2vw;
  }
`;

export const PWImage = styled(motion.img)`
  width: 100%;
  object-fit: contrain;
  // @media ${device.small} {
  //   width: 32rem;
  // }
`;

//ballot.jsx

export const Wrapper = styled.div`
  width: 60%;
  margin: 4%;
  @media ${device.small} {
    width: 32%;
  }
`;

export const Subheading = styled.h2`
  font: 1.6rem/3rem Kumbh Sans;
  color: #6a0dad;
  @media ${device.small} {
    font: 2.6rem/4rem Kumbh Sans;
  }
`;
