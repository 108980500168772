import React from "react";
import Logo from "../../images/SVG/HF_Official_Logo.svg";
import styled from "styled-components";
import TransitionPage from "../pageTransition";
import { Title } from "./styles";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  background-color: #2f123d;
  justify-content: space-around;
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ComingSoon = ({ title, image }) => {
  return (
    <TransitionPage>
      <Wrapper>
        <InfoWrapper>
          <img src={Logo} alt="logo" />
          <h1 style={{ color: "#b3c53f", font: "2vw Kumbh Sans" }}>
            Coming Soon!
          </h1>
        </InfoWrapper>
        <div style={{ width: "40%" }}>
          <img
            style={{ width: `${image.layout === "vertical" ? "60%" : "80%"}` }}
            src={image.image}
            alt={image}
          />
          <Title>{title}</Title>
        </div>
      </Wrapper>
    </TransitionPage>
  );
};

export default ComingSoon;
