import React, { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import useWindowSize from "./components/hooks/useWindowSize";
import { AnimatePresence } from "framer-motion";
import Menu from "./components/Menu/menu";
import Test from "./pages/Test.jsx";
import Home from "./pages/Home/Home.jsx";
import Contest from "./pages/Contest/ContestPage.jsx";
import PastWinners from "./pages/Contest/PastWinners.jsx";
import WhyNow from "./pages/WhyNow/WhyNow";
import Gallery from "./pages/Gallery/Gallery.jsx";
import Influences from "./pages/Influences/Influences.jsx";
import PhotoShoppe from "./pages/PhotoShoppe/PhotoShoppe.jsx";
import Contact from "./pages/Contact/Contact.jsx";
//DESTINATIONS
import Angkor from "./pages/destinations/Angkor";
import CapeCod from "./pages/destinations/CapeCod";
import Caravan from "./pages/destinations/Caravan";
import Havana from "./pages/destinations/Havana";
import NewYork from "./pages/destinations/NewYork/NewYork";
import Paris from "./pages/destinations/Paris";
import Singapur from "./pages/destinations/Singapur";
import Toronto from "./pages/destinations/Toronto";
import Venice from "./pages/destinations/Venice";
//SHOWS
import Amigos from "./pages/shows/Amigos.jsx";
import Enescu from "./pages/shows/Enescu/Enescu.jsx";
import Mexico from "./pages/shows/Mexico/Mexico.jsx";
//THEMES
import Abstracts from "./pages/themes/Abstracts/Abstracts.jsx";
import Explorations from "./pages/themes/Explorations/Explorations.jsx";
import FoundSubjects from "./pages/themes/FS/FoundSubjects.jsx";
import JustColor from "./pages/themes/JC/JustColor.jsx";
import Latf from "./pages/themes/LATF/Latf.jsx";
import Lightscapes from "./pages/themes/Lightscapes/Lightscapes.jsx";
import Portraits from "./pages/themes/Portraits/Portraits.jsx";
import Director from "./pages/themes/Director";
import FDTL from "./pages/themes/Fdtl.jsx";
import FOTM from "./pages/themes/FOTM/Fotm.jsx";
import FLUA from "./pages/themes/FLUA/Flua";
import WOTW from "./pages/themes/WOTW/Wotw";
import CulturalDisconnect from "./pages/themes/CD/CulturalDisconnect";
import FSS from "./pages/themes/FSS/Fss";
import FTP from "./pages/themes/FTP/Ftp";
import AF from "./pages/themes/AF/AnimalFarm";
import ImagesContextProvider from "./contexts/imagesContext";
import Room from "./pages/Gallery/room";
import ProtectedRoute from "./components/protectedRoutes";
import Ballot from "./pages/Contest/ballot";
import Logo from "./images/SVG/HF_Official_Logo.svg";
import { HeaderHome } from "./pages/Home/styles";

const ReactRouterSetup = () => {
  const location = useLocation();
  const [disclose, setDisclose] = useState(false);
  const size = useWindowSize();

  return (
    <div>
      {size.width <= 768 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#2f123d",
          }}
        >
          <HeaderHome style={{ color: "#8b33b9" }}>hidden focus</HeaderHome>
          <img width={"15px"} src={Logo} alt="logo" />
          <p
            style={{
              color: "#b3c53f",
              font: "1rem Kumbh Sans",
              margin: "2% auto",
            }}
          >
            Please, check the website on a bigger screen for a better experience{" "}
          </p>
        </div>
      ) : (
        <div>
          {disclose && <Menu />}

          <ImagesContextProvider>
            <AnimatePresence exitBeforeEnter>
              <Routes location={location} key={location.key}>
                <Route path="/" element={<Home setDisclose={setDisclose} />} />
                <Route path="test" element={<Test />} />
                <Route element={<ProtectedRoute disclose={disclose} />}>
                  <Route path="whynow" element={<WhyNow />} />
                  <Route path="influences" element={<Influences />} />
                  <Route path="contest" element={<Contest />} />
                  <Route path="ballot" element={<Ballot />} />
                  <Route path="past-winners" element={<PastWinners />} />
                  <Route path="photoshoppe" element={<PhotoShoppe />} />
                  <Route path="themes" element={<Room floor={"themes"} />} />
                  <Route
                    path="destinations"
                    element={<Room floor={"destinations"} />}
                  />
                  <Route path="shows" element={<Room floor={"shows"} />} />
                  <Route path="gallery" element={<Gallery />} />
                  <Route path="contact" element={<Contact />} />
                  {/* destinations */}
                  <Route path="angkor-wat" element={<Angkor />} />
                  <Route path="cape-cod" element={<CapeCod />} />
                  <Route path="caravan" element={<Caravan />} />
                  <Route path="havana" element={<Havana />} />
                  <Route path="new-york" element={<NewYork />} />
                  <Route path="paris" element={<Paris />} />
                  <Route path="singapur" element={<Singapur />} />
                  <Route path="toronto" element={<Toronto />} />
                  <Route path="venice" element={<Venice />} />
                  {/* shows */}
                  <Route path="george-enescu" element={<Enescu />} />
                  <Route path="como-en-casa" element={<Mexico />} />
                  <Route path="cuatro-amigos" element={<Amigos />} />
                  {/* theme */}
                  <Route path="abstracts" element={<Abstracts />} />
                  <Route path="explorations" element={<Explorations />} />
                  <Route path="found-subjects" element={<FoundSubjects />} />
                  <Route path="just-color" element={<JustColor />} />
                  <Route path="look-at-the-flowers" element={<Latf />} />
                  <Route path="lightscapes" element={<Lightscapes />} />
                  <Route path="portraits" element={<Portraits />} />
                  <Route path="director-cut" element={<Director />} />
                  <Route path="from-darkness-to-light" element={<FDTL />} />
                  <Route
                    path="flashbacks-from-the-metaverse"
                    element={<FOTM />}
                  />
                  <Route
                    path="familiar-landmarks-unfamiliar-angles"
                    element={<FLUA />}
                  />
                  <Route path="/window-of-the-world" element={<WOTW />} />
                  <Route
                    path="cultural-disconnect"
                    element={<CulturalDisconnect />}
                  />
                  <Route path="favorite-street-scenes" element={<FSS />} />
                  <Route path="from-the-pavement" element={<FTP />} />
                  <Route path="animal-farm" element={<AF />} />
                </Route>
              </Routes>
            </AnimatePresence>
          </ImagesContextProvider>
        </div>
      )}
    </div>
  );
};

export default ReactRouterSetup;
