import ComingSoon from "../../../components/ComingSoon/ComingSoon";
import { DIRECTORCUT } from "../../../NewData/dataDirector";
import React from "react";

const AF = () => (
  <ComingSoon
    title={DIRECTORCUT.images[7].title}
    image={DIRECTORCUT.images[7]}
  />
);

export default AF;
