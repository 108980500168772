import { Link } from "react-router-dom";
import styled from "styled-components";
import Audio from "../../../../components/audio";
import Sound from "../../../../audio/Miserere - Enrico Garzilli 96kbs.mp3";
import React from "react";

const Container = styled.div`
  color: #edf1d0;
  display: flex;
  flex-direction: column;
  width: 60%;
  font: 1vw Kumbh Sans;
  margin: auto;
`;

const ROTH = () => (
  <Container>
    <div style={{ padding: " 0 4%" }}>
      <p>I know even though I can't see it</p>
      <p>somewhere waiting for me</p>
      <p>there will always be love</p>
    </div>
    <div style={{ alignSelf: "flex-end", padding: "4%" }}>
      <i>
        <p>Miserere, miserere nobis</p>
        <p>Miserere nobis, Domine</p>
        <p>Agnus Dei, dona nobis pacem</p>
      </i>
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        marginRight: "-4%",
        marginTop: -64,
      }}
    >
      <Audio audio={Sound} />
    </div>
    <div
      style={{
        alignSelf: "center",
        padding: "2% 0% 4%",
      }}
    >
      <p style={{ color: "#9dad34" }}>
        From <i>Rage of the Heart</i>
      </p>

      <p style={{ color: "#9dad34" }}>
        Book and music by{" "}
        <strong>
          <Link style={{ color: "#9dad34" }} to="/influences">
            Enrico Garzilli
          </Link>
        </strong>
      </p>
    </div>
  </Container>
);

export default ROTH;
