import { useContext } from "react";
import { ImagesContext } from "../../../contexts/imagesContext";
import VerticalScroll from "../../../components/VerticalScroll/verticalScroll";
import React from "react";

const NewYork = () => {
  const { data } = useContext(ImagesContext);

  return (
    <VerticalScroll photos1={data.NEWYORK.images} title={data.NEWYORK.title} />
  );
};

export default NewYork;
