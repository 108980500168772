import ImageMagnifier from "../imageMagnifier";
import { Slide, Text } from "./styles";

const SliderContent = ({ activeIndex, images, withText, width, height }) => {
  return (
    <section>
      {images.map((slide, index) => (
        <Slide
          key={index}
          width={slide.hasMagnifier ? 0 : width}
          height={slide.hasMagnifier ? "auto" : height}
          display={index === activeIndex ? "block" : "none"}
        >
          {slide.hasMagnifier ? (
            <div
              style={{
                width: "80%",
                height: "80%",
                display: "flex",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <ImageMagnifier width={"100%"} src={slide.image} alt="test" />
            </div>
          ) : (
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              src={slide.image}
              alt=""
            />
          )}
          {withText && <Text>{slide.text}</Text>}
        </Slide>
      ))}
    </section>
  );
};

export default SliderContent;
