import styled from "styled-components";
import React from "react";

const Container = styled.div`
  color: #331c65;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  line-height: 1.5;
  font: 1vw Kumbh Sans;
  padding: 8% 4%;
`;

const Hugo = () => (
  <Container>
    <div style={{ fontStyle: "italic" }}>
      <p>Frühling, Sommer und Herbst genoss</p>
      <p style={{ marginLeft: 16 }}>der glückliche dichter</p>
      <p>Vor dem Winter hat ihm endlich </p>
      <p style={{ marginLeft: 16 }}>der Hügel geschützt.</p>
      <br />
    </div>
    <br />
    <br />
    <p>Translation &copy;Ray Hanson 2022</p>
  </Container>
);

export default Hugo;
