import React, { useContext } from "react";
import { Link } from "react-router-dom";
import TransitionPage from "../../components/pageTransition";
import { ImagesContext } from "../../contexts/imagesContext";
import {
  BG,
  BlockWrapper,
  Button,
  Grid,
  Header,
  Image,
  SubTitle,
  Title,
} from "./styles";

const Contest = () => {
  const { data } = useContext(ImagesContext);

  return (
    <TransitionPage>
      <BG>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Title>Good with Words?</Title>
          <SubTitle>Free Associations?</SubTitle>
        </div>
        <Grid>
          <BlockWrapper style={{ gridArea: "1/1/2/2", alignItems: "end" }}>
            <Link to="/ballot">
              <Image src={data.JUSTCOLOR.images[0].image} alt="just color" />
            </Link>
          </BlockWrapper>

          <BlockWrapper style={{ gridArea: "1/2/2/3" }}>
            <Link to="/ballot">
              <Image src={data.LIGHTSCAPES.images[0].image} alt="lightscapes" />
            </Link>
            <Header>give me a name!</Header>
          </BlockWrapper>
          <BlockWrapper style={{ gridArea: "1/3/2/4", alignItems: "end" }}>
            <Link to="/ballot">
              <Image src={data.ABSTRACT.images[0].image} alt="abstract" />
            </Link>
          </BlockWrapper>

          <BlockWrapper style={{ gridArea: "2/1/3/2" }}>
            <Link to="/ballot">
              <Image src={data.CARAVAN.images[0].image} alt="caravan" />
            </Link>
          </BlockWrapper>
          <BlockWrapper style={{ gridArea: "2/2/3/3" }}>
            <Link to="/ballot">
              <Image src={data.VENICE.images[0].image} alt="venice" />
            </Link>
          </BlockWrapper>
          <BlockWrapper style={{ gridArea: "2/3/3/4" }}>
            <Link to="/ballot">
              <Image src={data.PARIS.images[0].image} alt="paris" />
            </Link>
          </BlockWrapper>
          <BlockWrapper
            style={{
              gridArea: "3/3/4/4",
              alignItems: "center",
              justifyItems: "start",
            }}
          >
            <div>
              <Link to="/past-winners">
                <Button>see past winners</Button>
              </Link>
            </div>
          </BlockWrapper>
        </Grid>
      </BG>
    </TransitionPage>
  );
};

export default Contest;
