import React, { useState } from "react";
import {
  Dot,
  Year,
  InfluencesText,
  InfluencesName,
  PairWrapper,
  Heading,
  InfluencesTextWrapper,
  Gradient,
  LeftSide,
  RightSide,
  BG,
  TimeLineWrapper,
  Line,
  FutureLine,
  PastLine,
  DotsWrapper,
  ActiveDot,
} from "./styles";
import { info } from "./dataInfluences";
import Slider from "../../components/Slider/slider";
import TransitionPage from "../../components/pageTransition";

const Influences = () => {
  const [state, setState] = useState(info[0]);
  const [length, setLength] = useState(0);

  const handleNext = (i) => {
    setState(info[i]);
    setLength(i);
  };

  let width = 100 / (info.length - 1);
  const singleImage = state.image.map((img, i) => (
    <img key={i} src={img.img} style={img.css} alt={i} />
  ));

  return (
    <TransitionPage>
      <div style={{ backgroundColor: "#180920", height: "100vh" }}>
        <Heading>Influences</Heading>
        <BG>
          <PairWrapper>
            <LeftSide>
              {state.image.length > 1 ? (
                <Slider
                  images={state.image}
                  withText={false}
                  autoPlay={false}
                  dotsOff={false}
                  arrowsOff={true}
                />
              ) : (
                singleImage
              )}
            </LeftSide>
            <RightSide>
              <InfluencesName>{state.name}</InfluencesName>
              <div
                style={{ position: "relative", height: "80%", width: "90%" }}
              >
                <InfluencesTextWrapper>
                  <InfluencesText>{state.content}</InfluencesText>
                </InfluencesTextWrapper>
                <Gradient />
              </div>
            </RightSide>
          </PairWrapper>
        </BG>
        {/* ///////// */}
        <TimeLineWrapper>
          <Line>
            <FutureLine />
            <PastLine style={{ width: `calc(${width} * ${length}%)` }} />
          </Line>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              position: "absolute",
            }}
          /> */}

          <DotsWrapper>
            {info.map((date, i) => (
              <div
                key={i}
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Year selected={i === state.id}>{date.date}</Year>
                <Dot
                  selected={i === state.id}
                  past={i < state.id}
                  onClick={() => handleNext(i)}
                >
                  {i === state.id && <ActiveDot />}
                </Dot>
              </div>
            ))}
          </DotsWrapper>
        </TimeLineWrapper>
      </div>
    </TransitionPage>
  );
};

export default Influences;
