import styled from "styled-components";
import { motion } from "framer-motion";

export const device = {
  tablet: `(min-width: 768px)`,
  small: `(min-width: 1024px)`,
  medium: `(min-width: 1366px)`,
  large: `(min-width: 1920px)`,
};

export const PageTransition = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

export const AnimationWrapper = styled.div`
  overflow: hidden;
`;

export const HeaderWrapper = styled.div`
  position: relative;
  height: 50vh;
`;
export const ImageWrapper = styled.div`
  position: relative;
  height: 50vh;
  // width: 100%;
  @media ${device.tablet} {
    // height: 70vh;
  }
`;

export const H1 = styled(motion.h1)`
  height: 50vh;
  width: 100%;
  justify-content: center;
  display: flex;
  font: 40px Hidden Focus;
  z-index: 1;
  color: #331c65;
  // color: #fff;
  opacity: 0;
  bottom: 10%;
  position: absolute;
  @media ${device.tablet} {
    font: 80px Hidden Focus;
    margin-bottom: 10%;
  }
  @media ${device.medium} {
    font: 120px Hidden Focus;
    margin-bottom: 10%;
  }
`;

export const AnimatedImage = styled(motion.img)`
  width: 30vw;
  left: 16%;
  bottom: 20%; //aparently bigger than starting point in galleries
  position: absolute;
`;
//this is the text that comes withing the pages
export const Text = styled.p`
  display: grid;
  white-space: normal;
  font: 12px Kumbh Sans;
  color: #331c65;
  @media ${device.tablet} {
    font: 1.2vw Kumbh Sans;
  }
`;

//this styles are used in Gallery.jsx

export const GalleryWrapper = styled(motion.div)`
  // display: flex;
  // justify-content: center;
  // height: 100vh;
`;

export const Title = styled.h1`
  position: absolute;
  right: 0px;
  z-index: 1;
  opacity: 0;
  font: 6vw Hidden Focus;
  color: #331c65;
  text-transform: capitalize;
`;

export const WrapperTest = styled.div`
  position: relative;
  align-items: center;
`;

export const GridContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(50, 1fr);
  grid-template-rows: repeat(30, 1fr);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding-top: 4%;
  @media ${device.small} {
    padding-top: 0;
  }

  // position: relative;
  // z-index: 2;

  // & ${WrapperTest}:nth-child(1) {
  //   grid-area: 1/1/15/13;
  //   // display: grid;
  //   align-items: start;
  // }
  // & ${WrapperTest}:nth-child(1) ${Title} {
  //   grid-area: 1/1/15/8;
  //   top: 80%;
  // }
  // & ${WrapperTest}:nth-child(1) ${GalleryWrapper} {
  //   grid-area: 2/2/10/20;
  // }
`;
