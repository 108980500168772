import React from "react";
import Top from "../../images/SVG/HF_Menu_Top.svg";
import Btm from "../../images/SVG/HF_Menu_Btm.svg";
import Logo from "../../images/SVG/HF_Official_Logo.svg";
import { Link } from "react-router-dom";
import { BurgerWrapper, HeaderContainer, LogoImg } from "./styles";

const Header = ({ open, setOpen }) => {
  return (
    <HeaderContainer>
      <div onClick={() => setOpen(false)}>
        <Link to="/">
          <LogoImg src={Logo} alt="logo" />
        </Link>
      </div>

      <BurgerWrapper open={open} onClick={() => setOpen((prev) => !prev)}>
        <img src={Top} alt="top" />
        <img src={Btm} alt="btm" />
      </BurgerWrapper>
    </HeaderContainer>
  );
};

export default Header;
