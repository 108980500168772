import Dust1 from "../../../images/FOTM/Dust1.jpg";
import Dust2 from "../../../images/FOTM/Dust2.jpg";
import Dust3 from "../../../images/FOTM/Dust3.jpg";
import Dust4 from "../../../images/FOTM/Dust4.jpg";
import Dust5 from "../../../images/FOTM/Dust5.jpg";
import Dust6 from "../../../images/FOTM/Dust6.jpg";
import Dust7 from "../../../images/FOTM/Dust7.jpg";
import Dust8 from "../../../images/FOTM/Dust8.jpg";
import Dust9 from "../../../images/FOTM/Dust9.jpg";
import Dust10 from "../../../images/FOTM/Dust10.jpg";



export const dataFotm = {
  title: "Flashbacks from the Metaverse",
  images: [
    {
      image: Dust1,
      title: 'Flashbacks from the Metaverse Number 1',
      date: 'Montreal 2021',
      text: [],
      gridRowStart: 2,
      gridColumnStart: 3,
      gridRowEnd: 5,
      gridColumnEnd: 12,
      layout: "vertical",
    },
    {
      image: Dust2,
      title: 'Flashbacks from the Metaverse Number 2',
      date: 'Montreal 2021',
      text: [],
      gridRowStart: 2,
      gridColumnStart: 13,
      gridRowEnd: 5,
      gridColumnEnd: 32,
      layout: "horizontal",
    },
    {
      image: Dust3,
      title: 'Flashbacks from the Metaverse Number 3',
      date: 'Montreal 2021',
      text: [],
      gridRowStart: 2,
      gridColumnStart: 33,
      gridRowEnd: 5,
      gridColumnEnd: 42,
      layout: "vertical",
    },
    {
      image: Dust4,
      title: 'Flashbacks from the Metaverse Number 4',
      date: 'Montreal 2021',
      text: [],
      gridRowStart: 2,
      gridColumnStart: 43,
      gridRowEnd: 5,
      gridColumnEnd: 52,
      layout: "vertical",
    },
    {
      image: Dust5,
      title: 'Flashbacks from the Metaverse Number 5',
      date: 'Montreal 2021',
      text: [],
      gridRowStart: 2,
      gridColumnStart: 53,
      gridRowEnd: 5,
      gridColumnEnd: 77,
      layout: "horizontal",
    },
    {
      image: Dust6,
      title: 'Flashbacks from the Metaverse Number 6',
      date: 'Montreal 2021',
      text: [],
      gridRowStart: 2,
      gridColumnStart: 79,
      gridRowEnd: 5,
      gridColumnEnd: 88,
      layout: "vertical",
    },
    {
      image: Dust7,
      title: 'Flashbacks from the Metaverse Nmber 7',
      date: 'Montreal 2021',
      text: [],
      gridRowStart: 2,
      gridColumnStart: 90,
      gridRowEnd: 5,
      gridColumnEnd: 105,
      layout: "horizontal",
    },
    {
      image: Dust8,
      title: 'Flashbacks from the Metaverse Number 8',
      date: 'Montreal 2021',
      text: [],
      gridRowStart: 2,
      gridColumnStart: 106,
      gridRowEnd: 5,
      gridColumnEnd: 115,
      layout: "vertical",
    },
    {
      image: Dust9,
      title: 'Flashbacks from the Metaverse Number 9',
      date: 'Montreal 2021',
      text: [],
      gridRowStart: 2,
      gridColumnStart: 116,
      gridRowEnd: 5,
      gridColumnEnd: 125,
      layout: "vertical",
    },
    {
      image: Dust10,
      title: 'Flashbacks from the Metaverse Number 10',
      date: 'Montreal 2021',
      text: [],
      gridRowStart: 2,
      gridColumnStart: 126,
      gridRowEnd: 5,
      gridColumnEnd: 135,
      layout: "vertical",
    },
  ],
};