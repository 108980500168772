import styled from "styled-components";
import { Link } from "react-router-dom";
import { device } from "../../generalStyles";
import { motion } from "framer-motion";

// header.jsx
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 1;
  align-items: center;
  padding: 0.8% 3%;
  top: 0;
`;

export const BurgerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: ${({ open }) => (open ? "4rem" : "1rem")};
  cursor: pointer;
  img {
    transition: all 0.3s linear;
    transform-origin: 0.25rem;
    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    :last-child {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

export const LogoImg = styled.img`
  width: 0.625rem;
  @media ${device.tablet} {
    width: 1.125rem;
  }
`;

//menu.jsx
export const Layer = styled(motion.div)`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
`;

export const MenuItemsWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const LeftMenu = styled(motion.div)`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 100px;
`;
export const RightMenu = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  margin-left: 10%;
`;

export const Li = styled(motion.li)`
  font: 4vw/5vw Hidden Focus;
  color: #f9efff;
  list-style: none;
  margin: 4px auto;
  text-transform: capitalize;
  &:hover {
    color: #8a33b9;
  }
`;

export const Links = styled(Link)`
  color: #f9efff;
  text-decoration: none;
  &:hover {
    color: #8a33b9;
  }
`;
export const LiSmall = styled(motion.li)`
  font: 4vw/4vw Hidden Focus;
  margin-left: 14%;
  letter-spacing: 1px;
  & ${Links} {
    -webkit-text-stroke: 1px #b3c53f;
    color: transparent;
    font: 3vw/3vw Hidden Focus;
    text-transform: capitalize;
    &:hover {
      color: #b3c53f;
    }
  }
`;

export const Ul = styled(motion.ul)`
  list-style: none;
  padding: 0;
  & ${Li}:nth-child(2) ${Links} {
    color: #b3c53f;
    margin: 8% auto 0 9%;
    &:hover {
      color: #8a33b9;
    }
  }
  & ${LiSmall}:nth-child(3) {
    margin-left: 20%;
  }
  & ${LiSmall}:nth-child(4) {
    margin-left: 24%;
  }
  & ${LiSmall}:nth-child(5) {
    margin-left: 30%;
  }
  & ${Li}:nth-child(7) ${Links} {
    margin-left: 20px;
  }
`;

export const ContestImage = styled(motion.img)`
  margin-bottom: 4%;
  width: 80%;
`;
export const ContestText = styled.h3`
  font: 3vw "Mrs Saint Delafield";
  text-transform: capitalize;
  color: #f9efff;
  @media ${device.large} {
    font: 3vw/5vw "Mrs Saint Delafield";
  }
`;

export const ContestYear = styled(ContestText)`
  font: 300 2vw/1vw Kumbh Sans;
  color: #f9efff;
  @media ${device.large} {
    font: 200 2vw/2vw Kumbh Sans;
  }
`;
