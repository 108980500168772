import ComingSoon from "../../../components/ComingSoon/ComingSoon";
import { DIRECTORCUT } from "../../../NewData/dataDirector";
import React from "react";

const WOTW = () => (
  <ComingSoon
    title={DIRECTORCUT.images[2].title}
    image={DIRECTORCUT.images[2]}
  />
);

export default WOTW;
