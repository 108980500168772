import FDTL1 from "../images/FDTL/FDTL1.jpg";
import FDTL2 from "../images/FDTL/FDTL2.jpg";
import FDTL3 from "../images/FDTL/FDTL3.jpg";
import FDTL4 from "../images/FDTL/FDTL4.jpg";
import FDTL5 from "../images/FDTL/FDTL5.jpg";

export const FROMDARKNESSTOLIGHT = {
  title: "From darkness to light",
  images: [
    {
      id: "FDTL1",
      image: FDTL1,
      title: "Velveteen Rabbit",
      date: "Toronto 2020",
      text: [],
      gridRowStart: 1,
      gridColumnStart: 8,
      gridRowEnd: 3,
      gridColumnEnd: 20,
      layout: "horizontal",
    },
    {
      id: "FDTL2",
      image: FDTL2,
      title: "Abandon Hope All Ye Who Enter Here",
      date: "New York",
      text: [],
      gridRowStart: 2,
      gridColumnStart: 20,
      gridRowEnd: 6,
      gridColumnEnd: 32,
      layout: "vertical",
    },
    {
      id: "FDTL3",
      image: FDTL3,
      title: "Mother and Child",
      date: "Montreal 2021",
      text: [],
      gridRowStart: 2,
      gridColumnStart: 33,
      gridRowEnd: 6,
      gridColumnEnd: 52,
      layout: "horizontal",
    },
    {
      id: "FDTL4",
      image: FDTL4,
      title: "Madonna of the Willows",
      date: "New York",
      text: [],
      gridRowStart: 2,
      gridColumnStart: 52,
      gridRowEnd: 5,
      gridColumnEnd: 70,
      layout: "horizontal",
    },
    {
      id: "FDTL5",
      image: FDTL5,
      title: "Know Thyself",
      date: "Phnom Bakheng, Cambodia 2020",
      text: [],
      gridRowStart: 3,
      gridColumnStart: 70,
      gridRowEnd: 6,
      gridColumnEnd: 88,
      layout: "horizontal",
    },
  ],
};
