import styled from "styled-components";
import { device } from "../../generalStyles";

export const BG = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1d0d25;
  color: #f9efff;
`;

export const PageHeader = styled.h1`
  font: 4em Hidden Focus;
  color: #b3c53f;
  margin: auto;
  padding: 8% 0;
  text-align: center;
  @media ${device.small} {
    font-size: 5rem;
  }
`;

export const SubTitle = styled.h2`
  text-align: center;
  font: 3rem Hidden Focus;
  margin-top: 8%;
  margin-bottom: -2rem;
  z-index: 1;
  -webkit-text-stroke: 1px #b3c53f;
  color: transparent;
`;
export const Cross = styled.img`
  position: absolute;
  opacity: 0;
  padding: 24px;
  border-radius: 32%;
  background-color: #1d0d2559;
`;

export const Hover = styled.div`
  position: relative;
  overflow: hidden;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    ${Cross} {
      visibility: visible;
      opacity: 1;
      z-index: 1;
    }
  }
`;
export const PSContainer = styled.div`
  width: 90%;
  padding: 8%;
  margin: 0 auto 8% auto;
  background-color: #2f123d94;
`;
export const PSWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font: 400 2vw Kumbh Sans;
`;

export const InfoWrapper = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-evenly;
  margin: auto;
  div {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      font: 400 1rem Kumbh Sans;
      margin: 1%;
    }
  }
`;
export const PSImage = styled.img`
  width: 20%;
  object-fit: contain;
`;

export const SpanBtn = styled.span`
  background-color: #f9efff30;
  padding: 4px 8px;
  border-radius: 4px;
  color: #f9efff;
`;

export const ImagelessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImagelessText = styled.p`
  font: 400 1rem / 1.8rem "Kumbh Sans";
  margin: 1%;
  width: 60%;
  text-align: center;
`;

export const DashedBox = styled.div`
  border: 1px dashed #8a33b9;
  border-radius: 1rem;
  width: 60%;
  display: flex;
  margin: 4% auto;
`;
