import styled from "styled-components";
import { device } from "../../../generalStyles";

export const First = styled.h1`
  font: 14px Mrs Saint Delafield;
  color: #b3c53f;
  @media ${device.small} {
    font: 2.7em Mrs Saint Delafield;
  }
`;

export const Second = styled.h3`
  font: 1em Hidden Focus;
  color: #331c65;
  @media ${device.small} {
    font: 2.7em Hidden Focus;
  }
`;
export const Third = styled.h3`
  font: 14px Kumbh Sans;
  color: #331c65;
  @media ${device.small} {
    font: 20px Kumbh Sans;
  }
`;

export const Text = styled.p`
  display: grid;
  white-space: normal;
  font: 12px Kumbh Sans;
  color: #331c65;
  @media ${device.tablet} {
    font: 1.1vw Kumbh Sans;
  }
`;

//these styles belong to more pages
export const SubHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 70%;
  @media ${device.small} {
    height: 76%;
  }
`;
