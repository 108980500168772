import React, { useRef } from "react";
import styled from "styled-components";
import CloseIcon from "../images/SVG/HF_Close_Icon.svg";
import { device } from "../generalStyles";

const Background = styled.div`
  width: 100%;
  height: 100%;
  background: #180920;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: ${(props) => (props.zIndex ? props.zIndex : 10)};
`;

const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 94%;
  position: fixed;
  top: 30px;
`;
const ImageWrapper = styled.div`
  width: 70%;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  @media ${device.small} {
    width: 80%;
  }
`;

const Modal = ({ showModal, setShowModal, children, style, zIndex }) => {
  const modalRef = useRef();
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };
  return (
    <>
      {showModal ? (
        <Background zIndex={zIndex} onClick={closeModal} ref={modalRef}>
          <CloseWrapper>
            <img
              onClick={() => setShowModal((prev) => !prev)}
              src={CloseIcon}
              alt="close_icon"
            />
          </CloseWrapper>
          <ImageWrapper style={style} showModal={showModal}>
            {children}
          </ImageWrapper>
        </Background>
      ) : null}
    </>
  );
};

export default Modal;
