export const HeaderAnim = {
  initial: { translateY: -50, opacity: 0 },
  animate: {
    translateY: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  exit: { opacity: 0 },
};

export const AudioAnim = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { delay: 1 },
  },
  exit: { opacity: 0 },
};

export const ImageAnim = {
  initial: {
    clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
  },
  animate: {
    clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
    transition: { delay: 1.4, duration: 0.4 },
  },
  exit: {
    clipPath: "polygon(100% 0, 100% 0%, 100% 100%, 100% 100%)",
    transition: { duration: 0.2 },
  },
};

export const TextAnim = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 1.8,
    },
  },
  exit: { opacity: 0 },
};
