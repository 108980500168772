import ComingSoon from "../../../components/ComingSoon/ComingSoon";
import { DIRECTORCUT } from "../../../NewData/dataDirector";
import React from "react";

const FTP = () => (
  <ComingSoon
    title={DIRECTORCUT.images[6].title}
    image={DIRECTORCUT.images[6]}
  />
);

export default FTP;
