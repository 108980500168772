import ComingSoon from "../../../components/ComingSoon/ComingSoon";
import React from "react";
import { DIRECTORCUT } from "../../../NewData/dataDirector";

const CulturalDisconnect = () => (
  <ComingSoon
    title={DIRECTORCUT.images[0].title}
    image={DIRECTORCUT.images[0]}
  />
);

export default CulturalDisconnect;
