import React from "react";
import styled from "styled-components";
import Chevron from "../../../../components/Chevron";

const Container = styled.div`
  color: #9dad34;
  display: flex;
  flex-direction: column;
  width: 100%;
  font: 1vw Kumbh Sans;
  padding: 0 8%;
  background-color: white;
  align-items: flex-end;
`;
const Rose = ({ modal }) => (
  <Container>
    <div>
      <p>My love for you is an evening star</p>
      <p>that glows once more toward the end of day</p>
      <br />
      <p>My love for you is an ancient scar</p>
      <p>a gash in youth that bled away</p>
      <br />
      <p>My love for you is the morningstar</p>
      <p>the glint of light that leads the way</p>
      <span onClick={modal}>
        <Chevron style={{ margin: "16px 0" }} />
      </span>
    </div>
  </Container>
);

export default Rose;
