import ArrowSliderRight from "../../images/SVG/HF_arrowSliderRight.svg";
import ArrowSliderLeft from "../../images/SVG/HF_arrowSliderLeft.svg";
import { Arrow } from "./styles";

function Arrows({ prevSlide, nextSlide }) {
  return (
    <div>
      <Arrow style={{ left: 8 }} src={ArrowSliderLeft} onClick={prevSlide} />
      <Arrow style={{ right: 8 }} src={ArrowSliderRight} onClick={nextSlide} />
    </div>
  );
}

export default Arrows;
