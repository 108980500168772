import React from "react";
import I0 from "../images/LATF/0.jpg";
import I1 from "../images/LATF/1.jpg";
import I2 from "../images/LATF/2.jpg";
import I3 from "../images/LATF/3.jpg";
import I4 from "../images/LATF/4.jpg";
import I5 from "../images/LATF/5.jpg";
import I6 from "../images/LATF/6.jpg";
import I7 from "../images/LATF/7.jpg";
import I8 from "../images/LATF/8.jpg";
import I9 from "../images/LATF/9.jpg";
import I10 from "../images/LATF/10.jpg";
import I11 from "../images/LATF/11.jpg";
import I12 from "../images/LATF/12.jpg";
import I13 from "../images/LATF/13.jpg";
import I14 from "../images/LATF/14.jpg";
import I15 from "../images/LATF/15.jpg";
import I16 from "../images/LATF/16.jpg";
import I17 from "../images/LATF/17.jpg";
import I18 from "../images/LATF/18.jpg";
import I19 from "../images/LATF/19.jpg";
import I20 from "../images/LATF/20.jpg";
import I21 from "../images/LATF/21.jpg";
import I22 from "../images/LATF/22.jpg";
import I23 from "../images/LATF/23.jpg";
import I24 from "../images/LATF/24.jpg";
import I25 from "../images/LATF/25.jpg";
import I26 from "../images/LATF/26.jpg";
import I27 from "../images/LATF/27.jpg";
import I28 from "../images/LATF/28.jpg";
import I29 from "../images/LATF/29.jpg";
import I30 from "../images/LATF/30.jpg";
import I31 from "../images/LATF/31.jpg";
import I32 from "../images/LATF/32.jpg";
import I33 from "../images/LATF/33.jpg";
import I34 from "../images/LATF/34.jpg";
import I35 from "../images/LATF/35.jpg";
import I36 from "../images/LATF/36.jpg";
import I37 from "../images/LATF/37.jpg";
import I38 from "../images/LATF/38.jpg";
import I39 from "../images/LATF/39.jpg";
import I40 from "../images/LATF/40.jpg";
import I41 from "../images/LATF/41.jpg";
import I42 from "../images/LATF/42.jpg";
import I43 from "../images/LATF/43.jpg";
import I44 from "../images/LATF/44.jpg";
import I45 from "../images/LATF/45.jpg";
import I46 from "../images/LATF/46.jpg";
import I47 from "../images/LATF/47.jpg";
import I48 from "../images/LATF/48.jpg";
import I49 from "../images/LATF/49.jpg";
import I50 from "../images/LATF/50.jpg";
import I51 from "../images/LATF/51.jpg";
import I52 from "../images/LATF/52.jpg";
import I53 from "../images/LATF/53.jpg";
import I54 from "../images/LATF/54.jpg";
import I55 from "../images/LATF/55.jpg";
import I56 from "../images/LATF/56.jpg";
import I56a from "../images/LATF/56a.jpg";
import I57 from "../images/LATF/57.jpg";
import I58 from "../images/LATF/58.jpg";
import I59 from "../images/LATF/59.jpg";
import I60 from "../images/LATF/60.jpg";
import I61 from "../images/LATF/61.jpg";
import I62 from "../images/LATF/62.jpg";
import I63 from "../images/LATF/63.jpg";
import I64 from "../images/LATF/64.jpg";
import I65 from "../images/LATF/65.jpg";
import I66 from "../images/LATF/66.jpg";
import I67 from "../images/LATF/67.jpg";
import I68 from "../images/LATF/68.jpg";
import I69 from "../images/LATF/69.jpg";
import I70 from "../images/LATF/70.jpg";
import I71 from "../images/LATF/71.jpg";
import I72 from "../images/LATF/72.jpg";
import I73 from "../images/LATF/73.jpg";
import I74 from "../images/LATF/74.jpg";
import I75 from "../images/LATF/75.jpg";
import I76 from "../images/LATF/76.jpg";
import I77 from "../images/LATF/77.jpg";
import I78 from "../images/LATF/78.jpg";
import I79 from "../images/LATF/79.jpg";
import I80 from "../images/LATF/80.jpg";
import I81 from "../images/LATF/81.jpg";
import Focus from "../pages/themes/LATF/Texts/focus";
import ROTH from "../pages/themes/LATF/Texts/roth";
import Windhover from "../pages/themes/LATF/Texts/windhover";
import Cole from "../pages/themes/LATF/Texts/cole";
import Georgia from "../pages/themes/LATF/Texts/georgia";
import Matthew from "../pages/themes/LATF/Texts/matthew";
import Goethe from "../pages/themes/LATF/Texts/goethe";
import Thomas from "../pages/themes/LATF/Texts/thomas";
import William from "../pages/themes/LATF/Texts/william";
import Hugo from "../pages/themes/LATF/Texts/hugo";
import Keats from "../pages/themes/LATF/Texts/keats";
import styled from "styled-components";
import { device } from "../generalStyles";

const Subtitle = styled.span`
  font: 2rem Hidden Focus;
  color: #431e57;
  @media ${device.small} {
    font-size: 3rem;
  }
  @media ${device.medium} {
    font-size: 4rem;
  }
`;
const LATFTitle = (
  <span>
    <span>Look at the Flowers</span>
    <br />
    <Subtitle> A Digital Coffee Table Book</Subtitle>
  </span>
);

export const LOOKATTHEFLOWERS1 = {
  title: LATFTitle,
  images: [
    {
      id: "LATF0",
      image: I0,
      imageGridColumnStart: 5,
      imageGridColumnEnd: 17,
      text: <Focus />,
      textGridColumnStart: 1,
      textGridColumnEnd: 11,
      layout: "horizontal",
      title: "A Hummingbird’s Dream",
      date: "Toronto 2019",
      marginTop: "",
      speed: 0,
    },
    {
      id: "LATF1",
      image: I1,
      imageGridColumnStart: 5,
      imageGridColumnEnd: 10,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Zero Point",
      date: "Toronto 2019",
      marginTop: "",
      speed: 0,
    },
    {
      id: "LATF2",
      image: I2,
      imageGridColumnStart: 10,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "2%",
      speed: 0,
    },
    {
      id: "LATF3",
      image: I3,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 12,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "The Old Fiddler",
      date: "Toronto 2019",
      marginTop: "8%",
      speed: 2,
    },
    {
      id: "LATF4",
      image: I4,
      imageGridColumnStart: 16,
      imageGridColumnEnd: 19,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "-2%",
      speed: 1,
    },
    {
      id: "LATF5",
      image: I5,
      imageGridColumnStart: 12,
      imageGridColumnEnd: 17,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "4%",
      speed: 2,
    },
    {
      id: "LATF6",
      image: I6,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 21,
      text: <Keats />,
      textGridColumnStart: 14,
      textGridColumnEnd: 19,
      layout: "horizontal",
      title: "Homage to John Keats",
      date: "Toronto 2020",
      marginTop: "12%",
      speed: 4,
    },
    {
      id: "LATF7",
      image: I7,
      imageGridColumnStart: 5,
      imageGridColumnEnd: 7,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "4%",
      speed: 0,
    },
    {
      id: "LATF8",
      image: I8,
      imageGridColumnStart: 8,
      imageGridColumnEnd: 19,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "4%",
      speed: 2,
    },
    {
      id: "LATF9",
      image: I9,
      imageGridColumnStart: 6,
      imageGridColumnEnd: 16,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "Sunya Tulip",
      date: "Toronto 2020",
      marginTop: "4%",
      speed: 0,
    },
    {
      id: "LATF10",
      image: I10,
      imageGridColumnStart: 3,
      imageGridColumnEnd: 19,
      imageBG: "#180920",
      padding: "4%",
      text: <ROTH />,
      textGridColumnStart: 1,
      textGridColumnEnd: 21,
      textBG: "#180920",
      layout: "horizontal",
      title: "The Heart of an Artist",
      date: "Toronto 2019",
      marginTop: "6%",
      speed: 0,
      textMarginTop: "-2%",
    },
    {
      id: "LATF11",
      image: I11,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 15,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "4%",
      speed: -4,
    },
    {
      id: "LATF12",
      image: I12,
      imageGridColumnStart: 5,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "2%",
      speed: -5,
    },
    {
      id: "LATF13",
      image: I13,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 11,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "8%",
      speed: -6,
    },
    {
      id: "LATF14",
      image: I14,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      textBG: "white",
      layout: "horizontal",
      title: "Rose Window",
      date: "Toronto 2020",
      marginTop: "6%",
      speed: -10,
    },
  ],
};
export const LOOKATTHEFLOWERS2 = {
  images: [
    {
      id: "LATF15",
      image: I15,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "12%",
      speed: -10,
    },
    {
      id: "LATF16",
      image: I16,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 7,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Heirloom Corsage",
      date: "Toronto 2019",
      marginTop: "8%",
      speed: -12,
    },
    {
      id: "LATF17",
      image: I17,
      imageGridColumnStart: 9,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "8%",
      speed: -12,
    },
    {
      id: "LATF18",
      image: I18,
      imageGridColumnStart: 2,
      imageGridColumnEnd: 11,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "Orchids Duchamp",
      date: "Toronto 2019",
      marginTop: "4%",
      speed: -12,
    },
    {
      id: "LATF19",
      image: I19,
      imageGridColumnStart: 10,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "4%",
      speed: -12,
    },
    {
      id: "LATF20",
      image: I20,
      imageGridColumnStart: 6,
      imageGridColumnEnd: 16,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "Annunciation",
      date: "Toronto 2019",
      marginTop: "12%",
      speed: "",
    },
    {
      id: "LATF21",
      image: I21,
      imageGridColumnStart: 5,
      imageGridColumnEnd: 21,
      text: <Windhover />,
      textGridColumnStart: 2,
      textGridColumnEnd: 15,
      layout: "horizontal",
      title: "Windhover",
      date: "Toronto 2019",
      marginTop: "20%",
      speed: "",
    },
    {
      id: "LATF22",
      image: I22,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Medusa",
      date: "Toronto 2019",
      marginTop: "8%",
      speed: "",
    },
    {
      id: "LATF23",
      image: I23,
      imageGridColumnStart: 11,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "12%",
      speed: "",
    },
    {
      id: "LATF24",
      image: I24,
      imageGridColumnStart: 4,
      imageGridColumnEnd: 8,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "-4%",
      speed: "",
    },
    {
      id: "LATF25",
      image: I25,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 11,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Unexpected Guest",
      date: "Toronto 2019",
      marginTop: "10%",
      speed: "",
    },
    {
      id: "LATF26",
      image: I26,
      imageGridColumnStart: 12,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "",
      speed: "",
    },
    {
      id: "LATF27",
      image: I27,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "12%",
      speed: "",
    },
    {
      id: "LATF28",
      image: I28,
      imageGridColumnStart: 7,
      imageGridColumnEnd: 14,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Birds of a Feather",
      date: "Toronto 2020",
      marginTop: "10%",
      speed: "",
    },
    {
      id: "LATF29",
      image: I29,
      imageGridColumnStart: 15,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "",
      speed: "",
    },
    {
      id: "LATF30",
      image: I30,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 15,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "8%",
      speed: "",
    },
    {
      id: "LATF31",
      image: I31,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "8%",
      speed: "",
    },
    {
      id: "LATF32",
      image: I32,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 9,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Memento Mori",
      date: "Toronto 2019",
      marginTop: "6%",
      speed: "",
    },
    {
      id: "LATF33",
      image: I33,
      imageGridColumnStart: 5,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "A Lei of Peonies",
      date: "Toronto 2020",
      marginTop: "4%",
      speed: "",
    },
    {
      id: "LATF34",
      image: I34,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Bonfire",
      date: "Toronto 2019",
      marginTop: "6%",
      speed: "",
    },
    {
      id: "LATF35",
      image: I35,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 13,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "Homage to Monet",
      date: "Toronto 2020",
      marginTop: "10%",
      speed: "",
    },

    {
      id: "LATF36",
      image: I36,
      imageGridColumnStart: 4,
      imageGridColumnEnd: 21,
      text: <Cole />,
      textGridColumnStart: 17,
      textGridColumnEnd: 20,
      layout: "horizontal",
      title: "In the Still of the Night",
      date: "Toronto 2020",
      marginTop: "20%",
      speed: "",
    },
    {
      id: "LATF37",
      image: I37,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 9,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "4%",
      speed: "",
    },
    {
      id: "LATF38",
      image: I38,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Alien",
      date: "Toronto 2019",
      marginTop: "6%",
      speed: "",
    },
    {
      id: "LATF39",
      image: I39,
      imageGridColumnStart: 3,
      imageGridColumnEnd: 11,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Viper",
      date: "Toronto 2019",
      marginTop: "20%",
      speed: "",
    },
    {
      id: "LATF40",
      image: I40,
      imageGridColumnStart: 11,
      imageGridColumnEnd: 19,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Happy Dance",
      date: "Toronto 2019",
      marginTop: "4%",
      speed: "",
    },

    {
      id: "LATF41",
      image: I41,
      imageGridColumnStart: 6,
      imageGridColumnEnd: 16,
      text: <Georgia />,
      textGridColumnStart: 6,
      textGridColumnEnd: 16,
      layout: "vertical",
      title: "Homage to Georgia O’Keeffe",
      date: "Toronto 2020",
      marginTop: "20%",
      speed: "",
    },
    {
      id: "LATF42",
      image: I42,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 10,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "12%",
      speed: "",
    },
    {
      id: "LATF43",
      image: I43,
      imageGridColumnStart: 9,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "10%",
      speed: "",
    },
    {
      id: "LATF44",
      image: I44,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "8%",
      speed: "",
    },
    {
      id: "LATF45",
      image: I45,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 13,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "20%",
      speed: "",
    },
    {
      id: "LATF46",
      image: I46,
      imageGridColumnStart: 15,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "I Am Groot",
      date: "Toronto 2019",
      marginTop: "2%",
      speed: "",
    },
    {
      id: "LATF47",
      image: I47,
      imageGridColumnStart: 3,
      imageGridColumnEnd: 13,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "-2%",
      speed: "",
    },
    {
      id: "LATF48",
      image: I48,
      imageGridColumnStart: 13,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "",
      speed: "",
    },
    {
      id: "LATF49",
      image: I49,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Homage to Mandelbrot",
      date: "Toronto 2019",
      marginTop: "20%",
      speed: "",
    },
    {
      id: "LATF50",
      image: I50,
      imageGridColumnStart: 5,
      imageGridColumnEnd: 21,
      text: <Matthew />,
      textGridColumnStart: 3,
      textGridColumnEnd: 10,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "12%",
      speed: "",
    },
    {
      id: "LATF51",
      image: I51,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "12%",
      speed: "",
    },
    {
      id: "LATF52",
      image: I52,
      imageGridColumnStart: 4,
      imageGridColumnEnd: 10,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "8%",
      speed: "",
    },
    {
      id: "LATF53",
      image: I53,
      imageGridColumnStart: 11,
      imageGridColumnEnd: 20,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "-4%",
      speed: "",
    },
    {
      id: "LATF54",
      image: I54,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 13,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Hidden Focus",
      date: "Toronto 2019",
      marginTop: "4%",
      speed: "",
    },
    {
      id: "LATF55",
      image: I55,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Homage to Robert Mapplethorpe",
      date: "Toronto 2019",
      marginTop: "4%",
      speed: "",
    },
    {
      id: "LATF56",
      image: I56,
      imgWidth: "60%",
      imageGridColumnStart: 10,
      imageGridColumnEnd: 21,
      text: <Hugo />,
      textGridColumnStart: 11,
      textGridColumnEnd: 16,
      layout: "horizontal",
      title: "Homage to Hugo Wolf",
      date: "Toronto 2019",
      marginTop: "4%",
      speed: "",
      textMarginTop: "",
    },
    {
      id: "LATF56",
      image: I56a,
      imageGridColumnStart: 7,
      imageGridColumnEnd: 10,
      text: null,
      textGridColumnStart: 3,
      textGridColumnEnd: 10,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "8%",
      speed: "",
    },
    {
      id: "LATF57",
      image: I57,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 10,
      text: <Goethe />,
      textGridColumnStart: 12,
      textGridColumnEnd: 18,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "8%",
      speed: "",
      textMarginTop: "-28%",
    },
    {
      id: "LATF58",
      image: I58,
      imgWidth: "100%",
      imageGridColumnStart: 1,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "8%",
      speed: "",
    },
    {
      id: "LATF59",
      image: I59,
      imgWidth: "60%",
      imageGridColumnStart: 5,
      imageGridColumnEnd: 17,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Forget Me Not",
      date: "Toronto 2019",
      marginTop: "10%",
      speed: "",
    },
    {
      id: "LATF60",
      image: I60,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 8,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "12%",
      speed: "",
    },
    {
      id: "LATF61",
      image: I61,
      imageGridColumnStart: 9,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "Supernova",
      date: "Toronto 2019",
      marginTop: "",
      speed: "",
    },
    {
      id: "LATF62",
      image: I62,
      imageGridColumnStart: 2,
      imageGridColumnEnd: 14,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "2%",
      speed: "",
    },
    {
      id: "LATF63",
      image: I63,
      imageGridColumnStart: 15,
      imageGridColumnEnd: 19,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Gash Gold-Vermillion",
      date: "Toronto 2019",
      marginTop: "-6%",
      speed: "",
    },
    {
      id: "LATF64",
      image: I64,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 7,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "4%",
      speed: "",
    },
    {
      id: "LATF65",
      image: I65,
      imageGridColumnStart: 11,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "-4%",
      speed: "",
    },
    {
      id: "LATF66",
      image: I66,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 15,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "4%",
      speed: "",
    },
    {
      id: "LATF67",
      image: I67,
      imageGridColumnStart: 14,
      imageGridColumnEnd: 20,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "2%",
      speed: "",
    },
    {
      id: "LATF68",
      image: I68,
      imageGridColumnStart: 3,
      imageGridColumnEnd: 11,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Trio for Dandelions and Crabgrass",
      date: "Toronto 2019",
      marginTop: "8%",
      speed: "",
    },
    {
      id: "LATF69",
      image: I69,
      imageGridColumnStart: 8,
      imageGridColumnEnd: 20,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "Chive Starburst",
      date: "Toronto 2019",
      marginTop: "4%",
      speed: "",
    },
    {
      id: "LATF70",
      image: I70,
      imageGridColumnStart: 4,
      imageGridColumnEnd: 13,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2020",
      marginTop: "4%",
      speed: "",
    },
    {
      id: "LATF71",
      image: I71,
      imageGridColumnStart: 9,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Great Pumpkin",
      date: "Toronto 2019",
      marginTop: "4%",
      speed: "",
    },
    {
      id: "LATF72",
      image: I72,
      imageGridColumnStart: 4,
      imageGridColumnEnd: 12,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "4%",
      speed: "",
    },
    {
      id: "LATF73",
      image: I73,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 18,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Autumn Light",
      date: "Toronto 2019",
      marginTop: "6%",
      speed: "",
    },
    {
      id: "LATF74",
      image: I74,
      imageGridColumnStart: 6,
      imageGridColumnEnd: 16,
      text: <Thomas />,
      textGridColumnStart: 6,
      textGridColumnEnd: 16,
      layout: "vertical",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "12%",
      speed: "",
    },
    {
      id: "LATF75",
      image: I75,
      imageGridColumnStart: 2,
      imageGridColumnEnd: 7,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "12%",
      speed: "",
    },
    {
      id: "LATF76",
      image: I76,
      imageGridColumnStart: 8,
      imageGridColumnEnd: 21,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "Lachryma Mundi",
      date: "Toronto 2019",
      marginTop: "-4%",
      speed: "",
    },
    {
      id: "LATF77",
      image: I77,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 14,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "untitled",
      date: "Toronto 2019",
      marginTop: "6%",
      speed: "",
    },
    {
      id: "LATF78",
      image: I78,
      imageGridColumnStart: 16,
      imageGridColumnEnd: 19,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "Satori",
      date: "Toronto 2020",
      marginTop: "4%",
      speed: "",
    },
    {
      id: "LATF79",
      image: I79,
      imageGridColumnStart: 6,
      imageGridColumnEnd: 16,
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "vertical",
      title: "Be Lights Unto Yourselves",
      date: "Toronto 2019",
      marginTop: "8%",
      speed: "",
    },
    {
      id: "LATF80",
      image: I80,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 21,
      text: <William />,
      textGridColumnStart: 14,
      textGridColumnEnd: 19,
      layout: "horizontal",
      title: "Queen Anne Galaxy",
      date: "Toronto 2020",
      marginTop: "20%",
      speed: "",
    },
    {
      id: "LATF81",
      image: I81,
      imageGridColumnStart: 1,
      imageGridColumnEnd: 21,
      imageBG: "aliceblue",
      padding: "10%",
      text: null,
      textGridColumnStart: 2,
      textGridColumnEnd: 5,
      layout: "horizontal",
      title: "Elysium",
      date: "Toronto 2019",
      marginTop: "12%",
      speed: "",
    },
  ],
};
