import useAudio from "../../../components/hooks/useAudio";
import HorizontalScroll from "../../../components/HorizontalScroll/horizontalScroll";
import { First, Second, Third, Text } from "./styles";
import Audio from "../../../audio/LT_LiveFeb28_2015_DasLiedIstAus(RemixedForWebLarge).mp3";
import AudioOn from "../../../images/SVG/HF_Audio_On.svg";
import AudioOff from "../../../images/SVG/HF_Audio_Off.svg";
import { ImagesContext } from "../../../contexts/imagesContext";
import React, { useContext, useState } from "react";
import Chevron from "../../../components/Chevron";
import Modal from "../../../components/Modal";

const Enescu = () => {
  const [playing, toggle] = useAudio(Audio);
  const { data } = useContext(ImagesContext);
  const [showModalPoem, setShowModalPoem] = useState(false);

  const openModalPoems = () => setShowModalPoem((prev) => !prev);

  return (
    <>
      <Modal showModal={showModalPoem} setShowModal={setShowModalPoem}>
        <div>
          <h1
            style={{
              color: "#dcc02e",
              font: "2rem Hidden Focus",
              margin: "2% auto",
            }}
          >
            DAS LIED IST AUS
          </h1>
          <div
            style={{
              margin: "auto",
              width: "90%",
              display: "flex",
            }}
          >
            <div>
              <p style={{ font: "1rem Kumbh Sans", color: "#f9efff" }}>
                Frag’ nicht, warum ich gehe…
                <br />
                Frag’ nicht warum!
                <br />
                Was immer auch geschehe…
                <br />
                Frag’ nicht warum!
                <br />
                <br />
                Ich can dir nur mehr sagen:
                <br />
                Ich hab dich lieb!
                <br />
                Das Schönste im Leben
                <br />
                Wollt’ ich dir geben!
                <br />
                <br />
                Frag’ mich bloss nicht das Eine,
                <br />
                Frag nicht warum!
                <br />
                Frag’ nicht, warum ich weine…
                <br />
                Frag’ nicht warum!
                <br />
                <br />
                Wir gehen aus einander…
                <br />
                morgen küst dich ein and’rer,
                <br />
                Dann wirst du nicht mehr fragen warum!
                <br />
                <br />
                Das lied ist aus, das du fur mich gesungen.
                <br />
                Beim letzten Klang war mir nach dir so bang.
                <br />
                Das lied ist aus, die Melodie verklungen,
                <br />
                Nichts blieb von der Musik zurück,
                <br />
                Ein echo nur von Liebe!
                <br />
                Die Rosen, die ich dir gebracht,
                <br />
                Sind jetzt ein welker Blumenstrauss…
                <br />
                Das Lied ist aus…
                <br />
                <br />
                <br />
                <br />
                Musik: Robert Stolz
                <br />
                Text: Walter Reisch/Armin L. Robinson
              </p>
            </div>
            <div style={{ marginLeft: "auto" }}>
              <p style={{ font: "1rem Kumbh Sans", color: "#f9efff" }}>
                Don’t ask me why I’m leaving
                <br />
                Don’t ask me why
                <br />
                Whatever was I thinking
                <br />
                Don’t ask me why
                <br />
                <br />
                I can no longer whisper
                <br />
                I love you so
                <br />
                And all of life’s good things
                <br />
                I’ll give you to know
                <br />
                <br />
                For the last time, stop asking
                <br />
                Don’t ask me why
                <br />
                Don’t ask me why I’m weeping
                <br />
                Don’t ask me why
                <br />
                <br />
                Although tonight we’re parting
                <br />
                By dawn you’ll kiss a new love
                <br />
                Then you won’t be asking me why
                <br />
                <br />
                The music’s gone, your song that so enthralled me
                <br />
                It’s last reprise still brought me to my knees
                <br />
                The music’s gone, the spell it cast is broken
                <br />
                There’s nothing of our love song left
                <br />
                Not even distant echoes
                <br />
                The roses that I’d bring for you
                <br />
                Are now just withered memories
                <br />
                The music’s gone
                <br />
                <br />
                <br />
                <br />
                <br />
                Translation &copy;Ray Hanson 2022
              </p>
            </div>
          </div>
        </div>
      </Modal>
      <HorizontalScroll
        title={data.ENESCU.title}
        photos={data.ENESCU.images}
        width={"400vw"}
        columnAmount={106}
      >
        <Text
          style={{
            gridArea: "2 / 3 / 4 / 10",
            placeItems: "end",
            textAlign: "center",
          }}
        >
          “What’s the connection between your flower pictures and Enescu’s
          music?” asked the Festival’s producer. The answer was to be discovered
          in the imagination of each concertgoer.
          {<br />}
          {<br />}I would call the six photo exhibit “George Enescu’s Secret
          Garden,” and invite the viewers to “give the pictures a name that
          would make Enescu smile.”
          {<br />}
          {<br />}
          Some 225 to 275 people attended each concert, and 12% to 15% of them
          were intrigued enough to fill out a ballot!
          {<br />}
          {<br />}The winning names are highlighted on the next three pages.
        </Text>
        <Text style={{ gridArea: "5 / 15 / 6 / 21", display: "inline-block" }}>
          Michael is also a generous collaborator with vocalists in song. In
          2013 at Toronto’s Musideum, Michael and I performed together “Das Lied
          Ist Aus,” a non-traditional love song written in the 1930’s by Robert
          Stolz.
          <span onClick={openModalPoems} style={{ marginLeft: 8 }}>
            <Chevron />
          </span>
        </Text>

        <div
          onClick={toggle}
          style={{
            margin: "64px 8px 8px 8px",
            alignSelf: "center",
            gridArea: "5 / 31 / 6 / 32",
          }}
        >
          {playing ? (
            <img width="40px" src={AudioOn} alt="Audio_Icon" />
          ) : (
            <img width="40px" src={AudioOff} alt="Audio_Icon" />
          )}
        </div>

        <div style={{ gridArea: "2 / 21 / 4 / 28" }}>
          <Text>
            Michael Berkovsky and his wife Coral Solomon were the Festival’s
            performance organizers, and it was their idea to invite my
            participation.
            {<br />}
            {<br />}
            Both Michael and Coral are established piano concert artists. They
            are deeply committed to fostering the development of young talent,
            and to that end they have founded the{" "}
            <strong>
              <a
                style={{ color: "#331c65", textDecoration: "none" }}
                href="https://academybsam.editorx.io/bsam"
              >
                Berkovsky-Solomon Academy of Music
              </a>
            </strong>
          </Text>
        </div>

        <div style={{ gridArea: "2 / 35 / 4 / 40" }}>
          <Text>
            Concertgoers casting <i>Name the Picture</i> ballots at Glenn Gould
            Studio CBC performance.
          </Text>
        </div>

        <div style={{ gridArea: "5 / 35 / 6 / 40" }}>
          <Text>This inspired young artist is creating her own exhibit!</Text>
        </div>

        <div
          style={{
            display: "grid",
            gridArea: "1 / 48 / 2 / 58",
            alignContent: "center",
          }}
        >
          <First style={{ marginLeft: -20 }}>1st Concert</First>
          <Second>Trinity-Saint Paul’s Center, Toronto</Second>
          <Third>September 7, 2019</Third>
        </div>
        <div
          style={{
            display: "grid",
            gridArea: "1 / 66 / 2 / 76",
            alignContent: "center",
          }}
        >
          <First style={{ marginLeft: -20 }}>2nd Concert</First>
          <Second>Eglinton St. George’s United Church, Toronto</Second>
          <Third>September 14, 2019</Third>
        </div>
        <div
          style={{
            display: "grid",
            gridArea: "1 / 88 / 2 / 94",
            alignContent: "center",
          }}
        >
          <First style={{ marginLeft: -20 }}>3rd Concert</First>
          <Second>Glenn Gould Studio CBC, Toronto</Second>
          <Third>September 22, 2019</Third>
        </div>
      </HorizontalScroll>
    </>
  );
};

export default Enescu;
