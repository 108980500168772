import I0 from "../../images/LATF/0.jpg";
import LS1 from "../../images/LS/0.jpg";
import AW1 from "../../images/AW/1.jpg";
import I3 from "../../images/CAUC/intro.png";
import Mexico from "../../images/MX/intro.png";
import I1 from "../../images/NY/1.jpg";
import Paris from "../../images/PARIS/1.jpg";
import Toronto from "../../images/TORONTO/1.jpg";
import Venice from "../../images/VENICE/1.jpg";
import Caravan from "../../images/CARAVAN/1.jpg";
import FS1 from "../../images/FS/1.jpg";
import ABS1 from "../../images/ABSTRACT/1.webp";
import JC1 from "../../images/JC/1.jpg";
import EXP1 from "../../images/EXPLO/1.jpg";
import GE from "../../images/GE/intro.png";
import CapeCode from "../../images/CAPECODE/WEB-Final-892-257kb.jpg";
import Singapur from "../../images/SINGAPUR/WEB-Final-0327-256kb.jpg";
import Havana from "../../images/CAUC/3.jpg";
import Lawrence from "../../images/INFL/WEB-Infl-3-Lawrence-2037-263kb.jpg";
import Director from "../../images/DC/directorCut.jpg";

export const museum = [
  {
    floors: "themes",
    rooms: [
      {
        to: "look-at-the-flowers",
        id: 1,
        image: I0,
        gridRowStart: 18,
        gridColumnStart: 13,
        gridRowEnd: 28,
        gridColumnEnd: 28,
        comingSoon: false,
        title: "Look at the Flowers: A Digital Coffee Table Book",
        layout: "horizontal",
      },
      {
        to: "lightscapes",
        id: 2,
        image: LS1,
        gridRowStart: 2,
        gridColumnStart: 28,
        gridRowEnd: 8,
        gridColumnEnd: 37,
        comingSoon: false,
        title: "Lightscapes",
        layout: "horizontal",
      },
      {
        to: "portraits",
        id: 3,
        image: Lawrence,
        gridRowStart: 7,
        gridColumnStart: 44,
        gridRowEnd: 14,
        gridColumnEnd: 48,
        comingSoon: true,
        title: "Portraits",
        layout: "vertical",
      },
      {
        to: "found-subjects",
        id: 4,
        image: FS1,
        gridRowStart: 10,
        gridColumnStart: 34,
        gridRowEnd: 15,
        gridColumnEnd: 41,
        comingSoon: true,
        title: "Found Subjects",
        layout: "horizontal",
      },
      {
        to: "abstracts",
        id: 5,
        image: ABS1,
        gridRowStart: 20,
        gridColumnStart: 39,
        gridRowEnd: 28,
        gridColumnEnd: 50,
        comingSoon: true,
        title: "Abstracts",
        layout: "horizontal",
      },
      {
        to: "just-color",
        id: 6,
        image: JC1,
        gridRowStart: 6,
        gridColumnStart: 15,
        gridRowEnd: 10,
        gridColumnEnd: 21,
        comingSoon: true,
        title: "Just Color",
        layout: "horizontal",
      },
      {
        to: "explorations",
        id: 7,
        image: EXP1,
        gridRowStart: 3,
        gridColumnStart: 4,
        gridRowEnd: 9,
        gridColumnEnd: 12,
        comingSoon: true,
        title: "Explorations",
        layout: "horizontal",
      },
      {
        to: "director-cut",
        id: 8,
        image: Director,
        gridRowStart: 13,
        gridColumnStart: 5,
        gridRowEnd: 20,
        gridColumnEnd: 10,
        comingSoon: false,
        title: "Director's Cut",
        layout: "horizontal",
      },
    ],
  },
  {
    floors: "destinations",
    rooms: [
      {
        to: "angkor-wat",
        id: 9,
        image: AW1,
        gridRowStart: 4,
        gridColumnStart: 4,
        gridRowEnd: 10,
        gridColumnEnd: 13,
        comingSoon: false,
        title: "Angkor Wat",
        layout: "horizontal",
      },
      {
        to: "new-york",
        id: 10,
        image: I1,
        gridRowStart: 20,
        gridColumnStart: 11,
        gridRowEnd: 27,
        gridColumnEnd: 21,
        comingSoon: false,
        title: "New York",
        layout: "horizontal",
      },
      {
        to: "paris",
        id: 11,
        image: Paris,
        gridRowStart: 2,
        gridColumnStart: 28,
        gridRowEnd: 7,
        gridColumnEnd: 35,
        comingSoon: true,
        title: "Paris",
        layout: "horizontal",
      },
      {
        to: "havana",
        id: 12,
        image: Havana,
        gridRowStart: 6,
        gridColumnStart: 14,
        gridRowEnd: 12,
        gridColumnEnd: 23,
        comingSoon: true,
        title: "Havana",
        layout: "horizontal",
      },
      {
        to: "toronto",
        id: 13,
        image: Toronto,
        gridRowStart: 16,
        gridColumnStart: 44,
        gridRowEnd: 26,
        gridColumnEnd: 50,
        comingSoon: true,
        title: "Toronto",
        layout: "vertical",
      },
      {
        to: "singapur",
        id: 14,
        image: Singapur,
        gridRowStart: 24,
        gridColumnStart: 22,
        gridRowEnd: 30,
        gridColumnEnd: 30,
        comingSoon: true,
        title: "Singapur",
        layout: "horizontal",
      },
      {
        to: "venice",
        id: 15,
        image: Venice,
        gridRowStart: 15,
        gridColumnStart: 3,
        gridRowEnd: 26,
        gridColumnEnd: 10,
        comingSoon: true,
        title: "Venice",
        layout: "vertical",
      },
      {
        to: "cape-cod",
        id: 16,
        image: CapeCode,
        gridRowStart: 5,
        gridColumnStart: 36,
        gridRowEnd: 12,
        gridColumnEnd: 46,
        comingSoon: true,
        title: "Cape Cod",
        layout: "horizontal",
      },
      {
        to: "caravan",
        id: 17,
        image: Caravan,
        gridRowStart: 20,
        gridColumnStart: 32,
        gridRowEnd: 24,
        gridColumnEnd: 38,
        comingSoon: true,
        title: "Caravan",
        layout: "horizontal",
      },
    ],
  },
  {
    floors: "shows",
    rooms: [
      {
        to: "george-enescu",
        id: 18,
        image: GE,
        gridRowStart: 3,
        gridColumnStart: 6,
        gridRowEnd: 14,
        gridColumnEnd: 17,
        comingSoon: false,
        title: "George Enescu's Secret Garden",
        layout: "horizontal",
      },
      {
        to: "como-en-casa",
        id: 19,
        image: Mexico,
        gridRowStart: 20,
        gridColumnStart: 25,
        gridRowEnd: 29,
        gridColumnEnd: 40,
        comingSoon: false,
        title: "Como en Casa",
        layout: "horizontal",
      },
      {
        to: "cuatro-amigos",
        id: 20,
        image: I3,
        gridRowStart: 4,
        gridColumnStart: 34,
        gridRowEnd: 16,
        gridColumnEnd: 48,
        comingSoon: false,
        title: "Quatro Amigos: Una Cuba",
        layout: "horizontal",
      },
    ],
  },
];
