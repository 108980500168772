import React, { useState } from "react";
import { motion } from "framer-motion";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import WhoMe from "../../images/WHYNOW/WEB-9574-252kb--Who-Me.jpg";
import Leather from "../../images/WHYNOW/Leather.jpg";
import { WNData, MementosData, NonRandomSlidesData } from "./data";
import { PageTransition } from "../../generalStyles";
import {
  LetterHead,
  Title,
  Block1,
  P,
  ImageWrapper,
  TextWrapper,
  CenterTextWrapper,
  PStyled,
  Container,
} from "./styles";
import Chevron from "../../components/Chevron";
import Modal from "../../components/Modal";
import Slider from "../../components/Slider/slider";
import AudioOn from "../../images/SVG/HF_Audio_On.svg";
import AudioOff from "../../images/SVG/HF_Audio_Off.svg";
import Audio from "../../audio/Ray Hanson - Why now(Ver4).mp3";
import useAudio from "../../components/hooks/useAudio";
import { MementosSlidesData } from "./data";
import { AudioAnim, HeaderAnim, ImageAnim, TextAnim } from "./whyNowAnimations";

const WhatNow = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalTwo, setShowModalTwo] = useState(false);
  const [showModalNRP, setShowModalNRP] = useState(false);
  const [showModalSingle, setShowModalSingle] = useState(false);
  const [playing, toggle] = useAudio(Audio);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selected, setSelected] = useState(0);

  const openModal = (id) => setShowModal((prev) => !prev);

  const openModalSecond = (id) => {
    setShowModalTwo((prev) => !prev);
    setCurrentIndex(id.id);
  };

  const openModalSingleImage = (el) => {
    setSelected(el);
    setShowModalSingle((prev) => !prev);
  };

  const openModalNRP = (id) => {
    setShowModalNRP((prev) => !prev);
    setCurrentIndex(id.id);
  };

  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={PageTransition}
      style={{ backgroundColor: "#F5F5F5" }}
    >
      <Modal
        zIndex={11}
        showModal={showModalTwo}
        setShowModal={setShowModalTwo}
      >
        <div style={{ width: "60%", margin: "auto" }}>
          <Slider
            activeIndexMementos={currentIndex}
            images={MementosSlidesData}
            withText={false}
            autoPlay={false}
            dotsOff={true}
            width={"70vh"}
            height={"auto"}
          />
        </div>
      </Modal>

      <Modal
        zIndex={11}
        showModal={showModalNRP}
        setShowModal={setShowModalNRP}
      >
        <div style={{ width: "60%", margin: "auto" }}>
          <Slider
            activeIndexMementos={currentIndex}
            images={NonRandomSlidesData}
            withText={false}
            autoPlay={false}
            dotsOff={true}
            width={"70vh"}
            height={"auto"}
          />
        </div>
      </Modal>

      <Modal
        zIndex={11}
        showModal={showModalSingle}
        setShowModal={setShowModalSingle}
        style={{
          width: "66%",
          height: selected.layout === "vertical" ? "80%" : "auto",
        }}
      >
        <img
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
          src={selected.image}
          alt={selected.image}
        />
      </Modal>

      <Modal showModal={showModal} setShowModal={setShowModal}>
        <h1
          style={{
            color: "#dcc02e",
            font: "40px Mrs Saint Delafield",
            zIndex: 10,
          }}
        >
          Career Mementos
        </h1>
        <div
          style={{
            position: "relative",
            height: "80vh",
            margin: "0 -14% 0 14%",
          }}
        >
          {MementosData.map((img, i) => (
            <div key={i} className={`image-block mementos-${img.id} `}>
              <div className="img-hover-zoom">
                <img
                  onClick={() =>
                    img.hasSlider && img.id === "1false"
                      ? openModalNRP(img)
                      : img.hasSlider
                      ? openModalSecond(img)
                      : openModalSingleImage(img)
                  }
                  key={i}
                  src={img.image}
                  alt={i}
                />
              </div>
            </div>
          ))}
        </div>
      </Modal>

      <Container>
        <Title {...HeaderAnim}>Why Now</Title>

        <Block1>
          <ImageWrapper>
            <motion.img
              {...ImageAnim}
              style={{ width: "72%" }}
              src={WhoMe}
              alt="Who-Me"
            />

            <motion.div
              {...AudioAnim}
              onClick={toggle}
              style={{ margin: "4px -40px 4px 4px" }}
            >
              {playing ? (
                <img width="40px" src={AudioOn} alt="Audio_Icon" />
              ) : (
                <img width="40px" src={AudioOff} alt="Audio_Icon" />
              )}
            </motion.div>
          </ImageWrapper>

          <TextWrapper {...TextAnim}>
            <P style={{ color: "#301B3C" }}>
              <LetterHead>In</LetterHead> the early Spring of 2019, I picked up
              the camera again after a multi year break. Something magical
              happened: the pictures came to life and began to school me. Image
              after image, the shoots of new life revealed what I had been
              searching for over the years when I stood behind the lens.
            </P>
          </TextWrapper>
        </Block1>

        <CenterTextWrapper {...TextAnim}>
          <P style={{ color: "#6A761D" }}>
            The unfolding leaves and blossoms showed form emerging from the
            deep, light piercing dark and exploding into color. They told their
            story of creation and, by letting me stand witness, told me
            something of my own.
          </P>
        </CenterTextWrapper>
        <CenterTextWrapper {...TextAnim}>
          <P style={{ color: "#301B3C" }}>
            So did the Spring of 2019 mark a turning point in my journey, and
            the start of a new post retirement adventure. The search had woven
            like a thread through the story of my years: from parochial grade
            school through catholic seminary, from running the family business
            through personal relationships and marriage, from early stock market
            speculations through a thirty year career on Wall Street and Bay
            Street.
          </P>
        </CenterTextWrapper>

        <motion.div
          {...TextAnim}
          style={{
            margin: "8%",
            backgroundColor: "white",
            padding: "16% 4% 10%",
          }}
        >
          <Slider
            images={WNData}
            withText={true}
            autoPlay={false}
            dotsOff={true}
          />
        </motion.div>

        <CenterTextWrapper {...TextAnim}>
          <P style={{ color: "#6A761D" }}>
            I retired in October, 2013. Now I could devote serious time and
            effort to following my inner voices, especially those related to
            taking pictures and singing. As expressed in the program for my
            corporate retirement dinner:
          </P>
        </CenterTextWrapper>
        <CenterTextWrapper {...TextAnim}>
          <PStyled>
            Technical Analysis combines elements of pattern recognition, both in
            mathematics and in human behavior. These elements are very much at
            play in Ray’s two passionate avocations: photography and singing *
            both classical repertoire and the Great American Songbook.
            <span onClick={(e) => openModal(e)} style={{ marginLeft: 8 }}>
              <Chevron />
            </span>
          </PStyled>
        </CenterTextWrapper>

        <CenterTextWrapper>
          <P>
            But I wasn’t ready yet. Although life’s beauty ran riot around me,
            the inner eye could not yet hold it in focus. The opaqueness
            manifested physically as well. Muscular constrictions born of a
            lifetime’s worth of striving and fight or flight duality kept my
            voice from sounding freely the crystalline emotion of our deepest
            song.
          </P>
        </CenterTextWrapper>
        <CenterTextWrapper>
          <P style={{ color: "#6A761D" }}>
            Happily, since the Spring of 2019 the vocal knot has steadily
            loosened. And, as I approach the seventh anniversary of my official
            retirement, both body and spirit have near fully recycled. I look
            forward to a time soon when I will sing for you…
          </P>
        </CenterTextWrapper>
      </Container>
      <div
        style={{ display: "flex", justifyContent: "flex-end", width: "94%" }}
      >
        <p style={{ font: "2vw Mrs Saint Delafield", color: "#331c65" }}>
          July 2020
        </p>
      </div>
      <img
        style={{ width: "100%", marginBottom: -4 }}
        src={Leather}
        alt="Leather Jacket"
      />
    </motion.div>
  );
};

export default WhatNow;
