import React from "react";
import { motion } from "framer-motion";

const ImageHover = ({ image }) => (
  <motion.img
    style={{ width: "100%", height: "100%" }}
    whileHover={{ scale: 1.2 }}
    transition={{ duration: 1, ease: "easeInOut" }}
    src={image}
    alt={image}
  />
);

export default ImageHover;
