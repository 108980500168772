import React, { useRef, useEffect, useState } from "react";
import LocomotiveScroll from "locomotive-scroll";
import { motion } from "framer-motion";
import styled from "styled-components";
// import Footer from "../../components/HorizontalScroll/footer";
import GalleryItem from "./galleryItem";
import Modal from "../../components/Modal";
import ModalImage from "../Images/modalImage";
import { device } from "../../generalStyles";

// import "../../../node_modules/locomotive-scroll/dist/locomotive-scroll.css";

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100vw;
  height: 100%;
  align-items: center;
`;
const TitleWrapper = styled.div`
  width: 70%;
  white-space: normal;
  text-align: center;
`;
const Title = styled(motion.h1)`
  text-transform: capitalize;
  font: 3rem Hidden Focus;
  color: #431e57;
  @media ${device.small} {
    font-size: 4rem;
  }
  @media ${device.medium} {
    font-size: 6rem;
  }
`;
const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columnAmount}, 1fr);
  grid-template-rows: repeat(5, 20%);
  width: ${(props) => props.width};
  // padding-left: 16%;
  height: 100%;
  background-color: white;
`;
const Content = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
`;

const HorizontalScroll = ({ title, photos, children, width, columnAmount }) => {
  const ref = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (ref) {
      // new LocomotiveScroll({
      //   el: ref.current,
      //   smooth: true,
      //   direction: "horizontal",
      //   smartphone: {
      //     smooth: true,
      //     direction: "horizontal",
      //   },
      //   tablet: {
      //     smooth: true,
      //     direction: "horizontal",
      //   },
      // });

      new LocomotiveScroll({
        el: ref.current,
        smooth: true,
        lerp: 0.06,
        multiplier: 0.5,
        direction: "horizontal",
        smartphone: {
          smooth: true,
          lerp: 0.06,
          multiplier: 0.5,
          direction: "horizontal",
        },
        tablet: {
          smooth: true,
          lerp: 0.06,
          multiplier: 0.5,
          direction: "horizontal",
        },
      });
    }

    // return () => {
    //   console.log("ref", ref);
    // };
  }, []);

  const openModal = (data) => {
    setSelected(data);
    setShowModal((prev) => !prev);
  };

  return (
    <div>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        style={{ height: selected.layout === "vertical" ? "80%" : "auto" }}
      >
        <ModalImage
          id={selected.id}
          image={selected.image}
          title={selected.title}
          date={selected.date}
          noInfo={selected.noInfo}
          layout={selected.layout}
          contest={selected.contest}
        />
      </Modal>

      <div className="scroll-container" data-scroll-container ref={ref}>
        <Content>
          <TitleContainer>
            <TitleWrapper>
              <Title
                data-scroll
                data-scroll-speed={-3}
                initial={{ translateY: 50, opacity: 0 }}
                animate={{ translateY: 0, opacity: 1 }}
                transition={{
                  duration: 1,
                }}
                exit={{ opacity: 0 }}
              >
                {title}
              </Title>
            </TitleWrapper>
          </TitleContainer>
          <Gallery width={width} columnAmount={columnAmount}>
            {children}
            {photos.map((el, i) => (
              <GalleryItem data={el} key={i} index={i} modal={openModal} />
            ))}
          </Gallery>
          {/* <Footer /> */}
        </Content>
      </div>
    </div>
  );
};

export default HorizontalScroll;
