import HorizontalScroll from "../../../components/HorizontalScroll/horizontalScroll";
import { dataMexico } from "./dataMexico";
import { Text } from "../../../generalStyles";
import { First, Third } from "../Enescu/styles";
import React from "react";

const Mexico = () => {
  return (
    <HorizontalScroll
      title={dataMexico.title}
      photos={dataMexico.images}
      width={"180vw"}
      columnAmount={80}
    >
      <Text style={{ gridArea: "1 / 3 / 2 / 17 ", placeItems: "flex-end" }}>
        The Flowers images’ first public showing came about unexpectedly.
      </Text>
      <Text style={{ gridArea: "2 / 3 / 3 / 17 ", placeItems: "center" }}>
        One day in mid July 2019, I was having lunch with my friend Bill at Como
        En Casa, a family style Mexican restaurant in my old downtown
        neighborhood. As lunch hour wound down, I took out three of my most
        recent prints to show Bill. Carlos, the ever alert proprietor, quickly
        came over to join us and have a look.
      </Text>
      <Text style={{ gridArea: "3 / 3 / 4 / 17 ", placeItems: "flex-start" }}>
        “I like the last one most” said Carlos. Then he pointed over to the long
        wall, looked at me squarely and asked: “Ray, would you consider
        exhibiting your art here in my restaurant?” “Absolutely,” I replied
        without hesitation. “No, I’m serious,” he said, “please think about it.”
        “I have, Carlos, and the answer is Yes. Let’s do it!”
      </Text>
      <div
        style={{
          display: "grid",
          gridArea: "1 / 54 / 2 / 68",
          alignContent: "center",
        }}
      >
        <First>Como en Casa Fresh Mexican Kitchen</First>
        <Third>August to October, 2019</Third>
      </div>
    </HorizontalScroll>
  );
};

export default Mexico;
