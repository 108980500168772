import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import Cross from "../../images/SVG/charm_cross.svg";
import {
  FormWrapper,
  Form,
  Input,
  Textarea,
  Checkbox,
  Button,
  InputBallot,
  NEMWrapper,
  Sent,
} from "./styles";

const ReachUs = ({
  data,
  location,
  checkmark,
  images,
  light,
  message,
  ballot,
  openModal,
}) => {
  const {
    register,
    handleSubmit,
    control,
    // watch,
    formState: { errors },
  } = useForm(ballot);

  useFieldArray({
    ballot,
    control,
    name: "test",
  });

  const emailTemplate = ballot ? "template_c0fo2cv" : "template_wzfnt0h";

  const [status, setStatus] = useState(false);
  // const [err, setErr] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setStatus(false);
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [status]);

  const onSubmit = (formData, e) => {
    // console.log(formData);
    emailjs
      .send(
        "service_ngwjkrs",
        emailTemplate,
        formData,
        "user_QHASe49AohVypbNxPQXWd"
      )
      .then((res) => setStatus(res))
      .catch((err) => console.log(err));
    e.target.reset();

    if (location === "PhotoShoppe") {
      data.clearImagesOnSubmit();
    }
  };

  return (
    <FormWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ margin: "8% auto" }}>
          {ballot &&
            ballot.map((item, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  margin: "4% 0px",
                }}
              >
                <img
                  onClick={() => openModal(item.images[0])}
                  style={{ width: "40%", marginRight: "2%" }}
                  src={item.images[0].image}
                  alt="contest"
                />
                <InputBallot
                  placeholder={`name this image`}
                  light={light}
                  key={item.id}
                  {...register(`${item.title}`)}
                />
              </div>
            ))}
        </div>

        <NEMWrapper>
          <label>Name</label>
          <Input
            light={light}
            name="name"
            {...register("name", { required: true })}
          />
          {errors.name && (
            <span style={{ marginBottom: 8 }}>This field is required</span>
          )}

          <label>Email</label>
          <Input
            light={light}
            name="email"
            type="email"
            {...register("email", { required: true })}
          />
          {errors.email && (
            <span style={{ marginBottom: 8 }}>This field is required</span>
          )}

          {message && (
            <>
              <label>Message</label>
              <Textarea
                light={light}
                name="message"
                {...register("message")}
              ></Textarea>
            </>
          )}
        </NEMWrapper>

        {checkmark && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              light={light}
              name="checkbox"
              type="checkbox"
              {...register("checkbox")}
            />
            <label>
              Yes. Please let me know when something is added to Hidden Focus
              Galleries
            </label>
          </div>
        )}

        {images && (
          <Input
            style={{ display: "none" }}
            name="images"
            value={`images' ID: ${data.imagesPS.map((el) => el.id)}`}
            {...register("images", { required: true })}
          />
        )}

        <input
          type="hidden"
          name={location}
          {...register("hidden", { value: location })}
        />

        <Button type="submit" value="Send" />
        {status.status === 200 && (
          <Sent onClick={() => setStatus(false)}>
            <img
              style={{ alignSelf: "flex-end" }}
              width={16}
              src={Cross}
              alt="cross"
            />
            <p style={{ alignSelf: "center" }}>Your message is sent!</p>
          </Sent>
        )}
      </Form>
    </FormWrapper>
  );
};

export default ReachUs;
