export const HeaderAnim = {
  initial: { translateY: 50, opacity: 0 },
  animate: {
    translateY: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      delay: 2,
    },
  },
  exit: { opacity: 0 },
};

export const ImageAnim = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: 1,
      delay: 1.8,
    },
  },
  exit: { opacity: 0 },
};

export const PhraseAnim = {
  initial: { translateY: -50, opacity: 0 },
  animate: {
    translateY: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  exit: { opacity: 0 },
};
