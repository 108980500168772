import styled from "styled-components";
import { ImagesContext } from "../../contexts/imagesContext";
import React, { useContext, useState } from "react";
import { device } from "../../generalStyles";

const TextWrapper = styled.div`
  background-color: #2f123d94;
  color: #f9efff;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
`;
const Title = styled.p`
  text-align: center;
  font: ${(props) =>
    props.title === "untitled"
      ? "1rem Kumbh Sans"
      : "2rem Mrs Saint Delafield"};
  padding: 16px;
  color: ${(props) => (props.title === "untitled" ? "lightgray" : "#e5c100")};
  @media ${device.small} {
    font-size: ${(props) => (props.title === "untitled" ? "2rem " : "3rem ")};
  }
`;

const Rights = styled(Title)`
  font: 14px/12px Kumbh Sans;
`;
const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.layout === "vertical" ? "column" : "row"};
  align-items: ${(props) =>
    props.layout === "vertical" ? "center" : "center"};
  justify-content: space-between;
  font: 1rem Kumbh Sans;
  padding: 16px;
  text-decoration: none;
  @media ${device.small} {
    flex-direction: row;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  margin: auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &:hover ${TextWrapper} {
    opacity: 1;
  }
`;

const PSText = styled.button`
  font: 1rem Kumbh Sans;
  background-color: #6a0dad;
  padding: 4px 8px;
  border-radius: 4px;
  color: #f9efff;
  outline: none;
  border: none;
  cursor: pointer;
  margin: ${(props) => (props.layout === "vertical" ? "4% auto" : "0")};
  @media ${device.small} {
    margin: 0;
  }
`;

const ModalImage = ({ id, title, image, date, noInfo, layout, contest }) => {
  const { data } = useContext(ImagesContext);
  const [added, setAdded] = useState(false);

  return (
    <ImageWrapper>
      <img src={image} alt="imageModal" />
      <TextWrapper>
        {!noInfo && (
          <>
            {contest && (
              <p
                style={{
                  textAlign: "center",
                  font: "1rem Kumbh Sans",
                  margin: "2% auto",
                }}
              >
                Contest Winner
              </p>
            )}
            <Title title={title}>{title}</Title>
            <SubtitleWrapper layout={layout}>
              <p>{date}</p>
              <PSText
                layout={layout}
                onClick={() => {
                  data.addToPS(id, image);
                  setAdded((prev) => !prev);
                }}
                style={{
                  backgroundColor: data.imagesPS.some(
                    (el) => el.image === image
                  )
                    ? "#6a0dad"
                    : "#f9efff30",
                }}
              >
                {added ? "Added to My PhotoShoppe" : "Add to My PhotoShoppe"}
              </PSText>
            </SubtitleWrapper>
          </>
        )}
        <Rights>
          <span style={{ fontSize: 18 }}>&copy;</span> All Rights Reserved
        </Rights>
      </TextWrapper>
    </ImageWrapper>
  );
};

export default ModalImage;
