import styled from "styled-components";
import { device } from "../../../../generalStyles";
import Sound from "../../../../audio/Ray Hanson - Focus (V1).mp3";
import Audio from "../../../../components/audio";
import React from "react";

const Container = styled.div`
  white-space: normal;
  font: 14px/24px Kumbh Sans;
  color: #331c65;
  @media ${device.small} {
    font: 16px/26px Kumbh Sans;
  }
  @media ${device.medium} {
    font: 20px/32px Kumbh Sans;
  }
`;

const Focus = () => (
  <Container style={{ margin: "10% 10% 20% 10%" }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <p style={{ font: "4vw Hidden Focus" }}>Focus</p>
      <Audio addedStyle={{ marginBottom: 20 }} audio={Sound} />
    </div>
    <p>
      When we look at something, really look, what captures our attention stands
      out in sharp relief. Everything else blurs away. These flower “portraits”
      are taken with a 100mm Macro lens, and make deliberate use of the focal
      plane as a major part of the composition, along with the placement of
      objects and blocks of color. The goal is to use all the elements of
      composition to invite the viewer to see the flowers in a particular way.
    </p>
    <br />
    <p>
      Together, these images make up an imaginary exhibition called Look at the
      Flowers… It is a still growing body of work that began in the Spring and
      Summer of 2019. When Spring came around again in 2020, I wondered what
      would happen when I looked through the lens. Would anything “new” show up?
      <br />
      <br />
      Please take a look with me and see!
    </p>
  </Container>
);

export default Focus;
