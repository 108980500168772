import styled from "styled-components";
// import { motion } from "framer-motion";
import { device } from "../../generalStyles";

export const Heading = styled.h1`
  font: 1.5rem/2.5rem Hidden Focus;
  color: #f9efff;
  padding: 2% 8%;
  @media ${device.small} {
    padding: 8% 8% 4%;
    font: 2.5rem Hidden Focus;
  }
  @media ${device.medium} {
    padding: 4% 6% 1%;
    font: 2.6rem Hidden Focus;
    max-width: 1366px;
    margin: auto;
  }
`;

export const BG = styled.div`
  padding: 3.5%;
  width: 96%;
  margin: auto;
  background-color: #2f123db0;
  max-width: 1366px;
`;

export const PairWrapper = styled.div`
  display: flex;
  height: 60vh;
  justify-content: space-between;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
`;

export const RightSide = styled.div`
  width: 50%;
  color: #f9efff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const InfluencesName = styled.h2`
  font: 1.875rem/2.875rem "Mrs Saint Delafield";
  color: #b3c53f;
  @media ${device.small} {
    font: 2.5rem/3.125rem "Mrs Saint Delafield";
  }
  @media ${device.medium} {
    font: 3.4rem/3.6rem "Mrs Saint Delafield";
  }
`;

export const InfluencesTextWrapper = styled.div`
  height: 100%;
  overflow: scroll;
`;

export const InfluencesText = styled.span`
  font: 300 0.875rem/1.125rem "Kumbh Sans";
  overflow: scroll;
  height: 40%;
  @media ${device.small} {
    font: 300 1rem/1.375rem "Kumbh Sans";
  }
`;

export const Gradient = styled.div`
  height: 100px;
  background: linear-gradient(rgba(20, 9, 26, 0) 0%, #291035 100%) 0% 0%
    no-repeat padding-box padding-box transparent;
  position: absolute;
  width: 100%;
  bottom: -1px;
`;

export const TimeLineWrapper = styled.div`
  width: 80%;
  position: relative;
  display: flex;
  margin: auto;
  max-width: 1200px;
`;

export const Line = styled.div`
  display: flex;
  justifycontent: space-between;
  position: absolute;
  width: 100%;
`;

export const FutureLine = styled.div`
  height: 1px;
  background-color: #6a0dad;
  width: 100%;
  position: absolute;
  top: -1px;
`;
export const PastLine = styled.div`
  height: 1px;
  background-color: #b3c53f;
  position: absolute;
  top: -1px;
`;

export const DotsWrapper = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: -42px;
`;

export const Dot = styled.div`
  background-color: ${({ selected, past }) =>
    selected ? "#677126;" : past ? "#677126" : "#301b3c"};

  width: ${({ selected, past }) => (selected ? "1rem" : "0.625rem")};
  height: ${({ selected, past }) => (selected ? "1rem" : "0.625rem")};
  border-radius: 50%;
  border: ${({ selected, past }) =>
    selected
      ? `solid 2px #B3C53F`
      : past
      ? `solid 2px #b3c53f`
      : `solid 2px #6a0dad`};
  position: absolute;
  top: ${({ selected, past }) => (selected ? "33px" : "36px")};
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.small} {
    width: ${({ selected, past }) => (selected ? "1.4rem" : "1rem")};
    height: ${({ selected, past }) => (selected ? "1.4rem" : "1rem")};
    top: ${({ selected, past }) => (selected ? "30px" : "33px")};
  }
`;

export const ActiveDot = styled.div`
  height: 4px;
  width: 4px;
  background-color: #b3c53f;
  border-radius: 50%;
`;

export const Year = styled.span`
  font: 1rem Hidden Focus;
  color: #b3c53f;
  position: absolute;
  opacity: ${({ selected }) => (selected ? 1 : 0)};
  top: 56px;
  @media ${device.small} {
    top: 68px;
    font-size: 1.4rem;
  }
`;
