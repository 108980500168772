import React, { useContext } from "react";
import HorizontalScroll from "../../components/HorizontalScroll/horizontalScroll";
import { ImagesContext } from "../../contexts/imagesContext";

const Angkor = () => {
  const { data } = useContext(ImagesContext);

  return (
    <HorizontalScroll
      title={data.ANGKORWAT.title}
      photos={data.ANGKORWAT.images}
      width={"500vw"}
      columnAmount={100}
    />
  );
};

export default Angkor;
