import styled from "styled-components";
import React from "react";

const Container = styled.div`
  color: #331c65;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  line-height: 1.5;
  font: 1vw Kumbh Sans;
  padding: 8% 0;
`;

const Cole = () => (
  <Container>
    <div>
      <p>Like the moon growing dim</p>
      <p>on the rim of the hill</p>
      <p>In the chill, still of the night.</p>
    </div>
    <br />
    <div style={{ fontWeight: 600 }}>
      <p>Cole Porter</p>
    </div>
  </Container>
);

export default Cole;
