import I1 from "../images/AW/1.jpg";
import I2 from "../images/AW/2.jpg";
import I3 from "../images/AW/3.jpg";
import I4 from "../images/AW/4.jpg";
import I5 from "../images/AW/5.jpg";
import I6 from "../images/AW/6.jpg";
import I7 from "../images/AW/7.jpg";
import I8 from "../images/AW/8.jpg";
import I9 from "../images/AW/9.jpg";
import I10 from "../images/AW/10.jpg";
import I11 from "../images/AW/11.jpg";
import I12 from "../images/AW/12.jpg";
import I13 from "../images/AW/13.jpg";
import I14 from "../images/AW/14.jpg";

export const ANGKORWAT = {
  title: "angkor wat",
  images: [
    {
      id: "AW1",
      image: I1,
      floatingTitle: "angkor wat",
      text: [
        "Ankor Wat today is majestic and inspiring to be sure; but peaceful, quiet and conducive to meditation not so much.",
        "The approach to the temple’s west gate at sunset reveals a bustling street bazaar winding down after a hectic day.",
      ],
      gridRowStart: 1,
      gridColumnStart: 1,
      gridRowEnd: 4,
      gridColumnEnd: 11,
      layout: "horizontal",
      title: "untitled",
      date: "Angkor Wat 2020",
    },
    {
      id: "AW2",
      image: I2,
      floatingTitle: "angkor wat",
      text: [
        "On the day of our visit, a serene view of the temple towers is accessible only on a postcard offered by this child vendor with a disarming smile…",
      ],
      gridRowStart: 4,
      gridColumnStart: 8,
      gridRowEnd: 6,
      gridColumnEnd: 14,
      title: "untitled",
      date: "Angkor Wat 2020",
      layout: "horizontal",
    },
    {
      id: "AW3",
      image: I3,
      floatingTitle: "angkor wat",
      text: [
        "Angkor Wat was originally constructed as a Hindu temple dedicated to the god Vishnu. Here we see the goddess Kali receiving the mixed attentions of visitors.",
      ],
      gridRowStart: 1,
      gridColumnStart: 18,
      gridRowEnd: 5,
      gridColumnEnd: 24,
      title: "untitled",
      date: "Angkor Wat 2020",
      layout: "vertical",
    },
    {
      id: "AW4",
      image: I4,
      floatingTitle: "angkor wat",
      text: [
        " The best way to keep fellow tourists from “intruding” upon an idealized scene was to incorporate them directly into the composition…",
      ],
      gridRowStart: 4,
      gridColumnStart: 25,
      gridRowEnd: 6,
      gridColumnEnd: 30,
      title: "untitled",
      date: "Angkor Wat 2020",
      layout: "horizontal",
    },
    {
      id: "AW5",
      image: I5,
      floatingTitle: "Bayon",
      text: [],
      gridRowStart: 1,
      gridColumnStart: 34,
      gridRowEnd: 3,
      gridColumnEnd: 40,
      title: "untitled",
      date: "Bayon 2020",
      layout: "horizontal",
    },
    {
      id: "AW6",
      image: I6,
      floatingTitle: "Bayon",
      text: [],
      gridRowStart: 4,
      gridColumnStart: 34,
      gridRowEnd: 6,
      gridColumnEnd: 40,
      title: "untitled",
      date: "Bayon 2020",
      layout: "horizontal",
    },
    {
      id: "AW7",
      image: I7,
      floatingTitle: "Bayon",
      text: [],
      gridRowStart: 1,
      gridColumnStart: 42,
      gridRowEnd: 6,
      gridColumnEnd: 49,
      title: "untitled",
      date: "Bayon 2020",
      layout: "vertical",
    },
    {
      id: "AW8",
      image: I8,
      floatingTitle: "Phnom Bakheng",
      text: [],
      gridRowStart: 1,
      gridColumnStart: 50,
      gridRowEnd: 6,
      gridColumnEnd: 57,
      title: "untitled",
      date: "Phnom Bakheng 2020",
      layout: "vertical",
    },
    {
      id: "AW9",
      image: I9,
      floatingTitle: "Ta Prohm",
      text: [],
      gridRowStart: 1,
      gridColumnStart: 59,
      gridRowEnd: 3,
      gridColumnEnd: 65,
      title: "untitled",
      date: "Ta Prohm 2020",
      layout: "horizontal",
    },
    {
      id: "AW10",
      image: I10,
      floatingTitle: "Ta Prohm",
      text: [],
      gridRowStart: 4,
      gridColumnStart: 59,
      gridRowEnd: 6,
      gridColumnEnd: 65,
      title: "untitled",
      date: "Ta Prohm 2020",
      layout: "horizontal",
    },
    {
      id: "AW11",
      image: I11,
      floatingTitle: "Angkor Wat",
      text: [
        "Two of these images capture something of our cultural disconnect from spiritual moorings. The third reminds us of who we really are…",
      ],
      gridRowStart: 1,
      gridColumnStart: 72,
      gridRowEnd: 3,
      gridColumnEnd: 78,
      title: "Phone Home",
      date: "Angkor Wat 2020",
      layout: "horizontal",
    },
    {
      id: "AW12",
      image: I12,
      floatingTitle: "Angkor Wat",
      text: [
        "Two of these images capture something of our cultural disconnect from spiritual moorings. The third reminds us of who we really are…",
      ],
      gridRowStart: 4,
      gridColumnStart: 68,
      gridRowEnd: 6,
      gridColumnEnd: 74,
      title: "Getting Ahead",
      date: "Angkor Wat 2020",
      layout: "horizontal",
    },
    {
      id: "AW13",
      image: I13,
      floatingTitle: "Phnom Bakheng",
      text: [
        "Two of these images capture something of our cultural disconnect from spiritual moorings. The third reminds us of who we really are…",
      ],
      gridRowStart: 4,
      gridColumnStart: 76,
      gridRowEnd: 6,
      gridColumnEnd: 82,
      title: "Know Thyself",
      date: "Phnom Bakheng 2020",
      layout: "horizontal",
    },
    {
      id: "AW14",
      image: I14,
      floatingTitle: "Ta Prohm",
      text: [],
      gridRowStart: 2,
      gridColumnStart: 85,
      gridRowEnd: 5,
      gridColumnEnd: 97,
      title: "The Sentinel",
      date: "Angkor Thom 2020",
      layout: "horizontal",
    },
  ],
};
