import { motion } from "framer-motion";
import styled from "styled-components";
import { device } from "../../generalStyles";

export const Container = styled.div`
  margin: auto;
  @media ${device.medium} {
    padding: 6% 8%;
    max-width: 1366px;
  }
`;

export const Title = styled(motion.h1)`
  font: 5vw Hidden Focus;
  color: #b3c53f;
  display: flex;
  padding-top: 60px;
  justify-content: center;
  @media ${device.medium} {
    font: 3vw/3vw Hidden Focus;
  }
`;

export const LetterHead = styled.span`
  font: 30px/20px Hidden Focus;
  color: transparent;
  -webkit-text-stroke: 1px #8a33b9;
`;

export const P = styled.p`
  font: 1rem/1.25rem Kumbh Sans;
  color: #301b3c;
`;

export const PStyled = styled.p`
  font: 32px/48px Mrs Saint Delafield;
  color: #301b3c;
  text-align: center;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 4%;
`;

export const TextWrapper = styled(motion.div)`
  width: 50%;
  padding: 24px 32px;
`;

export const Block1 = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 60px auto;
  @media ${device.tablet} {
    margin: 10% auto 4%;
    width: 80%;
  }
`;

export const CenterTextWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin: 40px auto;
`;
