import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

//Protected routes because the user
// is unable to access the navbar
//until visited Why Now page

const ProtectedRoute = ({ disclose, children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!disclose) {
      return navigate("/");
    }
  }, [navigate, disclose]);

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
