import React from "react";
import styled from "styled-components";

const Svg = styled.svg`
  fill: #a600ff;
  height: 12px;
`;

const Chevron = ({ style }) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.5 10" style={style}>
      <g>
        <line
          y1="5"
          x2="72"
          y2="5"
          fill="none"
          stroke="#a600ff"
          strokeMiterlimit="10"
        />
        <path
          d="M63,9.5C63,5,72,5,72,5S63,5,63,.5"
          fill="none"
          stroke="#a600ff"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Svg>
  );
};

export default Chevron;
