import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { museum } from "../../pages/Gallery/dataGallery";
import Contest from "../../images/AW/13.jpg";
import { menuData } from "./menuData";
import Header from "./header";
import {
  ContestImage,
  ContestText,
  ContestYear,
  Layer,
  LeftMenu,
  Li,
  Links,
  LiSmall,
  MenuItemsWrapper,
  RightMenu,
  Ul,
} from "./styles";
import {
  DDMenuLayer1,
  DDMenuLayer2,
  MenuItems,
  ConstestImageAnim,
} from "./menuAnimations";

const Menu = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(false);

  return (
    <div style={{ position: "relative", zIndex: 2 }}>
      <Header open={open} setOpen={setOpen} />
      <AnimatePresence exitBeforeEnter>
        {open && (
          <Layer {...DDMenuLayer1} style={{ backgroundColor: "#8a33b9" }}>
            <Layer {...DDMenuLayer2} style={{ backgroundColor: "#1d0d25" }}>
              <MenuItemsWrapper>
                <LeftMenu>
                  {menuData.left.map((el, i) => (
                    <motion.div {...MenuItems} key={i}>
                      <Link
                        style={{ textDecoration: "none" }}
                        onClick={handleOpen}
                        to={`/${el.url}`}
                      >
                        <ContestImage
                          {...ConstestImageAnim}
                          src={Contest}
                          alt="contest photo"
                        />
                        <ContestText>{el.name}</ContestText>
                        {/* <ContestYear>2022</ContestYear> */}
                      </Link>
                    </motion.div>
                  ))}
                </LeftMenu>

                <RightMenu>
                  <nav style={{ marginTop: "20%" }}>
                    <Ul {...MenuItems} onClick={handleOpen}>
                      {menuData.right.slice(0, 2).map((el, i) => (
                        <Li key={i}>
                          <Links to={`/${el.url}`}>{el.name}</Links>
                        </Li>
                      ))}

                      {museum.map((room, i) => {
                        return (
                          <LiSmall key={i}>
                            <Links to={`/${room.floors}`}>{room.floors}</Links>
                          </LiSmall>
                        );
                      })}
                      {menuData.right.slice(2, 5).map((el, i) => (
                        <Li key={i}>
                          <Links to={`/${el.url}`}>{el.name}</Links>
                        </Li>
                      ))}
                    </Ul>
                  </nav>
                </RightMenu>
              </MenuItemsWrapper>
            </Layer>
          </Layer>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Menu;
